import {
  ComputerDesktopIcon,
  GlobeEuropeAfricaIcon,
  KeyIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

import CQCQIcon from "../svgs/CQCQIcon.js";

export const surveyTags = [
  {
    name: "Quality",
    icon: ShieldCheckIcon,
    textColour: "text-blue-700",
    bgColour: "bg-blue-100",
  },
  {
    name: "Access",
    icon: KeyIcon,
    textColour: "text-amber-600",
    bgColour: "bg-amber-100",
  },
  {
    name: "Net Zero",
    icon: GlobeEuropeAfricaIcon,
    textColour: "text-green-700",
    bgColour: "bg-green-100",
  },
  {
    name: "Workforce",
    icon: UserGroupIcon,
    textColour: "text-lime-700",
    bgColour: "bg-lime-100",
  },
  {
    name: "CQC",
    icon: CQCQIcon,
    textColour: "text-fuchsia-800",
    bgColour: "bg-fuchsia-100",
  },
  {
    name: "Digital",
    icon: ComputerDesktopIcon,
    textColour: "text-cyan-700",
    bgColour: "bg-cyan-100",
  },
];
