import React from "react";
import { Link } from "react-router-dom";

import { numberWithCommas } from "../../../../utilities/general";
import {
  calculatePlanCost,
  getPatientCount,
} from "../../../../utilities/stacks";
import ProductImage from "../../../ProductImage";
import ContractBadge from "../../../ContractBadge";
import ComplianceCell from "./ComplianceCell";

const ProductRow = ({ stack, product }) => {
    const patientCount = getPatientCount(stack);
    const productManagementDetail = stack.StackProductManagements?.items?.find(
      (productManagement) =>
        productManagement.productManagementDetailsSoftwareProductId ===
        product.softwareProductId
    );
  
    const cost = calculatePlanCost(
      productManagementDetail?.price,
      productManagementDetail?.pricingPlan,
      patientCount
      );
      
    return (
      <tr>
        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm bg-white-50 text-gray-900 sm:pl-3">
          <span className="flex gap-2 items-center ml-8">
            <ProductImage
              softwareProduct={product.softwareProduct}
              width="w-12"
              height="h-12"
              hideBorder
            />
            <Link
              className="hover:underline"
              to={`/product/${product.softwareProduct.id}`}
            >
              {product.softwareProduct.name}
            </Link>
          </span>
        </td>
        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm bg-white-50 text-gray-500 sm:pl-3">
          {isNaN(cost) ? 'Unavailable' : `£${numberWithCommas(cost.toFixed(2))}`}
        </td>
        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm bg-white-50 text-gray-500 sm:pl-3">
          {productManagementDetail?.contractEndDate ? (
            <ContractBadge
              contractEndDate={new Date(productManagementDetail.contractEndDate)}
              size="xs"
            />
          ) : (
            "-"
          )}
        </td>
        <ComplianceCell status={productManagementDetail?.DPIAStatus} />
        <ComplianceCell status={productManagementDetail?.DCB0160Status} />
        <ComplianceCell
          status={productManagementDetail?.sharingAgreementStatus}
        />
        <td></td>
      </tr>
    );
  };

  export default ProductRow