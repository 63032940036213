import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { generateClient, del } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

const RemoveStackDialog = ({ stack, setOpen }) => {
  const [loadingRemoval, setLoadingRemoval] = useState(false);
  const [name, setName] = useState();

  const removeStack = async () => {
    setLoadingRemoval(true);

    const accessToken = (
      await fetchAuthSession()
    ).tokens?.accessToken?.toString();

    const restOperation = del({
      apiName: "bordercrossRest",
      path: `/stacks/deleteStack/${stack.id}`,
      options: {
        headers: {
          "jwt-token": "Basic " + accessToken,
        },
      },
    });
    await restOperation.response

    document.location.href = '/dashboard'
  };

  useEffect(() => {
    setName(stack?.organisation?.name || stack.name);
  }, []);

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-1 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-base sm:text-lg font-semibold leading-6 text-gray-900"
          >
            Are you sure you want to delete data for {name}?
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm sm:text-base text-gray-500">
              All products and any details you added will be deleted.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          disabled={loadingRemoval ? true : false}
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={() => removeStack()}
        >
          {loadingRemoval && (
            <svg
              className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Delete
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default RemoveStackDialog;
