import { Fragment, useState, useEffect } from "react";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";

import ProductImage from "./ProductImage";
import MissingProductBanner from "./MissingProductBanner";

export default function SelectProduct({
  products,
  filters,
  selectedProduct,
  preselectedAddProductFilter,
  setSelectedProduct,
}) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    if (
      preselectedAddProductFilter &&
      !selectedFilters.includes(preselectedAddProductFilter)
    ) {
      setSelectedFilters([...selectedFilters, preselectedAddProductFilter]);
    }
  }, [preselectedAddProductFilter]);

  const handleFilterChange = (e) => {
    const newSelectedFilters = [...selectedFilters];
    const changedFilter = e.target.value;
    if (selectedFilters.includes(changedFilter)) {
      newSelectedFilters.splice(newSelectedFilters.indexOf(changedFilter), 1);
    } else {
      newSelectedFilters.push(changedFilter);
    }
    setSelectedFilters(newSelectedFilters);
  };

  let filteredProducts = products;
  if (searchText) {
    filteredProducts = filteredProducts.filter((product) => {
      if (
        product?.name?.toLowerCase().includes(searchText?.toLowerCase()?.trim())
      )
        return true;
      return false;
    });
  }
  if (selectedFilters.length) {
    filteredProducts = filteredProducts.filter((product) => {
      return selectedFilters.every((filterItem) => {
        const matchesCategory = product?.productCategory?.id === filterItem;
        const matchesTag = product?.productTags?.items?.some(
          (tag) => filterItem === tag.productTagId
        );
        return matchesCategory || matchesTag;
      });
    });
  }

  return (
    <div className="bg-white">
      <div>
        {/* Mobile filter dialog */}
        <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={setMobileFiltersOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">
                      Filters
                    </h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    {filters.map((section) => (
                      <Disclosure
                        as="div"
                        key={section.id}
                        defaultOpen={true}
                        className="border-t border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">
                                  {section.name}
                                </span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div
                                    key={option.id}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={`filter-mobile-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.id}
                                      type="checkbox"
                                      onChange={handleFilterChange}
                                      defaultChecked={
                                        option.id ===
                                        preselectedAddProductFilter
                                      }
                                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                    />
                                    <label
                                      htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                      className="ml-3 min-w-0 flex-1 text-gray-500"
                                    >
                                      {option.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <section aria-labelledby="products-heading" className="pb-24 pt-6">
            <div className="flex justify-end">
            <button
              type="button"
              className="text-gray-400 hover:text-gray-500 lg:hidden flex gap-2 mb-2"
              onClick={() => setMobileFiltersOpen(true)}
            >
              <span>Filter</span>
              <FunnelIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
              {/* Filters */}
              <form className="hidden lg:block">
                {filters.map((section) => (
                  <Disclosure
                    as="div"
                    key={section.id}
                    defaultOpen={true}
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              {section.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <div
                                key={option.id}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  defaultValue={option.id}
                                  type="checkbox"
                                  onChange={handleFilterChange}
                                  defaultChecked={
                                    option.id === preselectedAddProductFilter
                                  }
                                  className="h-5 w-5 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {option.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </form>

              {/* Product grid */}
              <div className="lg:col-span-3">
                <div className="mb-6">
                  <Search
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
                {searchText && filteredProducts.length > 0 && (
                  <div className="mb-2">
                    Products matching "{searchText.trim()}":
                  </div>
                )}
                {searchText && filteredProducts.length === 0 && (
                  <div className="mb-2">
                    No products matching "{searchText.trim()}"
                  </div>
                )}

                <div className="flex flex-wrap gap-3 max-h-[42rem] overflow-scroll">
                  {filteredProducts?.map((product) => {
                    const isSelected = product.id === selectedProduct?.id;
                    const category = product?.productCategory?.name;

                    if (isSelected) {
                      return (
                        <button
                          type="button"
                          className="flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          onClick={() => setSelectedProduct()}
                        >
                          <ProductImage
                            softwareProduct={product}
                            width="w-12"
                            height="h-12"
                            hideBorder
                          />
                          <div className="text-left">
                            <div>{product.name}</div>
                            {category && (
                              <div className="text-xs text-blue-200 font-normal">
                                {category}
                              </div>
                            )}
                          </div>
                          <CheckCircleIcon
                            className="-mr-0.5 h-5 w-5"
                            aria-hidden="true"
                          />
                        </button>
                      );
                    } else {
                      return (
                        <button
                          type="button"
                          className="flex items-center gap-2 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setSelectedProduct(product)}
                        >
                          <ProductImage
                            softwareProduct={product}
                            width="w-12"
                            height="h-12"
                            hideBorder
                          />
                          <div className="text-left">
                            <div>{product.name}</div>
                            {category && (
                              <div className="text-xs text-gray-500 font-normal">
                                {category}
                              </div>
                            )}
                          </div>
                        </button>
                      );
                    }
                  })}
                </div>

                <MissingProductBanner />
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

const Search = ({ searchText, setSearchText }) => (
  <div>
    <label
      htmlFor="search"
      className="ml-px block text-md leading-6 text-gray-900"
    >
      Search
    </label>
    <div className="mt-2">
      <input
        type="text"
        name="search"
        id="search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        className="block w-full rounded-md border-0 px-4 py-3 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6"
      />
    </div>
  </div>
);
