// Info about NHS Names
// https://digital.nhs.uk/developer/api-catalogue/organisation-data-service-fhir
// https://gitlab.com/polc1410/nhsnames
export const titleCaseNHS = (name, capitalizeFirstLetter = true) => {
  if (!name) return;

  name = name.replace(
    /\b\w+/g,
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  // Change "NHS" back to uppercase
  name = name.replace(/\bNhs\b/g, "NHS");

  name = name.replace(/\Icb\b/g, "ICB");
  name = name.replace(/\Pcn\b/g, "PCN");

  // Change conjunctions to lowercase
  name = name
    .replace(/\bAnd\b/g, "and")
    .replace(/\bIn\b/g, "in")
    .replace(/\bThe\b/g, "the")
    .replace(/\bOf\b/g, "of");

  if (capitalizeFirstLetter) {
    name = name.charAt(0).toUpperCase() + name.slice(1);
  }

  return name;
};

export async function fetchLinkedOrganizations(
  orgId,
  linkedOrganizations = [],
  depth = 0,
  isFirst = true
) {
  if (depth > 3) {
    return linkedOrganizations; // Stop recursion if depth exceeds 3
  }

  const apiUrl = `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations/${orgId}`;
  const response = await fetch(apiUrl);
  const data = await response.json();

  if (data?.Organisation?.Rels?.Rel) {
    for (const rel of data.Organisation.Rels.Rel) {
      const targetOrg = rel?.Target?.OrgId;
      const targetExtension = targetOrg?.extension;

      if (
        rel?.Status === "Active" &&
        targetOrg &&
        targetExtension &&
        !linkedOrganizations.some(
          (org) => org.OrgId?.extension === targetExtension
        )
      ) {
        await fetchLinkedOrganizations(
          targetExtension,
          linkedOrganizations,
          depth + 1,
          false
        );
      }
    }
  }

  // Include the current organization after processing its related organizations
  if (
    !isFirst &&
    !linkedOrganizations.some(
      (org) => org.OrgId?.extension === data?.Organisation?.OrgId?.extension
    )
  ) {
    linkedOrganizations.push({
      ...data.Organisation,
      Rels: undefined,
    });
  }

  return linkedOrganizations;
}

export const fetchOrganisationDetails = async (odsId) => {
  let response = await fetch(
    `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations/${odsId}`
  );
  response = await response.json();

  return response?.Organisation;
};

export const getODSAddress = (location) => {
  let address = [
    titleCaseNHS(location.AddrLn1),
    titleCaseNHS(location.Town),
    titleCaseNHS(location.County),
    titleCaseNHS(location.Country),
    location.PostCode,
  ];

  return address.filter((n) => n).join(", ");
};

export const getAddress = (organisation) => {
  let address = [
    titleCaseNHS(organisation.addressLine1),
    titleCaseNHS(organisation.town),
    titleCaseNHS(organisation.county),
    titleCaseNHS(organisation.country),
    organisation.postcode,
  ];

  return address.filter((n) => n).join(", ");
};

export const getODSTelephone = (odsDetails) => {
  return odsDetails?.Contacts?.Contact?.find(
    (contact) => contact.type === "tel"
  )?.value;
};

export const getPcn = (relationships) => {
  return relationships?.find((relation) => isPcn(relation));
};

export const isPcn = (organisation) => {
  return organisation?.Roles?.Role?.find((role) => {
    return role.Status === "Active" && role.id === "RO272"; // RO272 is the Primary Care Network role
  });
};

export const getIcb = (relationships) => {
  return relationships?.find((relation) => isIcb(relation));
};

export const isIcb = (organisation) => {
  return organisation?.Roles?.Role?.find((role) => {
    return role.Status === "Active" && role.id === "RO318";
  });
};

export const isPractice = (organisation) => {
  return organisation?.Roles?.Role?.find((role) => {
    return role.Status === "Active" && role.id === "RO76";
  });
};

export const getNHSRegion = (relationships) => {
  return relationships?.find((relation) => {
    return relation?.Roles?.Role?.find((role) => {
      return role.Status === "Active" && role.id === "RO209"; // RO318 is the NHS region role
    });
  });
};

export const fetchOrganisationsWithinPcn = async (pcn, relationships) => {
  let response = await fetch(
    `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations?RelTypeId=RE8&TargetOrgId=${pcn.OrgId.extension}&RelStatus=active&Limit=1000`
  );
  response = await response.json();

  const icb = getIcb(relationships);
  const nhsRegion = getNHSRegion(relationships);

  let organisationsDetails = [];
  for (const organisation of response.Organisations) {
    const organisationDetails = await fetchOrganisationDetails(
      organisation.OrgId
    );
    organisationsDetails.push({
      ...organisationDetails,
      pcn,
      icb,
      nhsRegion,
    });
  }
  return organisationsDetails;
};

export const fetchOrganisationsWithinIcb = async (icbId) => {
  let response = await fetch(
    `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations?RelTypeId=RE5&TargetOrgId=${icbId}&RelStatus=active&Limit=1000`
  );
  response = await response.json();

  let pcnIds = [];

  await Promise.all(
    response.Organisations.map(async (organisation) => {
      if (organisation.PrimaryRoleId === "RO98") {
        // RO98 is the role type for clinical commissioning group
        let response = await fetch(
          `https://directory.spineservices.nhs.uk/ORD/2-0-0/organisations?RelTypeId=RE4&TargetOrgId=${organisation.OrgId}&RelStatus=active&Limit=1000`
        );
        response = await response.json();

        for (const organisation of response.Organisations) {
          if (organisation.PrimaryRoleId === "RO272") {
            // if it's a PCN
            pcnIds.push(organisation.OrgId);
          }
        }
      }
    })
  );

  const organisations = [];

  await Promise.all(
    pcnIds.map(async (pcnId) => {
      const pcnDetails = await fetchOrganisationDetails(pcnId);
      const relationships = await fetchLinkedOrganizations(pcnId);
      const organisationsInPcn = await fetchOrganisationsWithinPcn(
        pcnDetails,
        relationships
      );
      organisations.push(...organisationsInPcn);
    })
  );

  return organisations;

  // let organisationDetails = [];

  // return organisationDetails;
};
