import SurveysGridItemSkeleton from "./SurveysGridItemSkeleton";

const SurveysGridSkeleton = () => {
  return (
    <div className="animate-pulse">
      <div class="h-8 bg-gray-200 rounded-full dark:bg-gray-700 max-w-xl w-full mt-2"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 mt-4">
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
        <SurveysGridItemSkeleton />
      </div>
      <span class="sr-only">Loading...</span>
    </div>
  );
};

export default SurveysGridSkeleton;
