import { useState, useEffect } from "react";
import { generateClient, post, get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { useForm } from "react-hook-form";

import Button from "./Button";
import { sortTeamMembersByRole } from "../utilities/general";
import LoadBox from "./LoadBox";
import { ClockIcon } from "@heroicons/react/24/outline";

const client = generateClient();

export default function ManageUsersModal({ stack, open, setOpen, user }) {
  const { register, handleSubmit, reset} = useForm();
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loadingInvitedUsers, setLoadingInvitedUsers] = useState(true);
  const [userCannotBeAddedError, setUserCannotBeAddedError] = useState(false)

  const getInvitedUsers = async () => {
    setLoadingInvitedUsers(true);
    const accessToken = (
      await fetchAuthSession()
    ).tokens?.accessToken?.toString();
    const restOperation = get({
      apiName: "bordercrossRest",
      path: "/users/getInvitedUsers",
      options: {
        headers: {
          "jwt-token": "Basic " + accessToken,
        },
        queryParams: {
          stackId: stack.id,
        },
      },
    });
    const response = await restOperation.response;
    const invitedUsers = await response.body.json();
    setInvitedUsers(invitedUsers);
    setLoadingInvitedUsers(false);
  };

  useEffect(() => {
    getInvitedUsers();
  }, []);

  const teamDetails = sortTeamMembersByRole(stack.team);

  const userCanBeAdded = (email) => {
    const invitedUsersIncludesEmail = invitedUsers.find(invitedUser => invitedUser.email === email)
    const teamDetailsIncludesEmail = teamDetails?.owner?.email === email || teamDetails?.administators?.find(administrator => administrator?.email === email)

    if (invitedUsersIncludesEmail || teamDetailsIncludesEmail || !email) return false
    return true
  }

  const handleAddUser = async (data) => {
    setUserCannotBeAddedError(false)
    setLoadingInvitedUsers(true)

    if (userCanBeAdded(data.email)) {
      const accessToken = (
        await fetchAuthSession()
      ).tokens?.accessToken?.toString();
  
      const restOperation = post({
        apiName: "bordercrossRest",
        path: "/users/inviteUser",
        options: {
          headers: {
            "jwt-token": "Basic " + accessToken,
          },
          body: {
            stackId: stack.id,
            role: data.role,
            email: data.email,
            inviterName: `${user.givenName} ${user.familyName}`,
          },
        },
      });
      await restOperation.response;
      await getInvitedUsers()
      reset()
    } else {
      setUserCannotBeAddedError(true)
    }

    setLoadingInvitedUsers(false)
  };

  return (
    <>
      <div className="max-h-[75vh] overflow-scroll">
        <h2 className="text-xl sm:text-4xl font-bold">Manage Users</h2>
      </div>

      {loadingInvitedUsers ? (
        <LoadBox />
      ) : (
        <>
          <div className="my-8">
            <form onSubmit={(e) => e.preventDefault()}>
              <h3 className="text-lg sm:text-xl font-bold mb-2">
                Add New User
              </h3>
              <p className="text-base mb-1">Enter the person's email you would like to invite and we will email them with an invite link.</p>
              <p className="text-base mb-4">You may need to ask them to <strong>check their junk/spam folder</strong> for the invite.</p>
              <label
                htmlFor="email"
                className="block text-sm md:text-base font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="md:flex gap-2">
                <div className="grow">
                  <input
                    type="email"
                    {...register("email")}
                    name="email"
                    id="email"
                    className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6"
                  />
                </div>

                <div>
                  <select
                    aria-label="role"
                    id="role"
                    name="role"
                    {...register("role")}
                    className="block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-base sm:leading-6"
                    defaultValue="administrator"
                  >
                    <option value="administrator">Administrator</option>
                    <option value="readAccess">Read access</option>
                  </select>
                </div>

                <Button
                  color="blue"
                  onClick={handleSubmit((data) => handleAddUser(data))}
                >
                  Add User
                </Button>
              </div>
              <p className="text-sm text-gray-500 mt-2">Administrator = full access to view and manage your organisation</p>
              <p className="text-sm text-gray-500 mt-1">Read access = view only access to your organisation</p>
              {userCannotBeAddedError && (
                <div className="bg-red-50 text-red-600 p-2 mt-4 rounded-lg">
                  <p>You can't invite a user who has already been added or invited.</p>
                </div>
              )}
            </form>
          </div>

          {invitedUsers?.length > 0 && (
            <div className="my-8">
              <div className="flex gap-2 items-center mb-4 text-gray-500">
                <ClockIcon className="h-5 w-5" />
                <h3 className="text-lg sm:text-xl font-bold">Invited Users</h3>
              </div>
              <div className="mt-6 border-t border-gray-100">
                {invitedUsers.map((invitedUser) => {
                  let role = invitedUser?.role
                  if (role === 'administrator') role = 'Administrator'
                  if (role === 'readAccess') role = 'Read Access'
                  return (
                    <dl className="divide-y divide-gray-100">
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm sm:text-base font-medium leading-6 text-gray-500">
                          {role}
                        </dt>
                        <dd className="mt-1 text-sm sm:text-base leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                          {invitedUser.email}
                        </dd>
                      </div>
                    </dl>
                  );
                })}
              </div>
            </div>
          )}

          <div className="my-8">
            <h3 className="text-lg sm:text-xl font-bold mb-4">
              Existing Users
            </h3>

            <div className="mt-6 border-t border-gray-100">
              <dl className="divide-y divide-gray-100">
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm sm:text-base font-medium leading-6 text-gray-900">
                    Owner
                  </dt>
                  <dd className="mt-1 text-sm sm:text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {teamDetails?.owner?.email}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm sm:text-base font-medium leading-6 text-gray-900">
                    Administrators
                  </dt>
                  <dd className="mt-1 text-sm sm:text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {teamDetails?.administators.length ? (
                      <ul>
                        {teamDetails.administators.map((administrator) => {
                          return <li>{administrator.email}</li>;
                        })}
                      </ul>
                    ) : (
                      <div className="text-gray-500">No administrators</div>
                    )}
                  </dd>
                </div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm sm:text-base font-medium leading-6 text-gray-900">
                    Read access
                  </dt>
                  <dd className="mt-1 text-sm sm:text-base leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {teamDetails?.readAccess.length ? (
                      <ul>
                        {teamDetails.readAccess.map((readAccessUser) => {
                          return <li>{readAccessUser.email}</li>;
                        })}
                      </ul>
                    ) : (
                      <div className="text-gray-500">
                        No users with read access
                      </div>
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            {/* <button
          disabled={updatingLoading ? true : false}
          type="button"
          className={
            "inline-flex items-center w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:bg-blue-400 disabled:hover:bg-blue-400 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
          }
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {updatingLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Save
        </button>

        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => setOpen(false)}
          ref={cancelButtonRef}
        >
          Cancel
        </button> */}
          </div>
        </>
      )}
    </>
  );
}
