import React from "react";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom"

import ProductImage from "./ProductImage";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MyDashboardProductTiles = ({
  isReadAccess,
  stack,
  currentProduct,
  setCurrentProduct,
  productCategories,
  setPreselectedAddProductFilter,
  setAddProductModalOpen,
}) => {
  return (
    <div>
      <ul
        className="gap-5 sm:gap-6 divide-y divide-gray-300 rounded-lg overflow-hidden border border-rl-0 border-gray-300"
      >
        {productCategories?.map((productCategory) => {
          let productsInCategory = [];
          stack?.SoftwareProducts?.items?.forEach((productItem) => {
            const categoryId = productItem.softwareProduct?.productCategory?.id;
            if (categoryId === productCategory.id)
              productsInCategory.push(productItem);
          });
          productsInCategory.sort((productA, productB) => {
            if (productA.softwareProduct.name > productB.softwareProduct.name)
              return 1;
            if (productB.softwareProduct.name > productA.softwareProduct.name)
              return -1;
            return 0;
          });

          return (
            <li key={productCategory.id} className="flex p-4 sm:p-6 bg-white h-full">
              <div className="w-full">
                <h3 className="text-xl font-medium sm:ml-6">
                  {productCategory.name}
                </h3>
                <ul className="mt-3 flex flex-wrap gap-2 min-h-[128px]">
                  {productsInCategory.map(({ softwareProduct }) => {
                    const isCurrentProduct =
                      currentProduct?.id === softwareProduct.id;
                    return (
                      <li key={softwareProduct.name}>
                        {setCurrentProduct ? (
                          <button
                            className={classNames(
                              "relative w-28 sm:w-36 inline-flex flex-col items-center mb-4 hover:bg-gray-100 p-2 h-full",
                              isCurrentProduct && "bg-gray-200"
                            )}
                            onClick={() =>
                              isCurrentProduct
                                ? setCurrentProduct()
                                : setCurrentProduct(softwareProduct)
                            }
                          >
                            <ProductImage softwareProduct={softwareProduct} />
                            <div
                              className={classNames(
                                "mt-2 text-sm text-center",
                                isCurrentProduct && "font-bold"
                              )}
                            >
                              {softwareProduct.name}
                            </div>
                          </button>
                        ) : (
                          <Link
                            to={`/product/${softwareProduct.id}`}
                            className="relative w-28 sm:w-36 inline-flex flex-col items-center mb-4 p-2 h-full hover:underline hover:font-bold"
                          >
                            <ProductImage softwareProduct={softwareProduct} />
                            <div className="mt-2 text-sm text-center">
                              {softwareProduct.name}
                            </div>
                          </Link>
                        )}
                      </li>
                    );
                  })}
                  {/* {setCurrentProduct && !isReadAccess && (
                    <li className="flex justify-center w-28 sm:w-36 p-2">
                      <button
                        onClick={() => {
                          setPreselectedAddProductFilter(productCategory.id);
                          setAddProductModalOpen(true);
                        }}
                        type="button"
                        className="relative block h-24 w-24 rounded-lg border-2 border-dashed border-gray-300 p-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      >
                        <PlusCircleIcon className="mx-auto h-8 w-8 text-gray-400" />
                        <span className="mt-2 block text-sm leading-4 text-gray-600">
                          Add product
                        </span>
                      </button>
                    </li>
                  )} */}
                </ul>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default MyDashboardProductTiles;
