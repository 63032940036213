import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { classNames } from "../utilities/general";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

import OrganisationSearch from "./OrganisationSearch";
import CountriesInput from "./CountriesInput";
import LoadingIcon from "./LoadingIcon"

const OrganisationForm = ({ onSubmit, isSiteAdmin, loadingSubmission }) => {
  const { register, handleSubmit, setValue, control } = useForm({ defaultValues: {
    ...(isSiteAdmin && { adminPrivate: 'private' })
  }});
  const [selectedCountry, setSelectedCountry] = useState(null);

  return (
    <form
      className="lg:w-2/3"
      onSubmit={handleSubmit((data) => onSubmit({ ...data }))}
    >
      <div className="my-6">
        <OrganisationSearch register={register} setValue={setValue} />
        {/* <label
          htmlFor="name"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Organisation Name *
        </label>
        <div className="mt-4">
          <input
            {...register("name", { required: true })}
            name="name"
            required
            id="name"
            className="block w-full rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
          />
        </div> */}
      </div>

      {isSiteAdmin && (
        <div className="my-6">
          <Controller
            control={control}
            name="adminPrivate"
            render={({ field: { onChange, value } }) => (
              <div>
                <RadioGroup value={value} onChange={onChange} className="mt-2">
                  <RadioGroup.Label className="block text-md font-medium leading-6 text-gray-900">
                    Make this stack public or private? (If private it won't show on Community)
                  </RadioGroup.Label>
                  <div className="mt-4 grid grid-cols-2 gap-3 lg:grid-cols-3">
                    <RadioGroup.Option
                      value="private"
                      className={({ active, checked }) =>
                        classNames(
                          active ? "ring-2 ring-blue-600 ring-offset-2" : "",
                          checked
                            ? "bg-blue-600 text-white hover:bg-blue-500"
                            : "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
                          "flex items-center justify-center rounded-md py-3 px-3 cursor-pointer focus:outline-none text-sm font-semibold sm:flex-1"
                        )
                      }
                    >
                      <RadioGroup.Label
                        className="flex gap-1 items-center"
                        as="span"
                      >
                        {"private" === value && (
                          <CheckCircleIcon className="h-5 w-5" />
                        )}
                        Private
                      </RadioGroup.Label>
                    </RadioGroup.Option>
                    <RadioGroup.Option
                      value="public"
                      className={({ active, checked }) =>
                        classNames(
                          active ? "ring-2 ring-blue-600 ring-offset-2" : "",
                          checked
                            ? "bg-blue-600 text-white hover:bg-blue-500"
                            : "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
                          "flex items-center justify-center rounded-md py-3 px-3 cursor-pointer focus:outline-none text-sm font-semibold sm:flex-1"
                        )
                      }
                    >
                      <RadioGroup.Label
                        className="flex gap-1 items-center"
                        as="span"
                      >
                        {"public" === value && (
                          <CheckCircleIcon className="h-5 w-5" />
                        )}
                        Public
                      </RadioGroup.Label>
                    </RadioGroup.Option>
                  </div>
                </RadioGroup>
              </div>
            )}
          />
        </div>
      )}

      {/* <CountriesInput
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
      /> */}

      {/* <div className="my-6">
        <label
          htmlFor="ownershipType"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Ownership Type *
        </label>
        <div className="mt-4">
          <select
            id="ownershipType"
            name="ownershipType"
            className="block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-md sm:leading-6"
            defaultValue="PRIVATE"
            required
            {...register("ownershipType", { required: true })}
          >
            <option value="PRIVATE">Private</option>
            <option value="PUBLIC">Public</option>
            <option value="MIXED">Mixed</option>
          </select>
        </div>
      </div>

      <div className="my-6">
        <label
          htmlFor="sector"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Sector *
        </label>
        <div className="mt-4">
          <select
            id="sector"
            name="sector"
            className="block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-md sm:leading-6"
            defaultValue="HEALTHCARE"
            required
            {...register("sector", { required: true })}
          >
            <option value="HEALTHCARE">Healthcare</option>
            <option value="TECHNOLOGY">Technology</option>
            <option value="EDUCATION">Education</option>
            <option value="OTHER">Other</option>
          </select>
        </div>
      </div>

      <div className="my-6">
        <label
          htmlFor="staffCount"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Staff Count *
        </label>
        <div className="mt-4">
          <input
            {...register("staffCount", { required: true })}
            type="number"
            name="staffCount"
            required
            id="staffCount"
            className="block w-full max-w-xs rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
          />
        </div>
      </div>

      <div className="my-6">
        <label
          htmlFor="customerCount"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Patient Count *
        </label>
        <div className="mt-4">
          <input
            {...register("customerCount", { required: true })}
            type="number"
            name="customerCount"
            required
            id="customerCount"
            className="block w-full max-w-xs rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
          />
        </div>
      </div> */}

      <button
        disabled={loadingSubmission}
        type="submit"
        className="inline-flex items-center rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
      {loadingSubmission && <LoadingIcon height="h-4" width="w-4" textColor="text-white" />} Next <ArrowRightIcon className="h-4 w-4 ml-1" />
      </button>
    </form>
  );
};

export default OrganisationForm;
