import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { useParams, Link } from "react-router-dom";
import Timeline, {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";

import LoadBox from "../LoadBox";
import ProductImage from "../ProductImage";
import {
  getSoftwareStack,
  listProductManagementDetails,
} from "../../graphql/queries-static";
import "./ContractsTimelinePage.css";

const client = generateClient();

const ContractsTimelinePage = ({ cognitoUser }) => {
  const { stackId } = useParams();
  const [stack, setStack] = useState([]);
  const [productManagementDetails, setProductManagementDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getStack = async () => {
      setLoading(true);
      const softwareStacks = await client.graphql({
        query: getSoftwareStack,
        variables: {
          id: stackId,
        },
        authMode: "userPool",
      });

      const productManagementDetails = await client.graphql({
        query: listProductManagementDetails,
        variables: {
          limit: 1000,
          filter: {
            softwarestackID: { eq: stackId },
          },
        },
        authMode: "userPool",
      });

      setStack(softwareStacks.data.getSoftwareStack);
      setProductManagementDetails(
        productManagementDetails.data.listProductManagementDetails.items
      );
      setLoading(false);
    };
    getStack();
  }, []);

  let startDate = new Date();
  startDate.setDate(startDate.getDate() + 1);

  let secondStartDate = new Date();
  secondStartDate.setDate(secondStartDate.getDate() + 1);

  const groups = stack?.SoftwareProducts?.items?.map((product, index) => {
    return {
      id: product.softwareProductId,
      title: (
        <div className="flex items-center">
          <span className="flex-shrink-0 mr-1">
            <ProductImage
              softwareProduct={product.softwareProduct}
              height="h-10"
              width="w-10"
            />
          </span>
          {product.softwareProduct.name}
        </div>
      ),
    };
  });

  const items = productManagementDetails.map(
    (productManagementDetail, index) => {
      if (!productManagementDetail.contractEndDate) return {};

      const group =
        productManagementDetail.productManagementDetailsSoftwareProductId;
      const startDate = +new Date(productManagementDetail.contractStartDate);
      const endDate = +new Date(productManagementDetail.contractEndDate);

      return {
        id: index,
        group: group,
        start_time: startDate,
        end_time: endDate,
        canMove: false,
        canResize: false,
      };
    }
  );

  const dateNow = new Date();
  const startOfMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
  const datePlus12Months = new Date(dateNow.setMonth(dateNow.getMonth() + 12));

  return (
    <>
      {loading ? (
        <LoadBox />
      ) : (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
            <Link
              to={`/dashboard?stackId=${stackId}`}
              className="mb-8 inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back to dashboard
            </Link>
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl mb-8">
              Contract Timeline
            </h1>
          </div>

          <div className="mx-6">
            <Timeline
              groups={groups}
              items={items}
              defaultTimeStart={startOfMonth}
              defaultTimeEnd={datePlus12Months}
              sidebarWidth={250}
              lineHeight={45}
              timeSteps={{
                second: 0,
                minute: 0,
                hour: 0,
                day: 1,
                month: 1,
                year: 1,
              }}
            >
              <TimelineMarkers>
                <TodayMarker />
              </TimelineMarkers>
            </Timeline>
          </div>
        </>
      )}
    </>
  );
};

export default ContractsTimelinePage;
