import React, { useState, useEffect } from "react";
import { PlusIcon, ArrowRightIcon } from "@heroicons/react/20/solid";
import { generateClient } from "aws-amplify/api";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { fetchPheData } from "../../../utilities/phe";

import {
  listSoftwareStacks,
  listSoftwareProducts,
  listProductFilters,
  getSoftwareStackWithProductManagements,
} from "../../../graphql/queries-static";

// import ProductDetails from "../ProductDetails";
import LoadBox from "../../LoadBox";
import StackTabs from "../../StackTabs";

import ProductManagementStats from "../../ProductManagementStats";

import { OrgStats } from "../../OrgStats";

import YourStack from "./YourStack";

import MyStackModals from "./MyStackModals";
import Button from "../../Button";
// import { fetchLinkedOrganizations } from "../../utilities/ods";

const client = generateClient();

const MyStackDashboard = ({ user, cognitoUser, cognitoUserAttributes }) => {
  const [stackList, setStackList] = useState([]);
  const [loadingStackList, setLoadingStackList] = useState(true);
  const [loadingStack, setLoadingStack] = useState(true);
  const [stack, setStack] = useState();
  const [currentProduct, setCurrentProduct] = useState();
  const [odsRelationships, setOdsRelationships] = useState([]);
  const [odsDetails, setOdsDetails] = useState();
  const [pheDetails, setPheDetails] = useState();
  const [loadingOdsData, setLoadingOdsData] = useState(false);
  const [loadingPheData, setLoadingPheData] = useState(false);
  const [showOrgInsights, setShowOrgInsights] = useState(false);
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  const [editProductModalOpen, setEditProductModalOpen] = useState(false);
  const [manageUsersModalOpen, setManageUsersModalOpen] = useState(false);
  const [removeProductDialogOpen, setRemoveProductDialogOpen] = useState(false);
  const [removeStackDialogOpen, setRemoveStackDialogOpen] = useState(false);
  const [preselectedAddProductFilter, setPreselectedAddProductFilter] =
    useState();
  const [products, setProducts] = useState([]);
  const [productFilters, setProductFilters] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const getStackList = async () => {
    setLoadingStackList(true);
    const softwareStacks = await client.graphql({
      query: listSoftwareStacks,
      variables: {
        limit: 1000,
        filter: {
          or: [
            { owner: { eq: cognitoUser.userId } },
            { administrator: { contains: cognitoUser.userId } },
            { readAccess: { contains: cognitoUser.userId } },
          ],
        },
      },
      authMode: "userPool",
    });

    const sortedStacks = softwareStacks.data.listSoftwareStacks.items?.sort(
      (stackA, stackB) => {
        const getStackName = (stack) => {
          return stack?.organisation?.name || stack.name;
        };
        const stackAName = getStackName(stackA);
        const stackBName = getStackName(stackB);

        if (stackAName > stackBName) return 1;
        if (stackBName > stackAName) return -1;
        return 0;
      }
    );

    if (!sortedStacks.length) navigate("/updateStack");

    setStackList(sortedStacks);

    setLoadingStackList(false);
    return sortedStacks;
  };

  const getStack = async (id, setLoading = true) => {
    setLoading && setLoadingStack(true);
    let stack = await client.graphql({
      query: getSoftwareStackWithProductManagements,
      variables: {
        id: id,
        filter: {
          or: [
            { owner: { eq: cognitoUser.userId } },
            { administrator: { contains: cognitoUser.userId } },
            { readAccess: { contains: cognitoUser.userId } },
          ],
        },
      },
      authMode: "userPool",
    });
    stack = stack.data.getSoftwareStack;
    setStack(stack);
    setLoading && setLoadingStack(false);
    return stack;
  };

  useEffect(() => {
    const fetchStacks = async () => {
      const stackList = await getStackList();

      if (stackList.length) {
        const urlParams = new URLSearchParams(location.search);
        const stackId = urlParams.get("stackId");
        const matchedStack = stackList?.find((stack) => stack.id === stackId);
        if (stackId && matchedStack) {
          await getStack(matchedStack.id);
        } else {
          await getStack(stackList[0].id);
        }
      }
    };
    fetchStacks();
  }, []);

  // console.log(stackList, 'stack list')
  // console.log(stack, 'stacks')

  useEffect(() => {
    const fetchPheDetails = async () => {
      setLoadingPheData(true);
      try {
        const populationSummaryUrl = `https://fingertips.phe.org.uk/api/quinary_population_summary?area_code=${stack.organisation.odsId}&area_type_id=7`;
        const populationUrl = `https://fingertips.phe.org.uk/api/quinary_population?area_code=${stack.organisation.odsId}&area_type_id=7`;
        // const mentalHealthIndicatorsUrl =
        //   "https://corsproxy.io/?" +
        //   encodeURIComponent(
        //     `https://fingertips.phe.org.uk/api/latest_data/all_indicators_in_profile_group_for_child_areas?area_type_id=7&profile_id=20&parent_area_code=${stack.organisation.odsId}&group_id=1938132829`
        //   );
        //   const mentalHealthIndicatorsMetadataUrl =
        //   "https://corsproxy.io/?" +
        //   encodeURIComponent(
        //     `https://fingertips.phe.org.uk/api/indicator_metadata/by_group_id?group_ids=1938132829`
        //   );

        let [
          populationSummaryResponse,
          populationResponse,
          // mentalHealthIndicatorsResponse,
          // mentalHealthIndicatorsMetadataResponse,
        ] = await Promise.all([
          await fetchPheData(populationSummaryUrl),
          await fetchPheData(populationUrl),
          // await fetch(mentalHealthIndicatorsUrl),
          // await fetch(mentalHealthIndicatorsMetadataUrl),
        ]);

        setPheDetails({
          populationSummary: populationSummaryResponse,
          population: populationResponse,
          // mentalHealthIndicators: mentalHealthIndicatorsResponse,
          // mentalHealthIndicatorsMetadata: mentalHealthIndicatorsMetadataResponse,
        });
      } catch (err) {
        setPheDetails();
      }
      setLoadingPheData(false);
    };

    if (stack?.organisation?.odsId) {
      fetchPheDetails();
    } else {
      setPheDetails();
    }
  }, [stack]);

  useEffect(() => {
    const getProducts = async () => {
      const softwareProducts = await client.graphql({
        query: listSoftwareProducts,
        variables: {
          limit: 1000,
        },
        authMode: 'userPool'
      });
      const sortedProducts =
        softwareProducts.data.listSoftwareProducts.items.sort(
          (productA, productB) => {
            if (productA.name > productB.name) return 1;
            if (productB.name > productA.name) return -1;
            return 0;
          }
        );
      setProducts(sortedProducts);
    };
    getProducts();
  }, []);

  useEffect(() => {
    const getFilters = async () => {
      const productFilters = await client.graphql({
        query: listProductFilters,
        authMode: 'userPool'
      });

      const sortedProductTags = productFilters.data.listProductTags.items.sort(
        (productTagA, productTagB) => {
          if (productTagA.name > productTagB.name) return 1;
          if (productTagB.name > productTagA.name) return -1;
          return 0;
        }
      );

      const sortedProductCategories =
        productFilters.data.listProductCategories.items.sort(
          (productCategoryA, productCategoryB) => {
            if (productCategoryA.name > productCategoryB.name) return 1;
            if (productCategoryB.name > productCategoryA.name) return -1;
            return 0;
          }
        );

      setProductFilters([
        {
          id: "category",
          name: "Category",
          options: sortedProductCategories,
        },
        {
          id: "tag",
          name: "Tags",
          options: sortedProductTags,
        },
      ]);
    };

    getFilters();
  }, []);

  useEffect(() => {
    if (addProductModalOpen === false) setPreselectedAddProductFilter();
  }, [addProductModalOpen]);

  const selectedProductManagementDetail =
    stack?.StackProductManagements?.items?.find((productManagementDetail) => {
      const isSameProduct =
        productManagementDetail.productManagementDetailsSoftwareProductId ===
        currentProduct?.id;
      const isSameStack = productManagementDetail.softwarestackID === stack?.id;
      return isSameProduct && isSameStack;
    });

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
      <div className="flex flex-col md:flex-row md:items-center gap-4">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Infrastructure Mapping
        </h1>
        <div className="flex flex-wrap gap-2">
          {cognitoUserAttributes?.isSiteAdmin && (
            <div>
              <Link
                to="/updateStack"
                className="inline-flex items-center bg-white text-center rounded-md px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 focus-visible:outline ring-1 ring-inset ring-gray-300 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                <PlusIcon className="h-5 w-5 mr-1" />
                Create new organisation
              </Link>
            </div>
          )}
          {stackList.length > 0 && (
            <div>
              <Link
                to="/dashboard/summary"
                className="flex items-center text-center rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                View full summary
                <ArrowRightIcon className="h-4 w-4 ml-1" />
              </Link>
            </div>
          )}
        </div>
      </div>

      {loadingStackList ? (
        <LoadBox />
      ) : (
        <>
          {stackList.length > 0 ? (
            <>
              <div className="my-6 py-4 border border-gray-300 border-r-0 border-l-0">
                <StackTabs
                  cognitoUser={cognitoUser}
                  stackList={stackList}
                  getStack={getStack}
                  setCurrentProduct={setCurrentProduct}
                />
              </div>
              {loadingStack ? (
                <LoadBox />
              ) : (
                <>
                  <div className="mt-6">
                    
                    <div className="flex gap-4 items-center mt-10">
                      <h2 className="text-3xl font-bold">
                        Organisation Overview
                      </h2>
                      <Button
                        onClick={() => setShowOrgInsights(!showOrgInsights)}
                        color="indigo"
                        variant="soft"
                      >
                        {showOrgInsights ? "Hide" : "Show"} Organisation Insights
                      </Button>
                    </div>

                    <div className="my-6">
                      <ProductManagementStats
                        pheDetails={pheDetails}
                        stack={stack}
                      />
                    </div>

                    {showOrgInsights && stack?.organisation && (
                      <>
                        <h2 className="text-3xl font-bold mt-10">
                          Organisation Insights
                        </h2>
                        <div className="my-6">
                          <OrgStats
                            loading={loadingOdsData || loadingPheData}
                            organisation={stack.organisation}
                            pheDetails={pheDetails}
                            odsDetails={odsDetails}
                            odsRelationships={odsRelationships}
                          />
                        </div>
                      </>
                    )}

                    <YourStack
                      cognitoUser={cognitoUser}
                      cognitoUserAttributes={cognitoUserAttributes}
                      currentProduct={currentProduct}
                      pheDetails={pheDetails}
                      loadingPheData={loadingPheData}
                      productFilters={productFilters}
                      selectedProductManagementDetail={
                        selectedProductManagementDetail
                      }
                      setAddProductModalOpen={setAddProductModalOpen}
                      setCurrentProduct={setCurrentProduct}
                      setEditProductModalOpen={setEditProductModalOpen}
                      setManageUsersModalOpen={setManageUsersModalOpen}
                      setPreselectedAddProductFilter={
                        setPreselectedAddProductFilter
                      }
                      setRemoveProductDialogOpen={setRemoveProductDialogOpen}
                      setRemoveStackDialogOpen={setRemoveStackDialogOpen}
                      stack={stack}
                    />
                  </div>
                  <MyStackModals
                    addProductModalOpen={addProductModalOpen}
                    currentProduct={currentProduct}
                    editProductModalOpen={editProductModalOpen}
                    getStack={getStack}
                    manageUsersModalOpen={manageUsersModalOpen}
                    preselectedAddProductFilter={preselectedAddProductFilter}
                    productFilters={productFilters}
                    products={products}
                    removeProductDialogOpen={removeProductDialogOpen}
                    removeStackDialogOpen={removeStackDialogOpen}
                    selectedProductManagementDetail={
                      selectedProductManagementDetail
                    }
                    setAddProductModalOpen={setAddProductModalOpen}
                    setCurrentProduct={setCurrentProduct}
                    setEditProductModalOpen={setEditProductModalOpen}
                    setManageUsersModalOpen={setManageUsersModalOpen}
                    setPreselectedAddProductFilter={
                      setPreselectedAddProductFilter
                    }
                    setRemoveProductDialogOpen={setRemoveProductDialogOpen}
                    setRemoveStackDialogOpen={setRemoveStackDialogOpen}
                    stack={stack}
                    user={user}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <p className="my-8">No stacks here yet.</p>
              <Link
                to="/updateStack"
                className="inline-flex items-center rounded-md bg-blue-600 px-3.5 py-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Create a stack <ArrowRightIcon className="h-4 w-4 ml-1" />
              </Link>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MyStackDashboard;
