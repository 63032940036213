import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  ArrowPathIcon,
  ChatBubbleBottomCenterTextIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

import surveyQuestions from "../../data/surveyQuestions";
import { classNames } from "../../../utilities/general";
import Button from "../../Button";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ProductComments = ({ survey, selectedProduct, isSiteAdmin }) => {
  const [loadingCommentRefresh, setLoadingCommentRefresh] = useState(false);

  let commentsCount = countComments(survey);
  let commentSentimentCount = {
    POSITIVE: 0,
    NEGATIVE: 0,
    NEUTRAL: 0,
    MIXED: 0,
  };

  survey?.completions?.forEach((completion) => {
    completion?.answers?.forEach((answer) => {
      if (answer.commentSentiment)
        commentSentimentCount[answer.commentSentiment] =
          commentSentimentCount[answer.commentSentiment] + 1;
    });
  });

  const data = {
    labels: ["Positive", "Negative", "Neutral/Mixed"],
    datasets: [
      {
        data: [
          commentSentimentCount.POSITIVE,
          commentSentimentCount.NEGATIVE,
          commentSentimentCount.NEUTRAL + commentSentimentCount.MIXED,
        ],
        backgroundColor: ["#86efac", "#fda4af", "#e5e7eb"],
        hoverOffset: 4,
      },
    ],
  };

  const productCommentSummaries = survey.commentSummaries.find(
    (commentSummary) => commentSummary.productId === selectedProduct.id
  );

  let parsedSummaries;
  try {
    parsedSummaries = JSON.parse(productCommentSummaries?.summaryText);
  } catch (e) {}

  const refreshCommentSummaries = async () => {
    setLoadingCommentRefresh(true);

    const accessToken = (
      await fetchAuthSession()
    ).tokens?.accessToken?.toString();

    const restOperation = post({
      apiName: "bordercrossRest",
      path: `/surveys/productSurvey/updateSurveyCommentSummaries/${survey.id}/${selectedProduct.id}`,
      options: {
        headers: {
          "jwt-token": "Basic " + accessToken,
        },
        body: {},
      },
    });
    await restOperation.response;

    setLoadingCommentRefresh(false);
    window.location.reload();
  };

  return (
    <>
      <div className="flex justify-between gap-4">
        <div className="bg-gray-100 rounded-lg p-5">
          <div className="inline-flex flex-col-reverse gap-y-2">
            <dt className="text-base text-gray-600">Total comments received</dt>
            <dd className="text-6xl font-semibold tracking-tight text-gray-900">
              {commentsCount}
            </dd>
          </div>
        </div>

        <div>
          <p className="text-base text-gray-600 mb-2">Comments breakdown</p>
          <div>
            <Doughnut
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  datalabels: {
                    color: "black",
                    anchor: "centre",
                    align: "centre",
                    font: {
                      weight: "bold",
                      size: "14",
                    },
                  },
                  legend: { position: "right" },
                },
              }}
            />
          </div>
        </div>
      </div>

      {parsedSummaries && (
        <div className="mt-8 bg-yellow-50 rounded-lg p-5">
          <div className="flex items-center gap-2 font-semibold">
            <p>Summary</p>
            <div className="inline-flex items-center gap-1 text-yellow-500 text-xs">
              <SparklesIcon className="h-4 w-4" />
              AI Powered
            </div>

            {isSiteAdmin && (
              <Button
                className="text-xs"
                color="gray"
                variant="soft"
                onClick={refreshCommentSummaries}
              >
                <ArrowPathIcon
                  className={classNames(
                    loadingCommentRefresh && "animate-spin",
                    "text-gray-700 h-4 w-4 mr-1"
                  )}
                />{" "}
                Refresh
              </Button>
            )}
          </div>

          <div className="my-4">
            <div className="flex gap-4">
              <div className="bg-green-50 p-4 rounded-lg w-full">
                <p className="font-semibold text-sm text-green-700">Pros</p>
                <ul className="text-sm list-disc pl-4">
                  {parsedSummaries?.pros?.map((pro) => (
                    <li className="mt-2">{pro}</li>
                  ))}
                </ul>
              </div>

              <div className="bg-red-50 p-4 rounded-lg w-full">
                <p className="font-semibold text-sm text-red-700">Cons</p>
                <ul className="text-sm list-disc pl-4">
                  {parsedSummaries?.cons?.map((con) => (
                    <li className="mt-2">{con}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <ul className="text-sm list-disc pl-4">
            {parsedSummaries?.summary?.map((summary) => (
              <li className="mt-2">{summary}</li>
            ))}
          </ul>

          {parsedSummaries?.userSuggestions && (
            <div className="mt-4">
              <p className=" font-semibold text-sm">Key actions</p>
              <ul className="text-sm list-disc pl-4">
                {parsedSummaries?.userSuggestions?.map((userSuggestion) => (
                  <li className="mt-2">{userSuggestion}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <div className="space-y-10 mt-8">
        {surveyQuestions.productSurvey.productQuestions.map((productQuestion) => {
          const questionComments = [];
          survey?.completions?.forEach((completion) => {
            completion?.answers?.forEach((answer) => {
              if (
                answer.productId === selectedProduct.id &&
                answer.questionId === productQuestion.id &&
                answer.comment
              ) {
                questionComments.push({
                  role: completion.role,
                  comment: answer.comment,
                  commentSentiment: answer.commentSentiment,
                });
                commentsCount++;
              }
            });
          });

          if (!questionComments.length) return;

          // test below
          return (
            <div>
              <p className="text-lg font-semibold mb-4">
                "
                {productQuestion.question.replace(
                  "<<softwareName>>",
                  selectedProduct.name
                )}
                "
              </p>
              <div className="space-y-4">
                {questionComments.map((comment) => {
                  let sentimentColours = {
                    border: {
                      POSITIVE: "border-green-400",
                      NEGATIVE: "border-red-400",
                      NEUTRAL: "border-gray-400",
                      MIXED: "border-gray-400",
                    },
                    text: {
                      POSITIVE: "text-green-600",
                      NEGATIVE: "text-red-600",
                      NEUTRAL: "text-gray-600",
                      MIXED: "text-gray-600",
                    },
                    background: {
                      POSITIVE: "bg-green-50",
                      NEGATIVE: "bg-red-50",
                      NEUTRAL: "bg-gray-50",
                      MIXED: "bg-gray-50",
                    },
                  };
                  return (
                    <div
                      className={classNames(
                        sentimentColours.border[comment.commentSentiment],
                        sentimentColours.background[comment.commentSentiment],
                        "flex gap-4 items-center justify-between border-l-4 mt-2"
                      )}
                    >
                      <div className="flex gap-2 py-2 ml-4">
                        <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mt-1 text-gray-600" />
                        <div>
                          <p className="leading-5">{comment.comment}</p>
                          <p className="text-sm text-gray-600 mt-1">
                            {comment.role}
                          </p>
                        </div>
                      </div>
                      <p
                        className={classNames(
                          sentimentColours.text[comment.commentSentiment],
                          "mr-4 text-xs text-right font-semibold min-w-[4rem]"
                        )}
                      >
                        {comment.commentSentiment}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        {commentsCount === 0 && <p>No comments received</p>}
      </div>
    </>
  );
};

export default ProductComments;

const countComments = (survey) => {
  let commentsCount = 0;
  survey?.completions?.forEach((completion) => {
    completion?.answers?.forEach((answer) => {
      if (answer.comment) {
        commentsCount++;
      }
    });
  });

  return commentsCount;
};
