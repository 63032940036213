import {
  ArrowUturnRightIcon,
  BeakerIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  DocumentPlusIcon,
  DocumentTextIcon,
  GlobeEuropeAfricaIcon,
  HeartIcon,
  PencilSquareIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";

import {
  ArrowUturnRightIcon as ArrowUturnRightIconSolid,
  BeakerIcon as BeakerIconSolid,
  ClipboardDocumentListIcon as ClipboardDocumentListIconSolid,
  ClockIcon as ClockIconSolid,
  DocumentPlusIcon as DocumentPlusIconSolid,
  DocumentTextIcon as DocumentTextIconSolid,
  GlobeEuropeAfricaIcon as GlobeEuropeAfricaIconSolid,
  HeartIcon as HeartIconSolid,
  PencilSquareIcon as PencilSquareIconSolid,
  PresentationChartLineIcon as PresentationChartLineIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  UserPlusIcon as UserPlusIconSolid,
} from "@heroicons/react/24/solid";

const surveyQuestions = {
  productSurvey: {
    introductionQuestions: [
      {
        question: "Your role",
        options: [
          { id: 1, name: "Doctor" },
          { id: 2, name: "Nurse" },
          { id: 3, name: "Pharmacist" },
          { id: 4, name: "Physician associate" },
          { id: 5, name: "Receptionist" },
          { id: 6, name: "Secretary" },
          { id: 7, name: "Manager" },
          { id: 8, name: "Advanced nurse practitioner" },
          { id: 9, name: "Midwife" },
          { id: 10, name: "Administrative Staff" },
          { id: 11, name: "Other" },
          { id: 12, name: "Clinical/senior pharmacist" },
          { id: 13, name: "Pharmacy technician" },
          { id: 14, name: "Social prescribing link worker" },
          { id: 15, name: "Health and wellbeing coach" },
          { id: 16, name: "Care co-ordinator" },
          { id: 17, name: "First contact physiotherapist" },
          { id: 18, name: "Paramedic" },
          { id: 19, name: "Occupational therapist" },
          { id: 20, name: "Podiatrist" },
          { id: 21, name: "Dietitian" },
          { id: 22, name: "Nursing associate" },
          { id: 23, name: "Adult mental health practitioner" },
          {
            id: 24,
            name: "Children and young people's mental health practitioner",
          },
          { id: 25, name: "General practice assistant" },
          { id: 26, name: "Digital and transformation lead" },
          { id: 27, name: "Advanced practitioner" },
          { id: 28, name: "Care navigator" },
        ],
      },
    ],
    productQuestions: [
      {
        id: "1",
        question: "I find <<softwareName>> easy to use",
        shortQuestion: "Usability",
        type: "rating",
        required: true,
      },
      {
        id: "2",
        question: "I find <<softwareName>> easy to learn",
        shortQuestion: "Learnability",
        type: "rating",
        required: true,
      },
      {
        id: "3",
        question: "I received sufficient training to use <<softwareName>>",
        shortQuestion: "Training",
        type: "rating",
        required: true,
      },
      {
        id: "4",
        question:
          "<<softwareName>> is reliable when completing my day-to-day tasks",
        shortQuestion: "Reliability",
        type: "rating",
        required: true,
      },
      {
        id: "5",
        question:
          "<<softwareName>> offers all the features I need (if not please specify)",
        shortQuestion: "Full-featured",
        type: "rating",
        required: true,
      },
      {
        id: "6",
        question: "<<softwareName>> integrates well with other systems we use",
        shortQuestion: "Interoperability",
        type: "rating",
        required: true,
      },
      {
        id: "7",
        question: "I rely on <<softwareName>> to do my job",
        shortQuestion: "Reliance",
        type: "rating",
        required: true,
      },
      {
        id: "8",
        question: "I would recommend <<softwareName>> to another organisation",
        shortQuestion: "Recommended",
        type: "rating",
        required: true,
      },
    ],
    tags: ["Digital"],
  },
  businessProcessSurvey: {
    introductionQuestions: [
      {
        question: "Your role",
        options: [
          { id: 1, name: "Doctor" },
          { id: 2, name: "Nurse" },
          { id: 3, name: "Pharmacist" },
          { id: 4, name: "Physician associate" },
          { id: 5, name: "Receptionist" },
          { id: 6, name: "Secretary" },
          { id: 7, name: "Manager" },
          { id: 8, name: "Advanced nurse practitioner" },
          { id: 9, name: "Midwife" },
          { id: 10, name: "Administrative Staff" },
          { id: 11, name: "Other" },
          { id: 12, name: "Clinical/senior pharmacist" },
          { id: 13, name: "Pharmacy technician" },
          { id: 14, name: "Social prescribing link worker" },
          { id: 15, name: "Health and wellbeing coach" },
          { id: 16, name: "Care co-ordinator" },
          { id: 17, name: "First contact physiotherapist" },
          { id: 18, name: "Paramedic" },
          { id: 19, name: "Occupational therapist" },
          { id: 20, name: "Podiatrist" },
          { id: 21, name: "Dietitian" },
          { id: 22, name: "Nursing associate" },
          { id: 23, name: "Adult mental health practitioner" },
          {
            id: 24,
            name: "Children and young people’s mental health practitioner",
          },
          { id: 25, name: "General practice assistant" },
          { id: 26, name: "Digital and transformation lead" },
          { id: 27, name: "Advanced practitioner" },
          { id: 28, name: "Care navigator" },
        ],
      },
    ],
    processes: [
      {
        id: "1",
        name: "Appointment Bookings",
        tags: ["Access"],
        aiDescription:
          "The appointment booking process includes scheduling patient visits, managing cancellations and rescheduling, and ensuring optimal utilization of healthcare providers' time. It aims to minimize no-shows and overbooking while maintaining a smooth flow of appointments. Staff may face challenges related to system usability, workload distribution, and patient communication.",
        icon: ClockIcon,
        iconSolid: ClockIconSolid,
        deprecated: true,
        groupWithProcessId: "6",
        questions: [
          {
            id: "1",
            question:
              "The current appointment booking process is easy to follow",
            shortQuestion: "Ease of Process",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The appointment booking process is efficient and saves time",
            shortQuestion: "Efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question: "The current process reliably prevents booking errors",
            shortQuestion: "Reliability",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "The appointment booking process facilitates good communication among staff",
            shortQuestion: "Communication",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What specific aspects of the appointment booking process do you find challenging?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What specific aspects of the appointment booking process do you find effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What improvements would you suggest for the appointment booking process?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "2",
        name: "Lab reports",
        tags: ["Quality"],
        shortDescription:
          "Lab results management involves the efficient handling, review, and communication of test outcomes to ensure timely and accurate patient care.",
        aiDescription:
          "Lab reports management encompasses the collection, processing, and communication of laboratory results. This includes coordinating tests, ensuring accurate and timely reporting, and integrating results into patient records. Challenges can include handling lab errors, ensuring timely follow-up, and maintaining effective communication with patients and other healthcare providers.",
        icon: BeakerIcon,
        iconSolid: BeakerIconSolid,
        questions: [
          {
            id: "1",
            question:
              "The current process for managing lab reports is easy to follow",
            shortQuestion: "Ease of Process",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The lab reports management process is efficient and saves time",
            shortQuestion: "Efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question: "The current process reliably prevents lab report errors",
            shortQuestion: "Reliability",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "The lab reports management process facilitates good communication among staff",
            shortQuestion: "Communication",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What specific aspects of the lab reports management process do you find challenging?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What specific aspects of the lab reports management process do you find effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What improvements would you suggest for the lab reports management process?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "3",
        name: "Prescription Management",
        tags: ["Quality"],
        shortDescription:
          "Prescription management involves the accurate processing, dispensing, and monitoring of medications to ensure safe and effective patient treatment.",
        aiDescription:
          "The prescription management process involves the generation, processing, and dispensing of prescriptions for patients. Key activities include ensuring accuracy in prescription details, handling medication requests, managing electronic prescriptions, and coordinating with pharmacies. Challenges often arise in areas such as prescription errors, workload management, and ensuring timely medication access for patients.",
        icon: DocumentPlusIcon,
        iconSolid: DocumentPlusIconSolid,
        questions: [
          {
            id: "1",
            question:
              "The current prescription management process is easy to follow",
            shortQuestion: "Ease of Process",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The prescription management process is efficient and saves time",
            shortQuestion: "Efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "The current process reliably prevents prescription errors",
            shortQuestion: "Reliability",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "The prescription management process facilitates good communication among staff",
            shortQuestion: "Communication",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What specific aspects of the prescription management process do you find challenging?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What specific aspects of the prescription management process do you find effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What improvements would you suggest for the prescription management process?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "4",
        name: "Document Management",
        tags: ["Quality"],
        shortDescription:
          "Inbound document management involves the organised handling, processing, and integration of incoming patient documents into the healthcare system to ensure accurate and accessible records.",
        aiDescription:
          "Document management involves the management of incoming document received by the practice either electronically or physically from patients, healthcare providers or other sources. This process is crucial for maintaining accurate patient histories, complying with regulations, and facilitating smooth operations within the practice. Issues often include volume of documents received, handling of documents when adding to the electronic record system and subsequent workflow processes for having them reviewed.",
        icon: DocumentTextIcon,
        iconSolid: DocumentTextIconSolid,
        questions: [
          {
            id: "1",
            question:
              "The current document management process is easy to follow",
            shortQuestion: "Ease of Process",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The document management process is efficient and saves time",
            shortQuestion: "Efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "The current process reliably prevents document mismangement",
            shortQuestion: "Reliability",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "The document management process facilitates good communication among staff",
            shortQuestion: "Communication",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What specific aspects of the document management process do you find challenging?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What specific aspects of the document management process do you find effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What improvements would you suggest for the document management process?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "5",
        name: "Patient Registration",
        tags: ["Access"],
        shortDescription:
          "Patient registrations allow patients to access healthcare services and maintain continuity of their medical records.",
        aiDescription:
          "The patient registration process at an NHS GP practice involves patients contacting the practice to provide basic information, completing the GMS1 and practice-specific forms, and verifying their identity with documents. Receptionists enter this information into the electronic health record (EHR) system. Patients are then assigned to a GP and informed about appointment booking procedures. The practice requests and integrates previous medical records using GP2GP and may schedule an initial health check to establish a health baseline.",
        icon: PencilSquareIcon,
        iconSolid: PencilSquareIconSolid,
        questions: [
          {
            id: "1",
            question:
              "The current patient registration process is easy to follow",
            shortQuestion: "Ease of Process",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The patient registration process is efficient and saves time",
            shortQuestion: "Efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "The current process reliably prevents errors in patient data",
            shortQuestion: "Reliability",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "The process facilitates good communication among staff regarding patient information",
            shortQuestion: "Communication",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What specific aspects of the patient registration process do you find challenging?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What specific aspects of the patient registration process do you find effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What improvements would you suggest for the patient registration process?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "6",
        name: "Appointment Bookings",
        tags: ["Access"],
        shortDescription:
          "Appointment bookings enable patients to schedule visits with their GP or healthcare provider for consultations and treatments.",
        aiDescription:
          "The appointment booking process at an NHS GP practice involves patients booking appointments through multiple channels: in person, over the phone, or online. Reception staff or an automated system manage these bookings, ensuring availability and matching patients with the appropriate clinician based on their needs. The practice may employ a triage model where initial assessment determines the urgency and type of care required before scheduling. The process aims to minimize errors and optimize time management for both patients and staff. ",
        icon: ClockIcon,
        iconSolid: ClockIconSolid,
        questions: [
          {
            id: "1",
            question: "The appointment scheduling process is easy to follow",
            shortQuestion: "Ease of Process",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The appointment management process is efficient and saves time",
            shortQuestion: "Efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question: "The current process reliably prevents booking errors",
            shortQuestion: "Reliability",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "The appointment system facilitates good communication among staff",
            shortQuestion: "Communication",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "The appointment system accommodates different booking methods (in-person, phone, online)",
            shortQuestion: "Flexibility",
            type: "rating",
            required: true,
          },
          {
            id: "6",
            question:
              "The triage-first model (if applicable) is effective in managing appointments and patient flow",
            shortQuestion: "Triage Model",
            type: "rating",
            required: true,
          },
          {
            id: "7",
            question:
              "What specific aspects of the appointment scheduling process do you find challenging?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "8",
            question:
              "What specific aspects of the appointment scheduling process do you find effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "9",
            question:
              "What improvements would you suggest for the appointment scheduling process?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "7",
        name: "Clinical Consultations",
        tags: ["Quality"],
        shortDescription:
          "Clinical consultations provide patients with personalised medical advice, diagnosis, and treatment from their healthcare provider.",
        aiDescription:
          "The clinical consultation process at an NHS GP practice involves a patient meeting with a clinician for assessment and treatment. The process begins with the patient being called in for their appointment, followed by a comprehensive consultation that includes history taking, physical examination, and necessary investigations. The clinician documents findings and diagnoses, discusses treatment options, and creates a management plan. Follow-up appointments and referrals to specialists may be arranged as needed. The goal is to ensure accurate diagnosis, effective treatment, and thorough documentation.",
        icon: HeartIcon,
        iconSolid: HeartIconSolid,
        questions: [
          {
            id: "1",
            question: "I have sufficient time for each patient consultation",
            shortQuestion: "Time management",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The process of documenting patient consultations is straightforward and efficient",
            shortQuestion: "Documentation",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "The process for scheduling and managing follow-ups is clear and efficient",
            shortQuestion: "Follow-ups",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "Finding the right documents for referrals is easy and time-efficient",
            shortQuestion: "Referral documentation",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question: "The referral process is straightforward and seamless",
            shortQuestion: "Referral efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "6",
            question:
              "What challenges do you face during patient consultations?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What improvements would you suggest to our process around patient consultations?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "8",
        name: "Referrals",
        tags: ["Quality"],
        shortDescription:
          "Referrals connect patients to specialised care or services beyond their GP for further diagnosis, treatment, or support.",
        aiDescription:
          "The referral process at an NHS GP practice involves the clinician assessing the patient's condition and determining the need for specialist consultation. Referrals can be categorized as acute, routine, or urgent based on the severity and urgency of the patient's condition. The clinician completes a referral form, including patient details, medical history, and reason for referral. This information is then sent electronically or via paper to the appropriate specialist or department. The practice ensures that referrals are tracked and follow-up appointments are scheduled as needed to ensure continuity of care.",
        icon: ArrowUturnRightIcon,
        iconSolid: ArrowUturnRightIconSolid,
        questions: [
          {
            id: "1",
            question:
              "The referrals process is straightforward and easy to navigate",
            shortQuestion: "Ease of process",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The system efficiently manages referrals and coordination with specialists",
            shortQuestion: "Efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "The process facilitates effective communication between primary and secondary care",
            shortQuestion: "Communication",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "The process ensures timely follow-up and communication with patients post-referral",
            shortQuestion: "Follow-up",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What specific aspects of the referrals process do you find challenging?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What specific aspects of the referrals process do you find effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What improvements would you suggest for the referrals and specialist coordination process?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "9",
        name: "Clinical Audit",
        tags: ["Quality"],
        shortDescription:
          "Clinical audits assess and improve the quality of patient care by reviewing and evaluating clinical practices against established standards.",
        aiDescription:
          "The clinical audit and quality improvement process at an NHS GP practice involves systematically reviewing and enhancing clinical care. This process starts with selecting an audit topic based on identified clinical priorities or areas of concern. Data is collected from patient records to measure current practice against established standards. The findings are analyzed to identify gaps and areas for improvement. An action plan is developed and implemented to address these gaps, followed by re-auditing to ensure improvements are sustained. Continuous quality improvement activities are integrated to enhance patient outcomes and practice efficiency.",
        icon: ClipboardDocumentListIcon,
        iconSolid: ClipboardDocumentListIconSolid,
        questions: [
          {
            id: "1",
            question:
              "The clinical audit process effectively identifies areas where we need to improve compliance",
            shortQuestion: "Effectiveness",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "I am aware of the findings from our recent clinical audits",
            shortQuestion: "Findings Shared",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "The clinical audit process helps us maintain high standards of care",
            shortQuestion: "Raises standards",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "I have received adequate training on how to conduct or participate in clinical audits",
            shortQuestion: "Training",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What specific challenges do you face with the current clinical audit processes?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What aspects of our clinical audit process do you find most effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What suggestions do you have to improve our clinical audit processes?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "10",
        name: "Workforce Development",
        tags: ["Workforce"],
        shortDescription:
          "Workforce Development focuses on training and supporting healthcare staff to enhance skills, knowledge, and performance for improved patient care.",
        aiDescription:
          "The workforce development process at an NHS GP practice focuses on enhancing the skills and competencies of all staff members. This involves identifying training needs through performance reviews and feedback, providing access to relevant training programs, and facilitating continuous professional development (CPD). The process includes regular appraisals, mentorship opportunities, and support for further education. Additionally, the practice fosters a culture of learning and development by encouraging staff to engage in ongoing education and skills enhancement to improve patient care and operational efficiency.",
        icon: UserPlusIcon,
        iconSolid: UserPlusIconSolid,
        questions: [
          {
            id: "1",
            question:
              "Training and development opportunities are easily accessible",
            shortQuestion: "Ease of Access",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question: "The training provided is relevant to my role",
            shortQuestion: "Relevance",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question: "The training helps improve my skills and performance",
            shortQuestion: "Effectiveness",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question: "I feel supported in my professional development",
            shortQuestion: "Support",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What challenges do you experience in your training and development?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What improvements would you suggest for workforce development and training?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "11",
        name: "Workforce Wellbeing & Enablement",
        tags: ["CQC", "Workforce"],
        shortDescription:
          "Workforce wellbeing and enablement focus on supporting the health, satisfaction, and professional growth of healthcare staff to enhance their performance and patient care quality.",
        aiDescription:
          "Workforce wellbeing and enablement focus on supporting the health, satisfaction, and professional growth of healthcare staff to enhance their performance and patient care quality.",
        icon: UserGroupIcon,
        iconSolid: UserGroupIconSolid,
        questions: [
          {
            id: "1",
            question:
              "The practice provides the necessary resources and facilities for safe working, such as regular breaks and rest areas",
            shortQuestion: "Resources",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question: "I have regular opportunities to provide feedback, raise concerns, and suggest ways to improve the service or staff experiences",
            shortQuestion: "Feedback",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "When concerns or suggestions are raised, leaders provide a timely and considered response",
            shortQuestion: "Responding to concerns",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question: "The practice fosters a culture that normalises good wellbeing through inclusivity, active listening, and open conversations",
            shortQuestion: "Inclusivity",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question: "I feel supported when I am struggling at work",
            shortQuestion: "Supported",
            type: "rating",
            required: true,
          },
          {
            id: "6",
            question: "I feel valued by my leaders and colleagues",
            shortQuestion: "Valued",
            type: "rating",
            required: true,
          },
          {
            id: "7",
            question: "I am able to contribute to decision-making processes in the practice",
            shortQuestion: "Empowerment",
            type: "rating",
            required: true,
          }, 
          {
            id: "8",
            question:
              "What challenges do you face in accessing the resources or support you need for your wellbeing?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "9",
            question:
              "What specific aspects of the practice's support for staff wellbeing do you find most effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "10",
            question:
              "What improvements would you suggest to enhance staff wellbeing and support in our practice?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "12",
        name: "Green Primary Care",
        tags: ["Net Zero"],
        shortDescription:
          "Green Primary Care promotes environmentally sustainable practices within healthcare, aiming to reduce the carbon footprint while maintaining high-quality patient care.",
        aiDescription:
          "The green primary care initiative at an NHS GP practice aims to align with the Net Zero NHS strategy by implementing sustainable practices across all aspects of the practice. This includes promoting environmental sustainability through efficient use of resources such as energy and water, effective recycling and waste management, and reducing carbon emissions. The practice encourages staff to participate in sustainability initiatives, such as green prescribing, which includes promoting physical activity and social prescribing. Efforts are made to promote low carbon transport options for both staff and patients, like bike racks and EV charging points. Additionally, the practice undertakes quality improvement projects aimed at enhancing sustainability in healthcare delivery and actively works to reduce its carbon footprint by implementing energy-saving measures and using renewable energy sources.",
        icon: GlobeEuropeAfricaIcon,
        iconSolid: GlobeEuropeAfricaIconSolid,
        questions: [
          {
            id: "1",
            question:
              "The practice actively promotes awareness about environmental sustainability",
            shortQuestion: "Awareness",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "The practice has effective recycling and waste management practices in place",
            shortQuestion: "Recycling and Waste Management",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "The practice efficiently uses resources such as energy and water",
            shortQuestion: "Resource Efficiency",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "Staff are encouraged to participate in sustainability initiatives",
            shortQuestion: "Staff Engagement",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "The practice supports and promotes green prescribing initiatives (e.g. encouraging physical activity, social prescribing, and eco-friendly medication options)",
            shortQuestion: "Green Prescribing",
            type: "rating",
            required: true,
          },
          {
            id: "6",
            question:
              "The practice promotes low carbon transport options for staff and patients (e.g. bike racks, EV charging points, public transport incentives)",
            shortQuestion: "Travel and Transportation",
            type: "rating",
            required: true,
          },
          {
            id: "7",
            question:
              "The practice has quality improvement projects aimed at enhancing sustainability in healthcare delivery",
            shortQuestion: "Quality Improvement and Sustainability",
            type: "rating",
            required: true,
          },
          {
            id: "8",
            question:
              "The practice has taken steps to reduce its carbon footprint (e.g. energy-saving measures, renewable energy use)",
            shortQuestion: "Carbon Footprint Reduction",
            type: "rating",
            required: true,
          },
          {
            id: "9",
            question:
              "How do you think we could better promote green prescribing within our practice?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
          {
            id: "10",
            question:
              "What additional sustainability initiatives would you like to see implemented in our practice?",
            type: "open-ended",
            required: true,
          },
        ],
      },
      {
        id: "13",
        name: "Quality Improvement (QI)",
        tags: ["Quality"],
        shortDescription:
          "QI involves systematic efforts to enhance healthcare services, processes, and outcomes for better patient care.",
        aiDescription:
          "The clinical audit and quality improvement process at an NHS GP practice involves systematically reviewing and enhancing clinical care. This process starts with selecting an audit topic based on identified clinical priorities or areas of concern. Data is collected from patient records to measure current practice against established standards. The findings are analyzed to identify gaps and areas for improvement. An action plan is developed and implemented to address these gaps, followed by re-auditing to ensure improvements are sustained. Continuous quality improvement activities are integrated to enhance patient outcomes and practice efficiency.",
        icon: PresentationChartLineIcon,
        iconSolid: PresentationChartLineIconSolid,
        questions: [
          {
            id: "1",
            question:
              "I feel involved in the quality improvement initiatives within the practice",
            shortQuestion: "Involvement",
            type: "rating",
            required: true,
          },
          {
            id: "2",
            question:
              "There are adequate opportunities for staff to contribute to quality improvement projects",
            shortQuestion: "Opportunities",
            type: "rating",
            required: true,
          },
          {
            id: "3",
            question:
              "The quality improvement initiatives have led to noticeable improvements in patient care",
            shortQuestion: "Improved care",
            type: "rating",
            required: true,
          },
          {
            id: "4",
            question:
              "I have received adequate training on quality improvement methods and tools",
            shortQuestion: "Training",
            type: "rating",
            required: true,
          },
          {
            id: "5",
            question:
              "What challenges do you experience with our quality improvement process?",
            aiSummarySection: "challenges",
            type: "open-ended",
            required: true,
          },
          {
            id: "6",
            question:
              "What specific aspects of the quality improvement process do you find most effective?",
            aiSummarySection: "effectiveAspects",
            type: "open-ended",
            required: true,
          },
          {
            id: "7",
            question:
              "What suggestions do you have for improving our quality improvement activities?",
            aiSummarySection: "userSuggestions",
            type: "open-ended",
            required: true,
          },
        ],
      },
    ],
  },
};
export default surveyQuestions;
