import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import CopyToClipboardDark from "./CopyToClipboardDark";
import { classNames } from "../utilities/general";
import WhatsAppShareButton from "./WhatsAppShareButton";

const SurveyStatus = ({ surveyPath, closedDate, organisationName }) => {
  const dateFormatter = new Intl.DateTimeFormat("en-GB");
  const surveyClosed = closedDate < new Date().toISOString();
  const surveyUrl = `${
    window.location.host?.startsWith("localhost") ? "http" : "https"
  }://${window.location.host}${surveyPath}`;

  return (
    <div className="flex gap-5">
      <span className="relative flex h-4 w-4 mt-2">
        <span
          className={classNames(
            surveyClosed ? "bg-red-400" : "bg-indigo-400",
            "animate-ping absolute inline-flex h-full w-full rounded-full  opacity-75"
          )}
        ></span>
        <span
          className={classNames(
            surveyClosed ? "bg-red-500" : "bg-indigo-500",
            "relative inline-flex rounded-full h-4 w-4 "
          )}
        ></span>
      </span>
      <div className="w-full">
        <h3 className="font-semibold text-xl">
          Your survey {surveyClosed ? "has finished" : "is live"}
        </h3>
        {closedDate && (
          <p>
            Submissions {surveyClosed ? "closed" : "close"} on{" "}
            <span className="font-semibold">
              {dateFormatter.format(new Date(closedDate))}
            </span>
          </p>
        )}

        {!surveyClosed && (
          <>
            <p className="text-base mt-4">
              Share the link with your colleagues to start receiving responses
            </p>
            <div className="max-w-[50rem] mt-2">
              <CopyToClipboardDark label="Survey url" text={surveyUrl} />
            </div>

            {organisationName && surveyUrl && (
              <div className="mt-2">
                <WhatsAppShareButton
                  organisationName={organisationName}
                  surveyUrl={surveyUrl}
                  dark={true}
                />
              </div>
            )}

            <div className="border-l-4 border-yellow-400 bg-blue-950 p-4 mt-2">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    className="h-5 w-5 text-yellow-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-400">
                    Only share this link with people who need to fill out the
                    survey. Anyone with the link can submit a response.
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SurveyStatus;
