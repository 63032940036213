import React, { useState, useEffect } from "react";
import { fetchAuthSession } from "aws-amplify/auth";
import { get } from "aws-amplify/api";
import { useNavigate, useParams } from "react-router-dom";
import { SparklesIcon } from "@heroicons/react/20/solid";

import LoadBox from "../../LoadBox";
import useStackList from "../../../hooks/useStackList";
import useProducts from "../../../hooks/useProducts";
import ResultsHeatmap from "./ResultsHeatmap";
import surveyQuestions from "../../data/surveyQuestions";
import ProductComments from "./ProductComments";
import ResultsOverview from "./ResultsOverview";
import ResultsHeatmapKey from "../../ResultsHeatmapKey";
import Dialog from "../../Dialog";
import RemoveSurveyDialog from "../../RemoveSurveyDialog";
import HeatmapSummary from "../../HeatmapSummary";
import HeaderSection from "./HeaderSection";
import SurveyAnswerRating from "../../SurveyAnswerRating";
import SurveyAnswerComment from "../../SurveyAnswerComment";
import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import ProductSummary from "./ProductSummary";

const ProductSurveyDetailsPage = ({ cognitoUser, cognitoUserAttributes }) => {
  const [loadingSurvey, setLoadingSurveys] = useState();
  const [deleteSurveyDialogOpen, setDeleteSurveyDialogOpen] = useState(false);
  const [survey, setSurvey] = useState([]);
  const { surveyUniqueCode } = useParams();
  const { loadingStackList, stackList } = useStackList(cognitoUser);
  const { loadingProducts, products } = useProducts();
  const [selectedProduct, setSelectedProduct] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const getSurvey = async () => {
      setLoadingSurveys(true);

      const accessToken = (
        await fetchAuthSession()
      ).tokens?.accessToken?.toString();

      const restOperation = get({
        apiName: "bordercrossRest",
        path: `/surveys/productSurvey/${surveyUniqueCode}`,
        options: {
          headers: {
            "jwt-token": "Basic " + accessToken,
          },
        },
      });
      const response = await restOperation.response;
      const survey = await response.body.json();
      setSurvey(survey);
      setLoadingSurveys(false);
    };

    getSurvey();
  }, []);

  // Select a product from survey to default as selected
  useEffect(() => {
    if (!selectedProduct && products && survey?.productIds?.length) {
      const product = products.find(
        (product) => product.id === survey.productIds[0]
      );
      setSelectedProduct(product);
    }
  }, [survey, products]);

  if (loadingSurvey || loadingStackList || loadingProducts) return <LoadBox />;

  const surveyStacks = stackList.filter((stack) =>
    survey.stackIds.includes(stack.id)
  );

  const isSiteAdmin = cognitoUserAttributes?.isSiteAdmin;

  const productHeatmapSummaries = survey.heatmapSummaries.find(
    (heatmapSummary) => heatmapSummary.productId === selectedProduct.id
  );
  let parsedHeatmapSummaries;
  try {
    parsedHeatmapSummaries = JSON.parse(productHeatmapSummaries?.summaryText);
  } catch (e) {}

  const surveyCompletions = survey?.byProduct?.find(
    (product) => product.productId === selectedProduct.id
  )?.completions;

  const productQuestions = surveyQuestions.productSurvey.productQuestions;

  return (
    <div className="mx-auto max-w-8xl mb-10 bg-white">
      <HeaderSection
        product={selectedProduct}
        surveyStacks={surveyStacks}
        survey={survey}
        setDeleteSurveyDialogOpen={setDeleteSurveyDialogOpen}
      />

      <div className="px-4 py-5 sm:py-12 sm:px-12">
        {surveyCompletions?.length > 0 ? (
          <>
            {parsedHeatmapSummaries && (
              <div className="mb-14">
                <div className="flex gap-2 items-center">
                  <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                    Summary
                  </h2>
                  <div className="inline-flex items-center gap-1 text-yellow-500 text-xs">
                    <SparklesIcon className="h-4 w-4" />
                    AI Powered
                  </div>
                </div>

                <div className="bg-slate-50 rounded-lg mt-6 p-4 md:p-8">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                    {parsedHeatmapSummaries && (
                      <div className="mt-2">
                        <ProductSummary
                          survey={survey}
                          product={selectedProduct}
                          isSiteAdmin={isSiteAdmin}
                        />
                      </div>
                    )}

                    <div>
                      <ResultsHeatmapKey />
                      <div className="mt-2">
                        <ResultsHeatmap
                          selectedProduct={selectedProduct}
                          survey={survey}
                          surveyQuestions={surveyQuestions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mb-14">
              <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                Results
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                {productQuestions
                  ?.filter((question) => question.type === "rating")
                  .map((question) => {
                    const answers = [];
                    surveyCompletions?.forEach((completion) =>
                      completion.answers?.map((answer) => {
                        if (answer.questionId === question.id)
                          answers.push(answer);
                      })
                    );
                    const ratings = answers.map((answer) => answer.rating);

                    return (
                      <SurveyAnswerRating
                        question={question.question.replace(
                          "<<softwareName>>",
                          selectedProduct.name
                        )}
                        ratings={ratings}
                      />
                    );
                  })}
              </div>
            </div>

            <div className="mb-14">
              <h2 className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
                Comments
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-6">
                {productQuestions
                  ?.filter((question) =>
                    surveyCompletions.find((completion) =>
                      completion.answers.find(
                        (answer) =>
                          answer.comment && question.id === answer.questionId
                      )
                    )
                  )
                  .map((question) => {
                    const answers = [];
                    surveyCompletions.forEach((completion) =>
                      completion.answers?.map((answer) => {
                        if (answer.questionId === question.id)
                          answers.push({
                            ...answer,
                            role: completion.role,
                          });
                      })
                    );
                    const comments = answers
                      .filter((answer) => answer.comment)
                      .map((answer) => {
                        return {
                          comment: answer.comment,
                          sentiment: answer.commentSentiment,
                          role: answer.role,
                        };
                      });

                    return (
                      <SurveyAnswerComment
                        question={question.question.replace(
                          "<<softwareName>>",
                          selectedProduct.name
                        )}
                        comments={comments}
                      />
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <ChatBubbleLeftEllipsisIcon className="max-w-sm max-h-sm text-slate-200" />
            <h2 className="text-2xl text-slate-400">No responses received</h2>
          </div>
        )}

        <Dialog
          open={deleteSurveyDialogOpen}
          setOpen={setDeleteSurveyDialogOpen}
        >
          <RemoveSurveyDialog
            surveyId={survey.id}
            setOpen={setDeleteSurveyDialogOpen}
            surveyEndpointPath="productSurvey"
          />
        </Dialog>
      </div>
    </div>
  );
};

export default ProductSurveyDetailsPage;
