import React, { useEffect, useState } from "react";
import { generateClient, get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { Link, useNavigate } from "react-router-dom";

import {
  listSoftwareProducts,
  listSoftwareStacks,
  listPCNStacksForSummaryPage,
} from "../../../graphql/queries-static";
import SelectOrgArea from "./SelectOrgArea";
import MostUsedProductsChart from "./MostUsedProductsChart";
import ProductImage from "../../ProductImage";
import Modal from "../../Modal";
import ManageGroupUsersModal from "../../ManageGroupUsersModal";
import ManageGroupProductsModal from "../../ManageGroupProductsModal";
import MenuOptions from "./MenuOptions";
import { titleCaseNHS } from "../../../utilities/ods";
import LoadBox from "../../LoadBox";
import PcnSummary from "./PcnSummary";

const client = generateClient();

const OrgSummaryPage = ({ cognitoUser, cognitoUserAttributes, user }) => {
  const [stackList, setStackList] = useState();
  const [loadingStackList, setLoadingStackList] = useState();
  const [stats, setStats] = useState();
  const [loadingStats, setLoadingStats] = useState(true);
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState();
  const [areaStructure, setAreaStructure] = useState();
  const [pcnStacks, setPcnStacks] = useState();
  const [pcnStacksLoading, setPcnStacksLoading] = useState();
  const [manageUsersModalOpen, setManageUsersModalOpen] = useState(false);
  const [groupAddProductsModalOpen, setGroupAddProductsModalOpen] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getStackList = async () => {
      setLoadingStackList(true);
      const softwareStacks = await client.graphql({
        query: listSoftwareStacks,
        variables: {
          limit: 1000,
          filter: {
            or: [
              { owner: { eq: cognitoUser.userId } },
              { administrator: { contains: cognitoUser.userId } },
              { readAccess: { contains: cognitoUser.userId } },
            ],
          },
        },
        authMode: "userPool",
      });
      const stackList = softwareStacks?.data?.listSoftwareStacks?.items;
      setStackList(stackList);

      let areaStructure = createAreaStructure(stackList);
      areaStructure = sortAreaStructure(areaStructure);
      setAreaStructure(areaStructure);

      if (areaStructure.length === 0) navigate("/");

      if (!selectedOrg) setSelectedOrg(areaStructure[0]?.pcns[0]);

      setLoadingStackList(false);
    };

    getStackList();
  }, []);

  // useEffect(() => {
  //   const fetchStats = async () => {
  //     setLoadingStats(true);

  //     const accessToken = (
  //       await fetchAuthSession()
  //     ).tokens?.accessToken?.toString();

  //     const restOperation = get({
  //       apiName: "bordercrossRest",
  //       path: `/stats/orgStats/${selectedOrg.id}`,
  //       options: {
  //         headers: {
  //           "jwt-token": "Basic " + accessToken,
  //         },
  //       },
  //     });
  //     const response = await restOperation.response;
  //     const stats = await response.body.json();

  //     setStats(stats);
  //     setLoadingStats(false);
  //   };

  //   if (selectedOrg) fetchStats();
  // }, [selectedOrg]);

  const getPCNStackList = async (pcnId) => {
    const softwareStacks = await client.graphql({
      query: listPCNStacksForSummaryPage,
      variables: {
        limit: 1000,
        filter: {
          and: [
            { pcnId: { eq: pcnId } },
            {
              or: [
                { owner: { eq: cognitoUser.userId } },
                { administrator: { contains: cognitoUser.userId } },
                { readAccess: { contains: cognitoUser.userId } },
              ],
            },
          ],
        },
      },
      authMode: "userPool",
    });

    const sortedStacks = softwareStacks.data.listSoftwareStacks.items?.sort(
      (stackA, stackB) => {
        const getStackName = (stack) => {
          return stack?.organisation?.name || stack.name;
        };
        const stackAName = getStackName(stackA);
        const stackBName = getStackName(stackB);

        if (stackAName > stackBName) return 1;
        if (stackBName > stackAName) return -1;
        return 0;
      }
    );

    return sortedStacks;
  };

  useEffect(() => {
    const fetchPCN = async () => {
      setPcnStacksLoading(true);
      const stacks = await getPCNStackList(selectedOrg.id);
      setPcnStacks(stacks);

      setPcnStacksLoading(false);
    };

    if (selectedOrg) fetchPCN();
  }, [selectedOrg]);

  // useEffect(() => {
  //   const getProducts = async () => {
  //     setProductsLoading(true);
  //     const softwareProducts = await client.graphql({
  //       query: listSoftwareProducts,
  //       variables: {
  //         limit: 1000,
  //       },
  //     });

  //     const products = softwareProducts?.data?.listSoftwareProducts?.items;

  //     const sortedProducts = products?.sort((productA, productB) => {
  //       if (productA.name > productB.name) return 1;
  //       if (productB.name > productA.name) return -1;
  //       return 0;
  //     });

  //     setProducts(sortedProducts);
  //     setProductsLoading(false);
  //   };
  //   getProducts();
  // }, []);

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
      <div className="flex items-center gap-4">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Summary
        </h1>
        {cognitoUserAttributes?.isSiteAdmin && (
          <MenuOptions
            setGroupAddProductsModalOpen={setGroupAddProductsModalOpen}
            setManageUsersModalOpen={setManageUsersModalOpen}
          />
        )}
      </div>

      <Modal open={manageUsersModalOpen} setOpen={setManageUsersModalOpen}>
        <ManageGroupUsersModal
          areaStructure={areaStructure}
          user={user}
          setOpen={setManageUsersModalOpen}
        />
      </Modal>
      <Modal
        open={groupAddProductsModalOpen}
        setOpen={setGroupAddProductsModalOpen}
      >
        <ManageGroupProductsModal
          areaStructure={areaStructure}
          user={user}
          setOpen={setGroupAddProductsModalOpen}
        />
      </Modal>

      {areaStructure?.length > 0 && (
        <div className="my-6 py-4 border border-gray-300 border-r-0 border-l-0">
          <SelectOrgArea
            areaStructure={areaStructure}
            selected={selectedOrg}
            setSelected={setSelectedOrg}
          />
        </div>
      )}

      {pcnStacksLoading || !selectedOrg ? (
        <LoadBox />
      ) : (
        <>
          <div>
            <h2 className="text-2xl my-8 font-bold tracking-tight text-gray-900 sm:text-3xl">
              {titleCaseNHS(selectedOrg.name)}
            </h2>

            {pcnStacks && <PcnSummary pcnStacks={pcnStacks} />}

            {/* <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate font-medium text-gray-500">
                  Total products
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {stats.totalProducts}
                </dd>
                <p className="mt-4 text-gray-500 text-sm">Most used</p>
                <ul>
                  {stats.mostUsedProducts.slice(0, 5).map((mostUsedProduct) => {
                    const productInformation = products.find(
                      (product) => mostUsedProduct.id === product.id
                    );

                    return (
                      <li className="my-1">
                        <div className="flex items-center gap-2">
                          <ProductImage
                            softwareProduct={productInformation}
                            height="h-8"
                            width="w-8"
                          />
                          <span className="font-bold">
                            <Link
                              className="font-bold hover:underline"
                              to={`/product/${mostUsedProduct.id}`}
                            >
                              {productInformation.name}
                            </Link>{" "}
                            <span className="font-medium">
                              ({mostUsedProduct.usageCount})
                            </span>
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  Total cost
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  £{parseFloat(stats?.totalCost).toFixed(2)}
                </dd>
                <p className="mt-4 text-gray-500 text-sm">Highest spenders</p>
                <ul>
                  {stats.highestSpendersStacks
                    .slice(0, 3)
                    .map((highestSpendingStack) => {
                      const stack = stackList.find(
                        (stack) => stack.id === highestSpendingStack.id
                      );

                      return (
                        <li className="my-1">
                          <div className="flex items-center gap-2">
                            <span>
                              <Link
                                className="font-bold hover:underline"
                                to={`/dashboard?stackId=${stack.id}`}
                              >
                                {stack.name}
                              </Link>{" "}
                              <span>
                                (£
                                {parseFloat(
                                  highestSpendingStack?.totalCost
                                ).toFixed(2)}
                                )
                              </span>
                            </span>
                          </div>
                        </li>
                      );
                    })}
                </ul>
                <p className="mt-4 text-gray-500 text-sm">Lowest spenders</p>
                <ul>
                  {stats.lowestSpendingStacks
                    .slice(0, 3)
                    .map((lowestSpendingStack) => {
                      const stack = stackList.find(
                        (stack) => stack.id === lowestSpendingStack.id
                      );

                      return (
                        <li className="my-1">
                          <div className="flex items-center gap-2">
                            <span>
                              <Link
                                className="font-bold hover:underline"
                                to={`/dashboard?stackId=${stack.id}`}
                              >
                                {stack.name}
                              </Link>{" "}
                              <span>
                                ( £
                                {parseFloat(
                                  lowestSpendingStack?.totalCost
                                ).toFixed(2)}
                                )
                              </span>
                            </span>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>

              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">
                  Total patients
                </dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                  {stats.totalPatients}
                </dd>
              </div>
            </dl> */}
          </div>

          {/* <MostUsedProductsChart
            products={products}
            mostUsedProducts={stats.mostUsedProducts}
          /> */}
        </>
      )}
    </div>
  );
};

const createAreaStructure = (stackList) => {
  let areaStructure = [];
  stackList?.forEach((stack) => {
    const { icbId, icbName, pcnId, pcnName } = stack.organisation;

    // Check if an entry with the same icbId already exists
    const existingEntryIndex = areaStructure.findIndex(
      (entry) => entry.id === icbId
    );

    if (existingEntryIndex !== -1) {
      // If an entry with the same icbId exists, add pcn details to it
      const pcnAlreadyAdded = areaStructure[existingEntryIndex].pcns.find(
        (pcn) => pcn.id === pcnId
      );
      if (pcnAlreadyAdded) return;

      areaStructure[existingEntryIndex].pcns.push({
        id: pcnId,
        name: pcnName,
        organisations: getOrgsInPcn(stackList, pcnId),
      });
    } else {
      // If no entry with the same icbId exists, create a new entry
      areaStructure.push({
        id: icbId,
        name: icbName,
        type: "ICB",
        pcns: [
          {
            id: pcnId,
            name: pcnName,
            type: "PCN",
            organisations: getOrgsInPcn(stackList, pcnId),
          },
        ],
      });
    }
  });

  return areaStructure;
};

const getOrgsInPcn = (stackList, pcnId) => {
  return stackList
    .filter((stack) => stack.organisation.pcnId === pcnId)
    .map((stack) => {
      return {
        id: stack.organisation.id,
        stackId: stack.id,
        name: stack.name,
      };
    });
};

const sortAreaStructure = (areaStructure) => {
  let sortedAreaStructure = areaStructure;

  sortedAreaStructure.sort((orgA, orgB) => {
    if (orgA.name > orgB.name) return 1;
    if (orgB.name > orgA.name) return -1;
    return 0;
  });

  areaStructure.forEach((icb) => {
    icb = {
      ...icb,
      pcns: icb.pcns.sort((orgA, orgB) => {
        if (orgA.name > orgB.name) return 1;
        if (orgB.name > orgA.name) return -1;
        return 0;
      }),
    };
  });

  return sortedAreaStructure;
};

export default OrgSummaryPage;
