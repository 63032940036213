import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import Tooltip from "rc-tooltip";
import VisibilitySensor from "react-visibility-sensor";
import "react-circular-progressbar/dist/styles.css";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

import PopulationPyramid from "../PopulationPyramid";
import Tooltip from "../Tooltip";
import DeprivationScore from "./DeprivationScore";

const Summary = ({ pheDetails }) => {
  const maleLifeExpectancy = pheDetails?.populationSummary?.AdHocValues?.LifeExpectancyMale?.ValF
  const femaleLifeExpectancy = pheDetails?.populationSummary?.AdHocValues?.LifeExpectancyFemale?.ValF

  return (
    <dl className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      <div className="border-t border-gray-100 sm:px-4 py-4 sm:col-span-1 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          <div className="flex gap-1 items-center">
            Registered Patients
            <Tooltip
              overlay={
                <div className="text-base">
                  <div>
                    Total number of patients registered with the practice.
                  </div>
                  <div className="text-gray-500 mt-2">
                    Source: Quality and Outcomes Framework (QOF), NHS England
                  </div>
                </div>
              }
            >
              <InformationCircleIcon className="h-5 w-5 text-gray-500" />
            </Tooltip>
          </div>
        </dt>
        <dd className="mt-1 text-3xl leading-6 text-gray-700 sm:mt-2">
          {pheDetails?.population?.ListSize?.Val?.toLocaleString("en-GB")}
        </dd>
      </div>
      <div className="border-t border-gray-100 sm:px-4 py-4 sm:col-span-1 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          <div className="flex gap-1 items-center">
            Life Expectancy
            <Tooltip
              overlay={
                <div className="text-base">
                  <div>
                    Life expectancy for MSOAs where the practice population
                    lives (LSOA to GP mapping) has been proportionally applied
                    to build a population weighted average life expectancy for
                    the practice.
                  </div>
                  <div className="text-gray-500 mt-2">
                    Source: Office for Health Improvement and Disparities
                    (OHID), based on Office of National Statistics (ONS) data
                  </div>
                </div>
              }
            >
              <InformationCircleIcon className="h-5 w-5 text-gray-500" />
            </Tooltip>
          </div>
        </dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
          Male:{" "}
          {maleLifeExpectancy ? <>{maleLifeExpectancy} years</> : "Unknown"}

          <br /> Female:{" "}
          {femaleLifeExpectancy ? <>{femaleLifeExpectancy} years</> : "Unknown"}
        </dd>
      </div>
      <div className="border-t border-gray-100 py-4 sm:col-span-1 row-span-3">
        {pheDetails?.population && (
          <PopulationPyramid populationDetails={pheDetails.population} />
        )}
      </div>
      <div className="border-t border-gray-100 sm:px-4 py-4 sm:col-span-1 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          <div className="flex gap-1 items-center">
            Workforce planning
            <Tooltip
              overlay={
                <span className="text-base max-w-sm">
                  Based on the widely accepted formula of 72 appointments per
                  1000 patients (NHS England via McKinsey){" "}
                </span>
              }
            >
              <InformationCircleIcon className="h-5 w-5 text-gray-500" />
            </Tooltip>
          </div>

          <div className="text-xs text-gray-600">
            Minimum appointments required per week
          </div>
        </dt>
        <dd className="mt-1 text-xl leading-6 text-gray-700 sm:mt-2">
          {Math.round(pheDetails?.population?.ListSize?.Val / 1000) * 72}{" "}
          appointments
        </dd>
      </div>
      <div className="border-t border-gray-100 sm:px-4 py-4 sm:col-span-1 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          <div className="flex gap-1 items-center">
            Deprivation Score
            <Tooltip
              overlay={
                <div className="text-base">
                  <div>
                    The English Indices of Deprivation 2019 use 39 separate
                    indicators, organised across seven distinct domains of
                    deprivation which can be combined, using appropriate
                    weights, to calculate the Index of Multiple Deprivation 2019
                    (IMD 2019). This is an overall measure of multiple
                    deprivation experienced by people living in an area.
                  </div>
                  <div className="text-gray-500 mt-2">
                    Source: English indices of deprivation 2019, Ministry of
                    Housing, Communities & Local Government
                  </div>
                </div>
              }
            >
              <InformationCircleIcon className="h-5 w-5 text-gray-500" />
            </Tooltip>
          </div>
        </dt>
        <dd className="mt-1 text-xl leading-6 text-gray-700 sm:mt-2">
          <DeprivationScore pheDetails={pheDetails} />
        </dd>
      </div>
      {/* <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
      <dt className="text-sm font-medium leading-6 text-gray-900">
        Ethnicity
      </dt>
      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
        {pheDetails?.populationSummary.Ethnicity}
      </dd>
    </div> */}
      <div className="border-t border-gray-100 sm:px-4 py-4 sm:col-span-1 sm:px-0">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          % having a positive experience of their practice
        </dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
          <div className="w-16">
            <VisibilitySensor>
              {({ isVisible }) => {
                const value =
                  pheDetails?.populationSummary?.AdHocValues?.Recommend?.Val;
                const percentage = isVisible ? value : 0;
                return (
                  <CircularProgressbar
                    value={percentage}
                    text={`${value?.toFixed(2)}%`}
                    styles={buildStyles({
                      pathColor: "#3b82f6",
                      textColor: "#3b82f6",
                      pathTransitionDuration: 1,
                    })}
                  />
                );
              }}
            </VisibilitySensor>
          </div>
        </dd>
      </div>
      <div className="border-t border-gray-100 sm:px-4 py-4 sm:col-span-1">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          <div className="">
            <span>Quality and Outcomes Framework Achievement</span>
            <Tooltip
              overlay={
                <div className="text-base">
                  <div>
                    The percentage of all QOF points achieved across all domains
                    as a proportion of all achievable points.
                  </div>
                  <div className="text-gray-500 mt-2">
                    Source: Quality and Outcomes Framework (QOF), NHS Digital
                  </div>
                </div>
              }
            >
              <InformationCircleIcon className="h-5 w-5 text-gray-500 inline ml-1 mb-1" />
            </Tooltip>
          </div>
        </dt>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
          <div className="w-16">
            <VisibilitySensor>
              {({ isVisible }) => {
                const value =
                  pheDetails?.populationSummary?.AdHocValues?.Qof?.Count;
                const percentage = isVisible ? value : 0;
                return (
                  <CircularProgressbar
                    maxValue={
                      pheDetails?.populationSummary?.AdHocValues?.Qof?.Denom
                    }
                    value={percentage}
                    text={`${Math.round(
                      pheDetails?.populationSummary?.AdHocValues?.Qof?.Count
                    )}/${
                      pheDetails?.populationSummary?.AdHocValues?.Qof?.Denom
                    }`}
                    styles={buildStyles({
                      pathColor: "#3b82f6",
                      textColor: "#3b82f6",
                      pathTransitionDuration: 1,
                    })}
                    s
                  />
                );
              }}
            </VisibilitySensor>
          </div>
        </dd>
      </div>
    </dl>
  );
};

export default Summary;
