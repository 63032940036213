export const updateSoftwareStack = /* GraphQL */ `
  mutation UpdateSoftwareStack(
    $input: UpdateSoftwareStackInput!
    $condition: ModelSoftwareStackConditionInput
  ) {
    updateSoftwareStack(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createSoftwareStack = /* GraphQL */ `
  mutation CreateSoftwareStack(
    $input: CreateSoftwareStackInput!
    $condition: ModelSoftwareStackConditionInput
  ) {
    createSoftwareStack(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      id
    }
  }
`

export const createSoftwareStackSoftwareProduct = /* GraphQL */ `
  mutation CreateSoftwareStackSoftwareProduct(
    $input: CreateSoftwareStackSoftwareProductInput!
    $condition: ModelSoftwareStackSoftwareProductConditionInput
  ) {
    createSoftwareStackSoftwareProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteSoftwareStackSoftwareProduct = /* GraphQL */ `
  mutation DeleteSoftwareStackSoftwareProduct(
    $input: DeleteSoftwareStackSoftwareProductInput!
    $condition: ModelSoftwareStackSoftwareProductConditionInput
  ) {
    deleteSoftwareStackSoftwareProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createProductManagementDetails = /* GraphQL */ `
  mutation CreateProductManagementDetails(
    $input: CreateProductManagementDetailsInput!
    $condition: ModelProductManagementDetailsConditionInput
  ) {
    createProductManagementDetails(input: $input, condition: $condition) {
      id
      DPIAStatus
      DCB0160Status
      contractStartDate
      contractEndDate
      sharingAgreementStatus
      price
      pricingPlan
    }
  }
`

export const updateProductManagementDetails = /* GraphQL */ `
  mutation UpdateProductManagementDetails(
    $input: UpdateProductManagementDetailsInput!
    $condition: ModelProductManagementDetailsConditionInput
  ) {
    updateProductManagementDetails(input: $input, condition: $condition) {
      id
      DPIAStatus
      DCB0160Status
      contractStartDate
      contractEndDate
      sharingAgreementStatus
      price
      pricingPlan
    }
  }
`

export const deleteProductManagementDetails = /* GraphQL */ `
  mutation DeleteProductManagementDetails(
    $input: DeleteProductManagementDetailsInput!
    $condition: ModelProductManagementDetailsConditionInput
  ) {
    deleteProductManagementDetails(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createSoftwareProduct = /* GraphQL */ `
  mutation CreateSoftwareProduct(
    $input: CreateSoftwareProductInput!
    $condition: ModelSoftwareProductConditionInput
  ) {
    createSoftwareProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateSoftwareProduct = /* GraphQL */ `
  mutation UpdateSoftwareProduct(
    $input: UpdateSoftwareProductInput!
    $condition: ModelSoftwareProductConditionInput
  ) {
    updateSoftwareProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteSoftwareProduct = /* GraphQL */ `
  mutation DeleteSoftwareProduct(
    $input: DeleteSoftwareProductInput!
    $condition: ModelSoftwareProductConditionInput
  ) {
    deleteSoftwareProduct(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createSoftwareProductProductTag = /* GraphQL */ `
  mutation CreateSoftwareProductProductTag(
    $input: CreateSoftwareProductProductTagInput!
    $condition: ModelSoftwareProductProductTagConditionInput
  ) {
    createSoftwareProductProductTag(input: $input, condition: $condition) {
      id
    }
  }
`;

export const deleteSoftwareProductProductTag = /* GraphQL */ `
  mutation DeleteSoftwareProductProductTag(
    $input: DeleteSoftwareProductProductTagInput!
    $condition: ModelSoftwareProductProductTagConditionInput
  ) {
    deleteSoftwareProductProductTag(input: $input, condition: $condition) {
      id
    }
  }
`;

export const createOrganisationStats = /* GraphQL */ `
  mutation CreateOrganisationStats(
    $input: CreateOrganisationStatsInput!
    $condition: ModelOrganisationStatsConditionInput
  ) {
    createOrganisationStats(input: $input, condition: $condition) {
      id
      phePopulation
      phePopulationSummary
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
