const ResultsHeatmapKey = () => (
  <div className="flex md:justify-end">
  <div className="inline-flex flex-col gap-1">
    <p className="text-gray-600 text-sm mb-1">Key:</p>
    <div className="flex flex-wrap flex-row gap-5 text-sm">
      <div className="flex items-center gap-1">
        <div className="h-8 w-8 bg-red-500 text-sm flex items-center justify-center rounded">-2</div>
        <div>Unfavourable</div>
      </div>

      <div className="flex items-center gap-1">
        <div className="h-8 w-8 bg-gray-200 text-sm flex items-center justify-center rounded">0</div>
        <div>Neutral</div>
      </div>

      <div className="flex items-center gap-1">
        <div className="h-8 w-8 bg-green-500 text-sm flex items-center justify-center rounded">2</div>
        <div>Favourable</div>
      </div>
    </div>
  </div>
  </div>
);

export default ResultsHeatmapKey;
