import React from "react";
import surveyQuestions from "../../data/surveyQuestions";
import SurveysGridItem from "./SurveysGridItem";
import { ComputerDesktopIcon } from "@heroicons/react/24/solid";
import BusinessProcessSurveyPreview from "../../BusinessProcessSurveyPreview";
import ProductSurveyPreview from "../../ProductSurveyPreview";
import SurveysGridItemSkeleton from "./SurveysGridItemSkeleton";

const SurveysGrid = ({
  businessProcessSummaries,
  selectedStack,
  isReadAccess
}) => {
  const productSummary = businessProcessSummaries?.find(
    (summary) => summary.surveySummaryType === "product"
  );

  const allSurveys = [
    ...surveyQuestions.businessProcessSurvey.processes
      .filter((process) => !process.deprecated)
      .map((process) => {
        const businessProcessSummary = businessProcessSummaries?.find(
          (summary) =>
            summary.surveySummaryType === "businessProcess" &&
            summary.businessProcessId === process.id
        );

        return {
          icon: { iconSolid: process.iconSolid },
          name: process.name,
          tags: process.tags,
          description: process.shortDescription,
          newSurveyLink: `/employee-insights/business-process-survey-setup?businessProcessId=${process.id}&stackId=${selectedStack.id}`,
          viewSurveyLink: `/employee-insights/business-process-survey/list/${selectedStack.id}/${process.id}`,
          latestResultsSummary:
            businessProcessSummary?.latestSurveyAverageFavourability,
          surveyCount: businessProcessSummary?.surveyCount,
          surveyPreviewModal: (
            <BusinessProcessSurveyPreview
              process={process}
              orgName={selectedStack?.name}
            />
          ),
        };
      }),
    {
      icon: { iconSolid: ComputerDesktopIcon },
      name: "Digital Infrastructure",
      tags: surveyQuestions.productSurvey.tags,
      description:
        "Digital infrastructure refers to the foundational IT systems and technologies that support healthcare operations, enabling efficient data management, communication, and patient care.",
      newSurveyLink: `/employee-insights/product-survey-setup?stackId=${selectedStack.id}`,
      viewSurveyLink: `/employee-insights/product-survey/list/${selectedStack.id}`,
      latestResultsSummary: productSummary?.latestSurveyAverageFavourability,
      surveyCount: productSummary?.surveyCount,
      surveyPreviewModal: (
        <ProductSurveyPreview
        product={{
          name: 'Software Product Name'
        }}
          questions={surveyQuestions.productSurvey.productQuestions.map(
            (question) => {
              return {
                ...question,
                question: question.question.replace(
                  "<<softwareName>>",
                  'product name'
                ),
              };
            }
          )}
        />
      ),
    },
  ];

  const sortedSurveys = allSurveys.sort((a, b) => a.name.localeCompare(b.name));

  const surveyedSurveys = sortedSurveys.filter((survey) => survey.surveyCount);
  const unsurveyedSurveys = sortedSurveys.filter(
    (survey) => !survey.surveyCount
  );

  return (
    <div>
      <h2 className="mt-2 text-2xl font-semibold leading-8 text-gray-900">
        You've surveyed {surveyedSurveys.length} of {sortedSurveys.length} business processes so
        far
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 mt-4">
        {surveyedSurveys.map((survey) => {
          return (
            <SurveysGridItem
              selectedStack={selectedStack}
              icon={survey.icon}
              name={survey.name}
              tags={survey.tags}
              description={survey.description}
              newSurveyLink={survey.newSurveyLink}
              latestResultsSummary={survey.latestResultsSummary}
              viewSurveyLink={survey.viewSurveyLink}
              surveyPreviewModal={survey.surveyPreviewModal}
              surveyCount={survey.surveyCount}
              isReadAccess={isReadAccess}
            />
          );
        })}
        {unsurveyedSurveys.map((survey) => {
          return (
            <SurveysGridItem
              selectedStack={selectedStack}
              icon={survey.icon}
              name={survey.name}
              tags={survey.tags}
              description={survey.description}
              newSurveyLink={survey.newSurveyLink}
              latestResultsSummary={survey.latestResultsSummary}
              viewSurveyLink={survey.viewSurveyLink}
              surveyPreviewModal={survey.surveyPreviewModal}
              surveyCount={survey.surveyCount}
              isReadAccess={isReadAccess}
            />
          );
        })}
        <SurveysGridItemSkeleton />
      </div>
    </div>
  );
};

export default SurveysGrid;
