import React from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const StackTypeSelection = ({
  stackTypes,
  selectedStackType,
  setSelectedStackType,
}) => {
  return (
    <RadioGroup value={selectedStackType} onChange={setSelectedStackType}>
      {/* <RadioGroup.Label className="block text-md font-medium leading-6 text-gray-900">
        Select a stack type
      </RadioGroup.Label> */}

      <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-2 sm:gap-x-4">
        {stackTypes.map((stackType) => (
          <RadioGroup.Option
            key={stackType.id}
            value={stackType}
            className={({ active }) =>
              classNames(
                active
                  ? "border-blue-600 ring-2 ring-blue-600"
                  : "border-gray-300",
                "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
              )
            }
          >
            {({ checked, active }) => (
              <>
                <span className="flex flex-1 gap-3 items-start">
                  <div className="border border-gray-300 rounded-sm p-2">
                    {stackType.icon}
                  </div>
                  <span>
                    <span className="flex flex-col item">
                      <RadioGroup.Label
                        as="span"
                        className="block text-xl font-bold text-gray-900"
                      >
                        {stackType.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-base text-gray-500"
                      >
                        {stackType.description}
                      </RadioGroup.Description>
                    </span>
                    {/* <p className="mt-4 flex items-baseline gap-x-1">
                      <span className="text-2xl font-bold tracking-tight text-gray-900">
                      £{stackType.price}
                      </span>
                      <span className="text-sm font-semibold leading-6 text-gray-600">
                        /user
                      </span>
                    </p>
                    <div className="text-sm text-gray-500">(Billing details not required)</div> */}
                    <div className="mt-4 text-sm">
                      Features
                      <ul className="text-gray-600 mt-1">
                        {stackType.features.map((feature) => (
                          <li className="flex gap-2 mb-1 items-center">
                            <CheckIcon className="h-5 w-5 text-green-600" />
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </span>
                </span>
                <CheckCircleIcon
                  className={classNames(
                    !checked ? "invisible" : "",
                    "h-6 w-6 text-blue-600"
                  )}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    active ? "border" : "border-2",
                    checked ? "border-blue-600" : "border-transparent",
                    "pointer-events-none absolute -inset-px rounded-lg"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default StackTypeSelection;
