const SurveysGridItemSkeleton = () => {
  return (
    <div
      role="status"
      class="bg-white p-6 sm:p-8 rounded animate-pulse dark:border-gray-700"
    >
      <div className="flex justify-end gap-2">
        <div class="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-16 mb-4"></div>
      </div>
      <div className="flex items-start gap-3 min-h-[12rem]">
        <div class="h-10 w-10 bg-gray-200 rounded-full dark:bg-gray-700 mb-4 shrink-0"></div>
        <div className="w-full mt-1">
          <div class="h-8 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2"></div>
          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2"></div>
          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2"></div>
          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2"></div>

          <div class="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-28 mt-6 mb-2"></div>
        </div>
      </div>

      <div className="flex gap-2 mt-4 w-full">
        <div class="h-8 bg-gray-200 rounded-lg dark:bg-gray-700 w-full"></div>
        <div class="h-8 bg-gray-200 rounded-lg dark:bg-gray-700 w-full"></div>
      </div>
    </div>
  );
};

export default SurveysGridItemSkeleton;
