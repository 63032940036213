import React, { useEffect, useState, useCallback } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import debounce from "debounce";
import { MapPinIcon } from "@heroicons/react/24/outline";

import { titleCaseNHS } from "../utilities/ods";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OrganisationSearch = ({ register, setValue }) => {
  const [query, setQuery] = useState("");
  const [organisations, setOrganisations] = useState([]);

  const handleOrganisationSearch = async (query) => {
    let response = await fetch(
      `https://directory.spineservices.nhs.uk/STU3/Organization?name:contains=${query}&active=true&ods-org-role=76,272,318` // 76=practice,272=pcn,318=icb
    );
    response = await response.json();
    setOrganisations(response.entry);
  };
  const debouncedOrganisationsSearch = useCallback(
    debounce(handleOrganisationSearch, 600),
    []
  );

  useEffect(() => {
    const searchOrganisations = async () => {
      if (query.length < 3) return;
      await debouncedOrganisationsSearch(query.trim());
    };

    searchOrganisations();
  }, [query]);

  return (
    <Combobox
      as="div"
      onChange={(organisation) => setValue("odsId", organisation?.resource?.id)}
    >
      <Combobox.Label className="block sm:text-base font-medium leading-6 text-gray-900">
        Name *
      </Combobox.Label>
      <div className="relative mt-2">
        <Combobox.Input
          autoComplete="off"
          required
          className="w-full rounded-md border-0 bg-white py-3 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(organisation) =>
            titleCaseNHS(organisation?.resource?.name)
          }
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {organisations?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base">
            {organisations.map((organisation) => (
              <Combobox.Option
                key={`${organisation.resource.id}-org-combo-option`}
                value={organisation}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div>
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            "truncate text-base",
                            selected && "font-semibold"
                          )}
                        >
                          {titleCaseNHS(organisation.resource.name)}
                        </span>
                        <span
                          className={classNames(
                            "ml-2 truncat text-sm",
                            active ? "text-blue-200" : "text-gray-500"
                          )}
                        >
                          ({organisation.resource.id})
                        </span>
                      </div>

                      <div className="flex text-sm">
                        <MapPinIcon className="h4 w-4 mr-1" />
                        <span>
                          {titleCaseNHS(
                            organisation.resource.address.line?.[0]
                          )}
                          , {titleCaseNHS(organisation.resource.address.city)},{" "}
                          {titleCaseNHS(organisation.resource.address.country)},{" "}
                          {organisation.resource.address.postalCode}
                        </span>
                      </div>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-blue-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default OrganisationSearch;
