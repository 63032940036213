import React from 'react'
import {
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";

const MissingBannerIcon = () => (
    <div className="pointer-events-none inset-x-0 bottom-0 sm:pb-5 mt-8">
    <div className="pointer-events-auto flex items-center gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
      <QuestionMarkCircleIcon
        className="h-8 w-8 text-white"
        aria-hidden="true"
      />
      <p className="text-base leading-6 text-white">
        <div>
          <strong className="font-semibold">
            Don't see a product you're using?
          </strong>
        </div>
        <div className="text-sm">
          Lets us know and we'll get it added, email us at{" "}
          <a
            className="font-bold hover:text-gray-200 underline"
            href="mailto:contact@bordercross.health"
          >
            contact@bordercross.health
          </a>
        </div>
      </p>
    </div>
  </div>
)

export default MissingBannerIcon
