import React from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import VisibilitySensor from "react-visibility-sensor";

import { classNames } from "../../utilities/general";

const RatingArrow = ({ bg }) => {
  return (
    <VisibilitySensor>
      {({ isVisible }) => (
        <ChevronDownIcon
          className={classNames(
            "h-8 w-8 fill-white transition ease-in-out delay-150 duration-400",
            isVisible ? "scale-100 opacity-100" : "scale-50 opacity-0",
            bg
          )}
        />
      )}
    </VisibilitySensor>
  );
};

export default RatingArrow;
