import React, { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import {
  ArrowTopRightOnSquareIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navigation = ({ cognitoUser, cognitoUserAttributes, signOut }) => {
  const location = useLocation();
  const showAdminLink = cognitoUserAttributes?.groups?.includes("siteAdmin");

  const infrastructureTabWhitelistedUsers = [
    '78f2bada-8469-463b-bbb1-4603169c3342', // SL
    'acc957f1-57b2-4459-b714-a8e27958fe19', // RC
  ]
  const showInfrastructureTab = infrastructureTabWhitelistedUsers?.includes(cognitoUser?.userId) || cognitoUserAttributes?.isSiteAdmin

  const routes = [
    {
      href: "/employee-insights",
      title: (
        <div className="flex gap-2 items-center">
          Employee Insights
        </div>
      ),
    },
    ...showInfrastructureTab ? [{
      href: "/dashboard",
      title: "Infrastructure Mapping",
    }] : [],
    // {
    //   href: "/community",
    //   title: "Community",
    // },
    // {
    //   href: "/products",
    //   title: "Product Catalog",
    // },
  ];

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8">
            <div className="flex h-20 justify-between">
              <div className="flex px-2 lg:px-0">
                <div className="flex flex-shrink-0 items-center mr-6">
                  <a href="https://bordercross.health">
                    <img
                      className="h-14 w-auto"
                      alt="Logo"
                      src="/images/logo-white-cropped.png"
                    />
                  </a>
                </div>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  {routes.map((route) => {
                    const isCurrent = route.href === location.pathname;

                    return (
                      <Link
                        key={`route-${route.title}`}
                        to={route.href}
                        className={classNames(
                          isCurrent
                            ? "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 font-medium text-gray-900"
                            : "inline-flex items-center border-b-2 border-transparent px-1 pt-1 font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        )}
                      >
                        {route.title}
                      </Link>
                    );
                  })}
                </div>
              </div>
              {/* <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
      <div className="w-full max-w-lg lg:max-w-xs">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            id="search"
            name="search"
            className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:sm:leading-6"
            placeholder="Search"
            type="search"
          />
        </div>
      </div>
    </div> */}
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-4 flex-shrink-0">
                  <div>
                    <Menu.Button className="relative flex rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <div className="h-10 w-10 rounded-full bg-indigo-500 flex items-center justify-center text-white">
                        {cognitoUserAttributes?.given_name.charAt(0)}
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 max-w-xs origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="block px-4 pt-2 font-semibold text-gray-700">
                        Hello {cognitoUserAttributes?.given_name}
                      </div>
                      <div className="block text-sm px-4 pb-2 text-gray-600 truncate">
                        {cognitoUserAttributes?.email}
                      </div>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/account"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-gray-700 w-full text-left"
                            )}
                          >
                            Account
                          </Link>
                        )}
                      </Menu.Item>
                      {showAdminLink && (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/admin"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-gray-700 w-full text-left"
                              )}
                            >
                              Admin
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://billing.stripe.com/p/login/test_6oE9BOajgc17bHG9AA"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "px-4 py-2 text-gray-700 w-full text-left flex items-center flex-wrap gap-1"
                            )}
                          >
                            My Subscription
                            <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                          </a>
                        )}
                      </Menu.Item> */}
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={signOut}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-gray-700 w-full text-left"
                            )}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 pb-3 pt-2">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}

              {routes.map((route) => {
                const isCurrent = route.href === location.pathname;

                return (
                  <Disclosure.Button
                    as="a"
                    href={route.href}
                    className={classNames(
                      isCurrent
                        ? "block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
                        : "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                    )}
                  >
                    {route.title}
                  </Disclosure.Button>
                );
              })}
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full bg-indigo-500 flex items-center justify-center text-white">
                    {cognitoUserAttributes?.given_name.charAt(0)}
                  </div>
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-800">
                    {cognitoUserAttributes?.given_name}
                  </div>
                  <div className="font-medium text-gray-500 truncate">
                    {cognitoUserAttributes?.email}
                  </div>
                </div>
                {/* <button
                  type="button"
                  className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}
              </div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="/account"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Account
                </Disclosure.Button>
              </div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  onClick={signOut}
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navigation;
