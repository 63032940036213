import React from "react";
import { useForm } from "react-hook-form";

import NextButton from "./NextButton";
import Button from "../../Button";
import { XCircleIcon } from "@heroicons/react/20/solid";
import SurveyQuestion from "../../SurveyQuestion";

const BusinessProcessQuestionStep = ({
  step,
  productQuestionStepEnd,
  setStep,
  updateAnswers,
  currentBusinessProcess,
}) => {
  const form = useForm();

  const onSubmit = async (data) => {
    await updateAnswers(data);

    form.reset();
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  const errors = !!Object.entries(form?.formState?.errors)?.length;

  return (
    <>
      <div>
        <h3 className="mt-6 text-sm">This section is about: </h3>
        <div className="mt-2 flex gap-4 items-center">
          <div className="flex-shrink-0">
            <div className="flex h-20 w-20 rounded overflow-hidden bg-indigo-500">
              <div className="w-full self-center">
                <div className="flex justify-center text-gray-400">
                  <currentBusinessProcess.icon className="h-10 w-10 text-indigo-50" />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 className="font-semibold text-lg">
              {currentBusinessProcess.name}
            </h2>
            <p className="text-gray-600">{currentBusinessProcess.shortDescription}</p>
          </div>
        </div>
      </div>
      <form
        id={`business-process-${currentBusinessProcess.id}-form`}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="divide-y divide-solid border-gray-400">
          {currentBusinessProcess.questions.map(
            (question) => {
              return (
                <SurveyQuestion
                  question={question}
                  form={form}
                />
              );
            }
          )}
        </div>

        {errors && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-6 w-6 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-base font-semibold text-red-700">
                  Fix the errors with your responses to continue
                </h3>
              </div>
            </div>
          </div>
        )}

        <div className="flex gap-4 items-center mt-8">
          <Button
            className="w-full max-w-xs px-3 py-3 "
            onClick={() => {
              setStep(step - 1);
              window.scrollTo(0, 0);
            }}
            color="white"
            type="button"
          >
            Back
          </Button>
          <NextButton
            text={step === productQuestionStepEnd ? "Submit" : "Next"}
            type={"submit"}
          />
        </div>
      </form>
    </>
  );
};

export default BusinessProcessQuestionStep;
