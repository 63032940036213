import React from "react";
import Lottie from "react-lottie-player";

import notFoundJson from "../../lottieFiles/NotFound.json";

const NotFound = () => {
  return (
    <div className="grid h-[80vh] place-content-center bg-white px-4">
      <div className="text-center">
        <Lottie loop animationData={notFoundJson} play style={{ maxWidth: '600px' }} />

        <h1 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl sr-only">
         404 - Page not found
        </h1>

        <p className="mt-4 text-gray-500 text-lg mb-18">
          Contact us at{" "}
          <a
            className="text-gray-600 underline hover:text-gray-900"
            href="contact@bordercross.health"
          >
            contact@bordercross.health
          </a>{" "}
          if you are experiencing problems.
        </p>
      </div>
    </div>
  );
};

export default NotFound;
