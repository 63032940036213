import React, { useState } from "react";

import ProductList from "./ProductList";
import AddProductModal from "./AddProductModal";
import EditProductModal from "./EditProductModal";
import DesktopFilters from "../ProductsPage/DesktopFilters";
import Modal from "../../Modal";
import Dialog from "../../Dialog";
import RemoveProductDialog from "./RemoveProductDialog";

const ManageProducts = ({ products, productFilters, getProducts }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [addProductModalOpen, setAddProductModalOpen] = useState(false);
  const [editProductModalOpen, setEditProductModalOpen] = useState(false);
  const [removeProductDialogOpen, setRemoveProductDialogOpen] = useState(false);
  const [productToRemove, setProductToRemove] = useState();
  const [productToEdit, setProductToEdit] = useState();
  const handleFilterChange = (e) => {
    const newSelectedFilters = [...selectedFilters];
    const changedFilter = e.target.value;
    if (selectedFilters.includes(changedFilter)) {
      newSelectedFilters.splice(newSelectedFilters.indexOf(changedFilter), 1);
    } else {
      newSelectedFilters.push(changedFilter);
    }
    setSelectedFilters(newSelectedFilters);
  };

  const handleEditProduct = (product) => {
    setProductToEdit(product);
    setEditProductModalOpen(true);
  };

  const handleDeleteProduct = (product) => {
    setProductToRemove(product);
    setRemoveProductDialogOpen(true);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  let filteredProducts = products;
  if (searchTerm) {
    filteredProducts = filteredProducts.filter((product) => {
      if (
        product?.name?.toLowerCase().includes(searchTerm?.toLowerCase()?.trim())
      )
        return true;
      return false;
    });
  }
  if (selectedFilters.length) {
    filteredProducts = filteredProducts.filter((product) => {
      return selectedFilters.every((filterItem) => {
        const matchesCategory = product?.productCategory?.id === filterItem;
        const matchesTag = product?.productTags?.items?.some(
          (tag) => filterItem === tag.productTagId
        );
        return matchesCategory || matchesTag;
      });
    });
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Products
            </h1>
            <div className="mt-6">
              <input
                name="productName"
                id="productName"
                className="block w-full rounded-md border-0 py-4 px-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:leading-6 sm:text-sm lg:text-2xl  "
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search for a product..."
              />
            </div>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={() => setAddProductModalOpen(true)}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add product
            </button>
          </div>
        </div>
        <div className="pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          <aside>
            <h2 className="sr-only">Filters</h2>
            {productFilters && (
              <DesktopFilters
                filters={productFilters}
                handleFilterChange={handleFilterChange}
                selectedFilters={selectedFilters}
              />
            )}
          </aside>
          <div className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
            {products && (
              <ProductList
                products={filteredProducts}
                handleEditProduct={handleEditProduct}
                handleDeleteProduct={handleDeleteProduct}
              />
            )}
          </div>
        </div>
      </div>
      <Modal open={addProductModalOpen} setOpen={setAddProductModalOpen}>
        <AddProductModal
          productFilters={productFilters}
          setOpen={setAddProductModalOpen}
          getProducts={getProducts}
        />
      </Modal>
      <Modal open={editProductModalOpen} setOpen={setEditProductModalOpen}>
        <EditProductModal
          product={productToEdit}
          productFilters={productFilters}
          setOpen={setEditProductModalOpen}
          getProducts={getProducts}
        />
      </Modal>
      <Dialog
        open={removeProductDialogOpen}
        setOpen={setRemoveProductDialogOpen}
      >
        <RemoveProductDialog
          getProducts={getProducts}
          product={productToRemove}
          setOpen={setRemoveProductDialogOpen}
        />
      </Dialog>
    </>
  );
};

export default ManageProducts;
