import React, { useState } from "react";

import { classNames } from "../utilities/general";

const Rating = ({ questionId, required, form, hasError }) => {
  const [hovered, setHovered] = useState();

  const currentValue = form.watch(`rate-${questionId}`);

  const ratingDescriptions = [
    "Strongly disagree",
    "Disagree",
    "Neutral",
    "Agree",
    "Strongly agree",
  ];

  let description;
  if (hovered) description = ratingDescriptions[hovered - 1];
  else if (currentValue) description = ratingDescriptions[currentValue - 1];

  return (
    <div className="inline-flex flex-col items-center">
      <div className="min-h-[25px] mb-1">
        {description && description}
        {!description && (
          <span
            className={classNames(
              hasError ? "text-red-600" : "text-gray-500",
              "text-sm"
            )}
          >
            Select a rating
          </span>
        )}
      </div>
      <div>
        <fieldset
          className={classNames(
            hasError && "ring-2 ring-red-600/20",
            "w-full rounded-2xl overflow-hidden"
          )}
        >
          <div className="w-full flex">
            <legend className="sr-only">Response:</legend>
            <RatingRadio
              currentValue={currentValue}
              value={"1"}
              hovered={hovered}
              setHovered={setHovered}
              questionId={questionId}
              form={form}
              required={required}
            />
            <RatingRadio
              currentValue={currentValue}
              value={"2"}
              hovered={hovered}
              setHovered={setHovered}
              questionId={questionId}
              form={form}
              required={required}
            />
            <RatingRadio
              currentValue={currentValue}
              value={"3"}
              hovered={hovered}
              setHovered={setHovered}
              questionId={questionId}
              form={form}
              required={required}
            />
            <RatingRadio
              currentValue={currentValue}
              value={"4"}
              hovered={hovered}
              setHovered={setHovered}
              questionId={questionId}
              form={form}
              required={required}
            />
            <RatingRadio
              currentValue={currentValue}
              value={"5"}
              hovered={hovered}
              setHovered={setHovered}
              questionId={questionId}
              form={form}
              required={required}
            />
          </div>
        </fieldset>
      </div>
    </div>
  );
};

const RatingRadio = ({
  currentValue,
  value,
  hovered,
  setHovered,
  questionId,
  form,
  required,
}) => {
  const colours = [
    "bg-indigo-300",
    "bg-indigo-500",
    "bg-indigo-600",
    "bg-indigo-700",
    "bg-indigo-800",
  ];

  let highlighted = false;

  if (hovered) {
    if (value <= hovered) highlighted = true;
  } else {
    if (value <= currentValue) highlighted = true;
  }

  return (
    <>
      <input
        type="radio"
        id={`rate-${questionId}-${value}`}
        onClick={() => {
          if (value === currentValue) form.setValue(`rate-${questionId}`, null);
        }}
        value={value}
        className="hidden"
        {...form.register(`rate-${questionId}`, { required })}
      />
      <label
        htmlFor={`rate-${questionId}-${value}`}
        className={classNames(
          value !== "1" ? "border-l-4 border-l-white" : "",

          highlighted ? colours[value - 1] : "bg-gray-100",
          "h-10 w-10 box-content, overflow-hidden cursor-pointer duration-300 transition-all"
        )}
        onMouseEnter={() => setHovered(value)}
        onMouseLeave={() => setHovered()}
      ></label>
    </>
  );
};

export default Rating;
