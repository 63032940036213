import React, { useState } from "react";
import { TreeSelect } from "primereact/treeselect";
import { useForm } from "react-hook-form";
import { post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import "primereact/resources/themes/lara-light-cyan/theme.css"; // replace with my own styling for component

import { titleCaseNHS } from "../utilities/ods";
import LoadingIcon from "./LoadingIcon";
import Button from "./Button";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { getAreaStructureNodes, getSelectedPracticeIds } from "../utilities/treeSelect";

export default function ManageGroupUsersModal({
  areaStructure,
  user,
  setOpen,
}) {
  const { register, handleSubmit } = useForm();
  const [selectedNodeKeys, setSelectedNodeKeys] = useState();
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [inviteError, setInviteError] = useState(false);

  const nodes = getAreaStructureNodes(areaStructure)

  const handleAddUser = async (data) => {
    setLoadingInvite(true);

    try {
      const accessToken = (
        await fetchAuthSession()
      ).tokens?.accessToken?.toString();

      const restOperation = post({
        apiName: "bordercrossRest",
        path: "/users/groupInviteUser",
        options: {
          headers: {
            "jwt-token": "Basic " + accessToken,
          },
          body: {
            stackIds: getSelectedPracticeIds(selectedNodeKeys, nodes),
            role: data.role,
            email: data.email,
            inviterName: `${user.givenName} ${user.familyName}`,
          },
        },
      });
      await restOperation.response;
      setInviteSent(true);
    } catch {
      setInviteError(true);
    }

    setLoadingInvite(false);
  };

  const ModalHeader = () => (
    <h2 className="text-xl sm:text-4xl font-bold">Group Invite Users</h2>
  );

  if (inviteSent) {
    return (
      <div className="max-h-[75vh] overflow-scroll">
        <ModalHeader />

        <h3 className="text-lg sm:text-2xl font-bold mb-2 mt-6">
          <div className="flex gap-2 items-center">
            <CheckCircleIcon className="h-8 w-8 text-green-600" />
            <span>Invite sent</span>
          </div>
        </h3>
        <p className="text-base mb-4">
          We've successfully emailed an invitation.
        </p>

        <div className="w-full mt-5 sm:mt-6">
          <button
            type="button"
            className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  if (inviteError) {
    return (
      <div className="max-h-[75vh] overflow-scroll">
        <ModalHeader />

        <h3 className="text-lg sm:text-2xl font-bold mb-2 mt-6">
          <div className="flex gap-2 items-center">
            <XCircleIcon className="h-8 w-8 text-red-600" />
            <span>Invite failed</span>
          </div>
        </h3>
        <p className="text-base mb-4">
          Please try again, if the issue continues please contact{" "}
          <a
            className="hover:text-gray-600 underline"
            href="mailto:contact@bordercross.health"
          >
            contact@bordercross.health
          </a>
          .
        </p>

        <div className="w-full mt-5 sm:mt-6">
          <button
            type="button"
            className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-h-[75vh] overflow-scroll">
      <ModalHeader />

      <div className="my-8">
        <h3 className="text-lg sm:text-xl font-bold mb-2">
          Add new user to multiple organisations
        </h3>
        <p className="text-base mb-1">
          Select the organisations you'd like to send an invitation to, enter
          the person's email you would like to invite and we will email them
          with an invite link.
        </p>
        <p className="text-base mb-4">
          You may need to ask them to{" "}
          <strong>check their junk/spam folder</strong> for the invite.
        </p>
        <label
          htmlFor="organisations"
          className="block text-sm md:text-base font-medium leading-6 text-gray-900 mb-1"
        >
          Organisations
        </label>
        <TreeSelect
          value={selectedNodeKeys}
          onChange={(e) => setSelectedNodeKeys(e.value)}
          options={nodes}
          metaKeySelection={false}
          className="w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6"
          selectionMode="checkbox"
          display="chip"
        ></TreeSelect>

        <label
          htmlFor="email"
          className="block text-sm md:text-base font-medium leading-6 text-gray-900 mt-4 mb-1"
        >
          Email
        </label>
        <div className="md:flex gap-2">
          <div className="grow">
            <input
              type="email"
              {...register("email")}
              name="email"
              id="email"
              className="block w-full rounded-md border-0 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-base sm:leading-6"
            />
          </div>

          <div>
            <select
              aria-label="role"
              id="role"
              name="role"
              {...register("role")}
              className="block w-full rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-base sm:leading-6"
              defaultValue="administrator"
            >
              <option value="administrator">Administrator</option>
              <option value="readAccess">Read access</option>
            </select>
          </div>
        </div>
      </div>

      <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse sm:gap-3">
        <div className="w-full">
          <Button
            className="w-full"
            color="blue"
            size="lg"
            onClick={handleSubmit((data) => handleAddUser(data))}
          >
            Invite User
            {loadingInvite && (
              <span className="ml-2">
                <LoadingIcon height="h-4" width="w-4" textColor="text-white" />
              </span>
            )}
          </Button>
        </div>
        <div className="w-full">
          <button
            type="button"
            className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
