import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchAuthSession } from "aws-amplify/auth";
import { post } from "aws-amplify/api";
import LoadBox from "../LoadBox";

const InvitedPage = () => {
  const [loading, setLoading] = useState(true);
  const [inviteResponse, setInviteResponse] = useState();
  const { inviteId } = useParams();

  useEffect(() => {
    const acceptInvite = async () => {
      const accessToken = (
        await fetchAuthSession()
      ).tokens?.accessToken?.toString();

      try {
        const restOperation = post({
          apiName: "bordercrossRest",
          path: "/users/acceptUserInvite",
          options: {
            headers: {
              "jwt-token": "Basic " + accessToken,
            },
            body: {
              inviteId,
            },
          },
        })
        const response = await restOperation.response;
        setInviteResponse({
          statusCode: response.statusCode,
        });
      } catch (error) {
        setInviteResponse({
            error: error.message
          });
      }

      setLoading(false);
    };

    acceptInvite();
  }, []);

  if (loading) return <LoadBox />;

  let title, body;
  if (
    inviteResponse?.statusCode === 200
  ) {
    title = "Invite accepted";
    body = (
      <>
        Go to the dashboard to see your new organisation(s)
      </>
    );
  } else if (inviteResponse?.error === "Already accepted") {
    title = "Invite already accepted";
    body = "You have already accepted the invite, go to the dashboard to see the organisation.";
  } else if (inviteResponse?.error === "Expired") {
    title = "Invite expired";
    body = "You will need to be invited again to join this organisation.";
  } else {
    title = "Invite failed";
    body = (
      <>
        Something went wrong. If this problem persists, let us know at{" "}
        <a
          href="mailto:contact@bordercross.health"
          className="hover:text-gray-600 underline"
        >
          contact@bordercross.health
        </a>
        .
      </>
    );
  }

  return (
    <div className="mx-auto max-w-2xl text-center px-6 py-8 sm:p-24 mt-16 lg:mt-32 rounded-lg bg-white">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        {title}
      </h2>
      <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
        {body}
      </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
        <a
          href="/"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Continue
        </a>
        {/* <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
          Learn more <span aria-hidden="true">→</span>
        </a> */}
      </div>
    </div>
  );
};

export default InvitedPage;
