import React from 'react'
import { classNames, percentage } from "../utilities/general";

const FavourabilityChart = ({ favourable, neutral, unfavourable, total }) => {
    const isVisible = true;
  
    const favourablePercentage = percentage(favourable, total) + "%";
    const neutralPercentage = percentage(neutral, total) + "%";
    const unfavourablePercentage = percentage(unfavourable, total) + "%";
    const favourableAndNeutralPercentage =
      percentage(favourable + neutral, total) + "%";
  
    return (
      <>
        <div className="flex gap-4 justify-between items-center">
          <div className="flex rounded overflow-hidden h-8 w-full relative text-white font-bold text-center text-sm">
            <div
              className={classNames(
                isVisible && "animate-progress",
                "absolute bg-slate-200 h-8"
              )}
              style={{ animationDelay: `${100}ms` }}
            ></div>
            <div className="absolute" style={{ width: favourablePercentage }}>
              <div
                className={classNames(
                  "bg-green-500",
                  isVisible && "animate-progress",
                  "h-8 pt-1.5"
                )}
                style={{ animationDelay: `${500}ms` }}
              >
                <span
                  className="animate-fade opacity-0"
                  style={{ animationDelay: "1250ms" }}
                >
                  {Number(percentage(favourable, total).toFixed(1)) + "%"}
                </span>
              </div>
            </div>
            <div
              className="absolute"
              style={{ width: neutralPercentage, left: favourablePercentage }}
            >
              <div
                className={classNames(
                  "bg-yellow-400",
                  isVisible && "animate-progress",
                  "h-8 pt-1.5"
                )}
                style={{ animationDelay: `${1250}ms` }}
              >
                <span
                  className="animate-fade opacity-0"
                  style={{ animationDelay: "2000ms" }}
                >
                  {Number(percentage(neutral, total).toFixed(1)) + "%"}
                </span>
              </div>
            </div>
            <div
              className="absolute"
              style={{
                width: unfavourablePercentage,
                left: favourableAndNeutralPercentage,
              }}
            >
              <div
                className={classNames(
                  "bg-red-500",
                  isVisible && "animate-progress",
                  "h-8 pt-1.5"
                )}
                style={{ animationDelay: `${2000}ms` }}
              >
                <span
                  className="animate-fade opacity-0"
                  style={{ animationDelay: "2750ms" }}
                >
                  {Number(percentage(unfavourable, total).toFixed(1)) + "%"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  export default FavourabilityChart