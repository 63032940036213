import React from "react";
import { classNames } from "../utilities/general";

const OrganisationSelector = ({
  defaultValue,
  cognitoUser,
  onChange,
  stackList,
  hideLabel,
  disableReadStacks = false
}) => {
  return (
    <div>
      <label
        htmlFor="tabs"
        className={classNames(hideLabel && "sr-only", "font-bold")}
      >
        Select an organisation
      </label>
      <select
        id="tabs"
        name="tabs"
        className="block mt-2 w-full h-12 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.target.value)}
      >
        {stackList?.map((stack) => {
          const stackName = stack?.organisation?.name || stack.name;
          let userTypeLabel;

          const isAdministrator = stack?.administrator?.includes(
            cognitoUser.userId
          );
          const isReadAccess = stack?.readAccess?.includes(cognitoUser.userId);
          if (isAdministrator) userTypeLabel = "Administrator";
          if (isReadAccess) userTypeLabel = "Read access";
          return (
            <option disabled={disableReadStacks && !!isReadAccess} key={stack.id} value={stack.id}>
              {stackName}{" "}
              {userTypeLabel && (
                <span className="text-sm">({userTypeLabel})</span>
              )}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default OrganisationSelector;
