import React, { useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import TableToExcel from "@linways/table-to-excel";

import ProductPanel from "./ProductPanel";
import MyDashboardProductTiles from "../../MyDashboardProductTiles";
import Button from "../../Button";
import { classNames } from "../../../utilities/general";
import YourStackOptions from "./YourStackOptions";
import Table from "./Table";

const YourStack = ({
  cognitoUser,
  cognitoUserAttributes,
  currentProduct,
  pheDetails,
  loadingPheData,
  productFilters,
  selectedProductManagementDetail,
  setAddProductModalOpen,
  setCurrentProduct,
  setEditProductModalOpen,
  setManageUsersModalOpen,
  setPreselectedAddProductFilter,
  setRemoveProductDialogOpen,
  setRemoveStackDialogOpen,
  stack,
}) => {
  const [viewMode, setViewMode] = useState("tile");
  const [perPatientPricing, setPerPatientPricing] = useState(false);
  const [downloadingXLS, setDownloadingXLS] = useState(false);

  const isReadAccess = stack?.readAccess?.includes(cognitoUser.userId);

  useEffect(() => {
    if (downloadingXLS) {
      if (viewMode !== "table") {
        return setViewMode("table");
      } 

      TableToExcel.convert(document.getElementById("table"));
      setDownloadingXLS(false);
    }
  }, [downloadingXLS]);

  useEffect(() => {
    if (downloadingXLS && viewMode === 'table') {
        TableToExcel.convert(document.getElementById("table"))
        setViewMode("tile")
        setDownloadingXLS(false);
    }
  }, [viewMode])

  return (
    <>
      <div className="flex flex-col gap-2 md:flex-row justify-between md:items-end">
        <h2 className="text-3xl font-bold mt-10">Your Products</h2>
      </div>

      <div className="mt-8 flex flex-col sm:flex-row gap-4 justify-between">
        <div>
          <div className="flex flex-wrap gap-4">
            {!isReadAccess && cognitoUserAttributes?.isSiteAdmin (
              <Button
                className="lg:w-72"
                color="blue"
                onClick={() => setAddProductModalOpen(true)}
              >
                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                Add product
              </Button>
            )}
            {!isReadAccess && (
              <YourStackOptions
                setManageUsersModalOpen={setManageUsersModalOpen}
                setRemoveStackDialogOpen={setRemoveStackDialogOpen}
                setDownloadingXLS={setDownloadingXLS}
              />
            )}
          </div>
        </div>
        <div>
          <div className="flex items-center gap-2">
            <label
              htmlFor="location"
              className="block font-medium text-gray-900"
            >
              View mode
            </label>
            <select
              id="location"
              name="location"
              className="block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:leading-6"
              defaultValue="tile"
              onChange={(e) => setViewMode(e.target.value)}
            >
              <option value="tile">Tile view</option>
              <option value="table">Table view</option>
            </select>
          </div>
        </div>
      </div>

      <div className="my-4">
        {viewMode === "tile" && (
          <div className="grid grid-cols-3 gap-2">
            <div
              className={classNames(
                currentProduct ? "col-span-2" : "col-span-3"
              )}
            >
              <MyDashboardProductTiles
                isReadAccess={isReadAccess}
                stack={stack}
                currentProduct={currentProduct}
                setCurrentProduct={setCurrentProduct}
                productCategories={productFilters?.[0]?.options}
                setAddProductModalOpen={setAddProductModalOpen}
                setPreselectedAddProductFilter={setPreselectedAddProductFilter}
              />
            </div>
            <ProductPanel
              isReadAccess={isReadAccess}
              currentProduct={currentProduct}
              setCurrentProduct={setCurrentProduct}
              productManagementDetail={selectedProductManagementDetail}
              setEditProductModalOpen={setEditProductModalOpen}
              setRemoveProductDialogOpen={setRemoveProductDialogOpen}
            />
          </div>
        )}
        {viewMode === "table" && (
          <>
            <Table
              downloadingXLS={downloadingXLS}
              loadingPheData={loadingPheData}
              perPatientPricing={perPatientPricing}
              pheDetails={pheDetails}
              productManagementDetails={selectedProductManagementDetail}
              setPerPatientPricing={setPerPatientPricing}
              stack={stack}
            />
          </>
        )}
      </div>
    </>
  );
};

export default YourStack;
