import React from "react";
import { BuildingOfficeIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom"

import { titleCaseNHS, getAddress } from "../utilities/ods";

const OrganisationBox = ({ organisation }) => {
  // const sectorMappings = {
  //   HEALTHCARE: "Healthcare",
  //   TECHNOLOGY: "Technology",
  //   EDUCATION: "Education",
  //   OTHER: "Other",
  // };

  // Will need to remove duplicate products from this array at some point
  // (products that appear in multiple stacks)
  const organisationProductsPreview = [];
  const totalProductsInPreview = 6;
  organisation?.SoftwareStacks?.items?.forEach((stack) => {
    stack?.SoftwareProducts?.items?.forEach((product) => {
      if (organisationProductsPreview.length < totalProductsInPreview) {
        organisationProductsPreview.push(product.softwareProduct);
      }
    });
  });

  return (
    <div className="flex flex-col items-center border border-gray-300 rounded-sm p-6 bg-white">
      <Link to={`/organisation/${organisation.id}`}>
        <div className="border border-gray-300 rounded-sm p-2">
          <BuildingOfficeIcon className="h-14 w-14 text-gray-500 stroke-1" />
        </div>
      </Link>
      <Link className="hover:underline" to={`/organisation/${organisation.id}`}>
        <h3 className="lg:text-xl font-bold mt-2">
          {titleCaseNHS(organisation.name)}
        </h3>
      </Link>
      <div className="text-gray-600 capitalize text-center">
        {titleCaseNHS(organisation.icbName)}
      </div>
      {organisation.addressLine1 && (
        <div className="mt-3 text-gray-600 capitalize">
          <div className="mt-3 text-gray-600 text-center inline-block">
            <div className="inline-block">
              <MapPinIcon className="h-4 w-4 mr-1 relative top-0.5" />
            </div>
            <div className="text-center inline -mt-1">
              {getAddress(organisation)}
            </div>
          </div>
        </div>
      )}

      {organisationProductsPreview.length > 0 && (
        <div className="bg-gray-100 p-3 mt-6 w-full rounded-sm flex-grow">
          <h3 className="text-md">Featured products</h3>
          <ul className="grid grid-cols-2 lg:grid-cols-3 gap-x-2 gap-y-6 py-4">
            {organisationProductsPreview.map((product) => (
              <li
                key={product.name}
                className="relative inline-flex flex-col items-center"
              >
                <div className="flex h-20 w-20 border border-gray-300 rounded overflow-hidden bg-white">
                  <Link
                    className="w-full self-center"
                    to={`/product/${product.id}`}
                  >
                    <img
                      className="mx-auto"
                      src={`${process.env.PUBLIC_URL}/images/product-logos/${product.imageUrl}`}
                      alt={`${product.name} Logo`}
                    />
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <Link
        to={`/organisation/${organisation.id}`}
        className="w-full mt-6 text-center rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        View stack
      </Link>
    </div>
  );
};

export default OrganisationBox;
