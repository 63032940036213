import React, { useState, useEffect } from "react";
import { CircleStackIcon } from "@heroicons/react/20/solid";

import PcnTable from "./PcnTable/PcnTable.js";

import PcnStatBoxes from "./PcnStatBoxes.js";
import Filter from "./Filter.js";
import {
  getCategoryOptions,
  getProductsInCategoryForStacks,
  getMostUsedProducts,
} from "../../../utilities/stacks.js";
import ProductsByCategoryChart from "./ProductsByCategoryChart.js";
import ProductsByCategoryTable from "./ProductsByCategoryTable.js";
import SimpleSelect from "../../SimpleSelect.js";

const PcnChartSummary = ({ pcnStacks }) => {
  const [selectedCategory, setSelectedCategory] = useState();

  const categories = [
    { id: "all", name: "All" },
    ...getCategoryOptions(pcnStacks),
  ];

  useEffect(() => {
    if (categories.length) {
      setSelectedCategory(categories[0]);
    }
  }, [pcnStacks]);

  if (!pcnStacks) return null;

  const mostUsedProducts = getMostUsedProducts(pcnStacks);
  const mostUsedProductsByCategory = mostUsedProducts.filter((product) => {
    if (selectedCategory?.id === "all") {
      return true;
    }
    return product?.categoryId === selectedCategory?.id;
  }).slice(0, 10);

  if (mostUsedProducts.length <= 1) return null

  return (
    <>
      <div className="grid gap-5 grid-cols-5 relative rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
        <div className="col-span-5 lg:col-span-3">
          <div className="sm:flex flex-wrap items-center gap-4 mb-6">
            <h3 className="text-xl font-semibold leading-7 text-gray-900">
              Most used products
            </h3>

            <div>
              <SimpleSelect
                options={categories}
                onChange={setSelectedCategory}
                selected={selectedCategory}
              />
            </div>
          </div>
          <div>
            <ProductsByCategoryChart
              mostUsedProducts={mostUsedProductsByCategory}
            />
          </div>
        </div>
        <div className="col-span-5 lg:col-span-2">
            <ProductsByCategoryTable 
               mostUsedProducts={mostUsedProductsByCategory}
            />
          </div>
      </div>
    </>
  );
};

export default PcnChartSummary;
