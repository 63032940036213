import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  UserIcon,
} from "@heroicons/react/20/solid";

import { classNames } from "../../../utilities/general";
import { TrashIcon } from "@heroicons/react/24/outline";

const MenuOptions = ({
  setDeleteSurveyDialogOpen
}) => {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex rounded overflow-hidden bg-indigo-1000 px-3.5 py-2.5 text-sm text-gray-200 shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-blue-950">
            Options
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md overflow-hidden bg-blue-950 shadow-lg ring-1 ring-gray-600 ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => setDeleteSurveyDialogOpen(true)}
                    className={classNames(
                      active && "bg-blue-900",
                      "group flex items-center px-4 py-2 w-full text-white"
                    )}
                  >
                    <TrashIcon
                      className="mr-3 h-5 w-5 text-white group-hover:text-slate-50"
                      aria-hidden="true"
                    />
                    Delete survey
                  </button>
                )}
              </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default MenuOptions;
