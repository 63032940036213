import { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { listProductFilters } from "../graphql/queries-static";

const client = generateClient();
const useProductFilters = () => {
  const [productFilters, setProductFilters] = useState([]);
  const [loadingProductFilters, setLoadingProductFilters] = useState(true);

  useEffect(() => {
    const getProductFilters = async () => {
      setLoadingProductFilters(true);
      try {
        const productFilters = await client.graphql({
          query: listProductFilters,
          authMode: 'userPool'
        });

        const sortedProductTags =
          productFilters.data.listProductTags.items.sort(
            (productTagA, productTagB) => {
              if (productTagA.name > productTagB.name) return 1;
              if (productTagB.name > productTagA.name) return -1;
              return 0;
            }
          );

        const sortedProductCategories =
          productFilters.data.listProductCategories.items.sort(
            (productCategoryA, productCategoryB) => {
              if (productCategoryA.name > productCategoryB.name) return 1;
              if (productCategoryB.name > productCategoryA.name) return -1;
              return 0;
            }
          );

        setProductFilters([
          {
            id: "category",
            name: "Category",
            options: sortedProductCategories,
          },
          {
            id: "tag",
            name: "Tags",
            options: sortedProductTags,
          },
        ]);
      } catch (error) {
        console.error("Failed to fetch software products", error);
      } finally {
        setLoadingProductFilters(false);
      }
    };

    getProductFilters();
  }, []);

  return { productFilters, loadingProductFilters };
};

export default useProductFilters;
