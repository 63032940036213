import React from "react";
import { TagIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import Button from "../../Button";
import ProductImage from "../../ProductImage";

const ProductList = ({ products, handleDeleteProduct, handleEditProduct }) => {
  return (
    <ul className="mt-4">
      {products.map((product) => {
        return (
          <li
            key={product.id}
            className="relative isolate flex gap-8 flex-row justify-between items-center mb-8 bg-white p-4 rounded-md"
          >
            <div className="flex gap-8">
              <ProductImage
                softwareProduct={product}
                width="w-24"
                height="h-24"
              />
              <div>
                <div className="flex items-center gap-x-4 text-xs">
                  {product.productCategory?.name && (
                    <Link
                      to={`/products?filter=${product.productCategory.id}`}
                      className="relative z-10 rounded-md bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {product.productCategory.name}
                    </Link>
                  )}
                </div>
                <div className="group relative max-w-xl">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:underline group-hover:text-gray-600">
                    <Link to={`/product/${product.id}`}>
                      <span className="absolute inset-0" />
                      {product.name}
                    </Link>
                  </h3>
                  {product.publisher && (
                    <p className="text-sm leading-6 text-gray-600">
                      {product.publisher}
                    </p>
                  )}
                  {product.description && (
                    <p className="mt-5 text-sm leading-6 text-gray-600">
                      {product.description}
                    </p>
                  )}
                </div>
                {product.productTags.items.length > 0 && (
                  <div className="flex items-center gap-2 my-2">
                    <TagIcon className="h-4 w-4 text-gray-600" />
                    {product.productTags.items.map(({ productTag }, index) => (
                      <>
                        {index !== 0 && (
                          <span className="text-gray-300">|</span>
                        )}
                        <Link
                          className="inline-flex items-center rounded-md text-sm font-medium text-gray-600 hover:underline hover:text-gray-700"
                          to={`/products?filter=${productTag.id}`}
                        >
                          {productTag.name}
                        </Link>
                      </>
                    ))}
                  </div>
                )}

                {/* <div className="mt-6 flex border-t pt-6">
                    <div className="relative flex items-center gap-x-4">
                      <img
                        src={product.author.imageUrl}
                        alt=""
                        className="h-10 w-10 rounded-full bg-gray-50"
                      />
                      <div className="text-sm leading-6">
                        <p className="font-semibold text-gray-900">
                          
                          <a href={product.author.href}>
                            <span className="absolute inset-0" />
                            {product.author.name}
                          </a>
                        </p>
                        <p className="text-gray-600">{product.author.role}</p>
                      </div>
                    </div>
                  </div> */}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Button
                className=""
                color="blue"
                onClick={() => handleEditProduct(product)}
              >
                Edit
              </Button>
              <Button
                className=""
                color="red"
                variant="soft"
                onClick={() => handleDeleteProduct(product)}
              >
                Delete
              </Button>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ProductList;
