import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import SurveyApp from "./SurveyApp.js";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import config from "./aws-exports.js";

Amplify.configure(config);

const components = {
  Header() {
    return (
      <div className="flex flex-shrink-0 justify-center items-center w-full my-6">
        <img
          className="h-24 w-auto mr-6"
          alt="Logo"
          src="/images/logo-white-cropped-no-bg.png"
        />
      </div>
    );
  },
  SetupTotp: {
    Header() {
      return (
        <div>
          <h1 className="text-xl sm:text-2xl">Set up an Authenticator App</h1>
          <div>
            Scan the QR code below using an authenticator app of your choice
          </div>
          <div className="mt-4 text-gray-500">
            Don't have an authenticator app? Download an authenticator app like
            Google Authenticator from the{" "}
            <a
              className="hover:text-gray-600 underline "
              href="https://apps.apple.com/gb/app/google-authenticator/id388497605"
            >
              App Store
            </a>{" "}
            or{" "}
            <a
              className="hover:text-gray-600 underline "
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            >
              Google Play
            </a>
            .
          </div>
        </div>
      );
    },
  },
  ConfirmSignIn: {
    Header() {
      return (
        <div>
          <h1 className="text-xl sm:text-2xl">Two-factor authentication</h1>
          <div>Enter the 6-digit code from your Authenticator app.</div>
        </div>
      );
    },
  },
};

const formFields = {
  setupTotp: {
    QR: {
      totpIssuer: "Bordercross Health",
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {window?.location?.pathname?.startsWith("/ext") ? (
        <SurveyApp />
      ) : (
        <Authenticator components={components} formFields={formFields}>
          {({ signOut, user: cognitoUser }) => (
            <App cognitoUser={cognitoUser} signOut={signOut} />
          )}
        </Authenticator>
      )}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
