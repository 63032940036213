import React from "react";
import {
  CircleStackIcon,
  BanknotesIcon,
  ArrowPathIcon,
} from "@heroicons/react/20/solid";

import { numberWithCommas } from "../../../utilities/general";
import {
  getStackCost,
  calculateProductsDueForRenewelInNMonths,
} from "../../../utilities/stacks";

const PcnStatBoxes = ({ stacks }) => {
  let totalProducts = 0;
  let totalCost = 0;
  let productsDueForRenewelIn3Months = 0;

  stacks.forEach((stack) => {
    totalProducts = totalProducts + stack?.SoftwareProducts?.items?.length;
    totalCost = totalCost + getStackCost(stack);
    productsDueForRenewelIn3Months =
      productsDueForRenewelIn3Months +
      calculateProductsDueForRenewelInNMonths(
        stack,
        3
      ).length;
  });

  return (
    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6 border-solid border-t-8 border-indigo-700">
        <dt>
          <div className="absolute rounded-md bg-indigo-700 p-3">
            <CircleStackIcon
              className="h-6 w-6 text-white"
              aria-hidden="true"
            />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Avg. products per organisation
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-2 sm:pb-4">
          <p className="text-2xl font-semibold text-gray-900">
            {Math.round(totalProducts / stacks.length)}
          </p>
        </dd>
      </div>
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6 border-solid border-t-8 border-indigo-500">
        <dt>
          <div className="absolute rounded-md bg-indigo-500 p-3">
            <BanknotesIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Avg. cost per organisation
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-2 sm:pb-4">
          <p className="text-2xl font-semibold text-gray-900">
          {isNaN(totalCost) ? "Unavailable" : `£${numberWithCommas(Math.round(totalCost / stacks.length))}`}
          </p>
        </dd>
      </div>
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6 border-solid border-t-8 border-indigo-400">
        <dt>
          <div className="absolute rounded-md bg-indigo-400 p-3">
          <ArrowPathIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Products renewals due{" "}
            <span className="text-xs">(next 3 months)</span>
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-2 sm:pb-4">
          <p className="text-2xl font-semibold text-gray-900">
            {productsDueForRenewelIn3Months}
          </p>
        </dd>
      </div>
    </dl>
  );
};

export default PcnStatBoxes;
