import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { TagIcon, LinkIcon } from "@heroicons/react/24/outline";
import { generateClient } from "aws-amplify/api";

import { post } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

import { getSoftwareProduct } from "../../graphql/queries-static";
import LoadBox from "../LoadBox";
import ProductImage from "../ProductImage";
const client = generateClient();

const ProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProduct = async () => {
      const stack = await client.graphql({
        query: getSoftwareProduct,
        variables: {
          id: productId,
        },
      });
      setProduct(stack.data.getSoftwareProduct);
    };
    fetchProduct();
  }, []);

    // useEffect(() => {
    //   const getSurveys = async () => {
    //     setLoadingSurveys(true);
  
    //     const restOperation = get({
    //       apiName: "bordercrossRest",
    //       path: `/public/surveys/productSurvey/${surveyUniqueCode}`,
    //     });
    //     const response = await restOperation.response;
    //     const survey = await response.body.json();
    //     setSurvey(survey);
    //     setLoadingSurveys(false);
    //   };
  
    //   getSurveys();
    // }, []);

    useEffect(() => {
      const getSurveys = async () => {
        // setLoadingSurveys(true);
        const accessToken = (
          await fetchAuthSession()
        ).tokens?.accessToken?.toString();
        const restOperation = post({
          apiName: "bordercrossRest",
          path: `/surveys/productSurvey/updateProductRating/${productId}`,
          options: {
            headers: {
              "jwt-token": "Basic " + accessToken,
            },
          },
        });
        const response = await restOperation.response;
        // const surveys = await response.body.json();
        // setSurveys(surveys);
        // setLoadingSurveys(false);
      };
  
      getSurveys();
    }, []);


  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
      <Link
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
        className="mb-8 inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back
      </Link>
      {product ? (
        <div className="flex flex-col gap-8 lg:flex-row">
          <ProductImage softwareProduct={product} width="w-36" height="h-36" />
          {/* <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
            <img
              src={`${process.env.PUBLIC_URL}/images/product-logos/${product.imageUrl}`}
              alt={`${product.name} logo`}
              className="absolute inset-0 max-h-full w-full rounded-2xl object-cover"
            />
            <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
          </div> */}
          <div>
            <div className="flex items-center">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {product.name}
              </h1>
              {product.productCategory?.name && (
                <div>
                  <Link
                    to={`/products?filter=${product.productCategory.id}`}
                    className="relative z-10 ml-2 rounded-md bg-sky-100 px-3 py-1.5 font-medium text-sky-600 hover:bg-sky-50"
                  >
                    {product.productCategory.name}
                  </Link>
                </div>
              )}
            </div>
            {product.publisher && (
                <p className="mt-1 text-lg sm:text-xl text-gray-600">{product.publisher}</p>
              )}
            {product.productTags.items.length > 0 && (
              <div className="flex items-center gap-2 my-2">
                <TagIcon className="h-4 w-4 text-indigo-600" />
                {product.productTags.items.map(({ productTag }, index) => (
                  <>
                    {index !== 0 && <span className="text-gray-300">|</span>}
                    <Link
                      className="inline-flex items-center rounded-md text-base font-medium text-indigo-600 hover:underline hover:text-indigo-700"
                      to={`/products?filter=${productTag.id}`}
                    >
                      {productTag.name}
                    </Link>
                  </>
                ))}
              </div>
            )}
            {product.link && (
              <a
                className="flex gap-2 mt-2 items-center text-gray-600 hover:underline hover:text-gray-700"
                href={product.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkIcon className="h-4 w-4" />
                {product.link}
              </a>
            )}
            <p className="mt-2 max-w-4xl text-lg leading-8 text-gray-600">
              {product.description}
            </p>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <LoadBox />
        </div>
      )}
    </div>
  );
};

export default ProductPage;
