import React, {useState} from 'react'

const Tabs = ({currentTab, setCurrentTab}) => {
    const tabs = [
      { name: 'Summary'},
      // { name: 'Mental Health' },
    ]

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
      }
      
  
    return (
      <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={(e) => setCurrentTab(e.target.value)}
          className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          defaultValue={currentTab}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setCurrentTab(tab.name)}
              className={classNames(
                currentTab === tab.name ? 'bg-blue-100 text-blue-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
    )
  }

  export default Tabs