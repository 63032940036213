export const listSoftwareProducts = `
  query ListSoftwareProducts(
    $filter: ModelSoftwareProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSoftwareProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        publisher
        description
        link
        imageUrl
        productCategory {
          id
          name
          description
        }
        productTags {
          items {
            id
            productTagId
            softwareProductId
            productTag {
              id
              name
              description
            }
          }
        }
        percentageRecommend
      }
      nextToken
      __typename
    }
  }
`;

export const getSoftwareProduct = /* GraphQL */ `
  query GetSoftwareProduct($id: ID!) {
    getSoftwareProduct(id: $id) {
      id
      name
      publisher
      description
      imageUrl
      link
      productCategory {
        id
        name
        description
      }
      productTags {
        items {
          id
          productTagId
          softwareProductId
          productTag {
            id
            name
            description
          }
        }
      }
      percentageRecommend
      reviewsCount
    }
  }
`;

export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        adminPrivate
        sector
        ownershipType
        staffCount
        customerCount
        odsId
        postcode
        addressLine1
        town
        county
        pcnName
        pcnId
        icbId
        icbName
        role
        telephone
        nhsRegionId
        nhsRegionName
        SoftwareStacks {
          items {
            id
            name
            description
            SoftwareProducts {
              items {
                id
                softwareProductId
                softwareStackId
                softwareProduct {
                  id
                  name
                  publisher
                  description
                  imageUrl
                  productcategoryID
                  createdAt
                  updatedAt
                  __typename
                }
              }
              nextToken
              __typename
            }
            isPersonal
            organisationID
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        country
        region
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      name
      sector
      ownershipType
      staffCount
      customerCount
      SoftwareStacks {
        items {
          id
          name
          description
          SoftwareProducts {
            items {
              id
              softwareProductId
              softwareStackId
              softwareProduct {
                id
                name
                publisher
                description
                link
                imageUrl
                productcategoryID
                productCategory {
                  id
                  name
                  description
                  createdAt
                  updatedAt
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
            }
            nextToken
            __typename
          }
          isPersonal
          organisationID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      country
      region
      odsId
      postcode
      addressLine1
      town
      county
      pcnName
      pcnId
      icbId
      icbName
      role
      telephone
      nhsRegionId
      nhsRegionName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;

export const listSoftwareStacks = /* GraphQL */ `
  query ListSoftwareStacks(
    $filter: ModelSoftwareStackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSoftwareStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        administrator
        readAccess
        owner
        organisation {
          id
          pcnName
          pcnId
          icbName
          icbId
        }
      }
    }
  }
`;

export const listSoftwareStacksWithProducts = /* GraphQL */ `
  query ListSoftwareStacks(
    $filter: ModelSoftwareStackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSoftwareStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        owner
        organisationID
        team
        organisation {
          id
          name
          sector
          ownershipType
          odsId
          staffCount
          customerCount
          country
          region
          createdAt
          updatedAt
          owner
          odsId
          postcode
          addressLine1
          town
          county
          pcnName
          pcnId
          icbId
          icbName
          role
          telephone
          nhsRegionId
          nhsRegionName
          __typename
        }
        SoftwareProducts {
          items {
            id
            softwareProductId
            softwareProduct {
              id
              publisher
              name
              imageUrl
            }
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listSoftwareStacksWithProductsAndProductManagements = /* GraphQL */ `
  query ListSoftwareStacks(
    $filter: ModelSoftwareStackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSoftwareStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        organisationID
        owner
        administrator
        readAccess
        team
        organisation {
          id
          name
          sector
          ownershipType
          odsId
          staffCount
          customerCount
          country
          region
          odsId
          postcode
          addressLine1
          town
          county
          pcnName
          pcnId
          icbId
          icbName
          role
          telephone
          nhsRegionId
          nhsRegionName
          createdAt
          updatedAt
          owner
        }
        SoftwareProducts {
          items {
            id
            softwareProductId
            softwareStackId
            softwareProduct {
              id
              name
              publisher
              description
              imageUrl
              softwarestacks {
                items {
                  id
                  softwareProductId
                  softwareStackId
                  owner
                }
              }
              productcategoryID
              productCategory {
                id
                name
                description
              }
              productTags {
                items {
                  id
                  productTagId
                  softwareProductId
                  productTag {
                    id
                    name
                    description
                  }
                }
              }
            }
            owner
          }
        }
        StackProductManagements {
          items {
            id
            notes
            DPIAStatus
            DCB0160Status
            contractStartDate
            contractEndDate
            sharingAgreementStatus
            price
            pricingPlan
            softwarestackID
            procuredInternally
            productManagementDetailsSoftwareProductId
          }
        }
      }
    }
  }
`;

export const getSoftwareStack = /* GraphQL */ `
  query GetSoftwareStack($id: ID!) {
    getSoftwareStack(id: $id) {
      id
      name
      description
      owner
      SoftwareProducts {
        items {
          id
          softwareProductId
          softwareStackId
          softwareProduct {
            id
            name
            publisher
            description
            imageUrl
            productcategoryID
            productCategory {
              id
              name
              description
            }
          }
        }
      }
      isPersonal
      organisationID
      organisation {
        id
        name
        sector
        ownershipType
        odsId
        staffCount
        customerCount
        country
        region
        createdAt
        updatedAt
        owner
        odsId
        postcode
        addressLine1
        town
        county
        pcnName
        pcnId
        icbId
        icbName
        role
        telephone
        nhsRegionId
        nhsRegionName
      }
      createdAt
      updatedAt
    }
  }
`;

export const getSoftwareStackWithProductManagements = /* GraphQL */ `
  query GetSoftwareStack($id: ID!) {
    getSoftwareStack(id: $id) {
      id
      name
      description
      owner
      team
      readAccess
      SoftwareProducts {
        items {
          id
          softwareProductId
          softwareStackId
          softwareProduct {
            id
            name
            publisher
            description
            imageUrl
            productcategoryID
            productCategory {
              id
              name
              description
            }
          }
        }
      }
      isPersonal
      organisationID
      organisation {
        id
        name
        sector
        ownershipType
        odsId
        staffCount
        customerCount
        country
        region
        createdAt
        updatedAt
        owner
        odsId
        postcode
        addressLine1
        town
        county
        pcnName
        pcnId
        icbId
        icbName
        role
        telephone
        nhsRegionId
        nhsRegionName
      }
      StackProductManagements {
        items {
          id
          notes
          DPIAStatus
          DCB0160Status
          contractStartDate
          contractEndDate
          sharingAgreementStatus
          price
          pricingPlan
          softwarestackID
          procuredInternally
          productManagementDetailsSoftwareProductId
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const listProductManagementDetails = /* GraphQL */ `
  query ListProductManagementDetails(
    $filter: ModelProductManagementDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductManagementDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notes
        DPIAStatus
        DCB0160Status
        contractStartDate
        contractEndDate
        sharingAgreementStatus
        price
        pricingPlan
        softwarestackID
        createdAt
        updatedAt
        productManagementDetailsSoftwareProductId
        procuredInternally
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listUsersWithSubscriptions = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        givenName
        familyName
        userSubscription {
          id
          plan
          currentPeriodEnd
        }
        optOutContractEndEmailNotificaitons
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listProductCategories = /* GraphQL */ `
  query ListProductCategories(
    $filter: ModelProductCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listProductFilters = /* GraphQL */ `
  query ListProductFilters {
    listProductTags {
      items {
        id
        name
        description
      }
    }
    listProductCategories {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;

export const listPCNStacksForSummaryPage = /* GraphQL */ `
  query ListSoftwareStacks(
    $filter: ModelSoftwareStackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSoftwareStacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        organisationID
        owner
        administrator
        readAccess
        team
        organisation {
          id
          name
          sector
          odsId
          customerCount
          country
          region
          county
          pcnName
          icbName
          role
          nhsRegionName
          owner
          organisationStats {
            phePopulation
            phePopulationSummary
          }
        }
        SoftwareProducts {
          items {
            id
            softwareProductId
            softwareStackId
            softwareProduct {
              id
              name
              publisher
              imageUrl
              productcategoryID
              productCategory {
                id
                name
                description
              }
              productTags {
                items {
                  id
                  productTagId
                  softwareProductId
                  productTag {
                    id
                    name
                    description
                  }
                }
              }
            }
            owner
          }
        }
        StackProductManagements {
          items {
            id
            notes
            DPIAStatus
            DCB0160Status
            contractStartDate
            contractEndDate
            sharingAgreementStatus
            price
            pricingPlan
            softwarestackID
            procuredInternally
            productManagementDetailsSoftwareProductId
          }
        }
      }
    }
  }
`;