import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { generateClient, get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

import { listSoftwareStacks } from "../../../graphql/queries-static";
import LoadBox from "../../LoadBox";

import OrganisationSelector from "../../OrganisationSelector";
import SurveysGrid from "./SurveysGrid";
import MenuOptions from "./MenuOptions";
import SurveysGridSkeleton from "./SurveysGridSkeleton";

const client = generateClient();

const SurveysPage = ({ cognitoUser, cognitoUserAttributes }) => {
  const [loadingSurveys, setLoadingSurveys] = useState();
  const [surveys, setSurveys] = useState([]);
  const [loadingStackList, setLoadingStackList] = useState();
  const [stackList, setStackList] = useState();
  const [selectedStackId, setSelectedStackId] = useState();
  const [loadingBusinessProcessSummaries, setLoadingBusinessProcessSummaries] =
    useState(true);
  const [businessProcessSummaries, setBusinessProcessSummaries] = useState([]);
  const location = useLocation();

  const isSiteAdmin = cognitoUserAttributes?.isSiteAdmin;

  useEffect(() => {
    const getStackList = async () => {
      setLoadingStackList(true);
      const softwareStacks = await client.graphql({
        query: listSoftwareStacks,
        variables: {
          limit: 1000,
          filter: {
            or: [
              { owner: { eq: cognitoUser.userId } },
              { administrator: { contains: cognitoUser.userId } },
              { readAccess: { contains: cognitoUser.userId } },
            ],
          },
        },
        authMode: "userPool",
      });

      const sortedStacks = softwareStacks.data.listSoftwareStacks.items?.sort(
        (stackA, stackB) => {
          const getStackName = (stack) => {
            return stack?.organisation?.name || stack.name;
          };
          const stackAName = getStackName(stackA);
          const stackBName = getStackName(stackB);

          if (stackAName > stackBName) return 1;
          if (stackBName > stackAName) return -1;
          return 0;
        }
      );

      const urlParams = new URLSearchParams(location.search);
      const stackIdFromUrl = urlParams.get("stackId");
      const stackExistsFromUrl = !!sortedStacks.find(
        (stack) => stack.id === stackIdFromUrl
      );

      setStackList(sortedStacks);
      if (stackExistsFromUrl) {
        setSelectedStackId(stackIdFromUrl);
      } else {
        setSelectedStackId(sortedStacks[0]?.id);
      }

      setLoadingStackList(false);
      return sortedStacks;
    };

    getStackList();
  }, []);

  useEffect(() => {
    const getSurveys = async () => {
      setLoadingSurveys(true);
      const accessToken = (
        await fetchAuthSession()
      ).tokens?.accessToken?.toString();
      const restOperation = get({
        apiName: "bordercrossRest",
        path: "/surveys/",
        options: {
          headers: {
            "jwt-token": "Basic " + accessToken,
          },
        },
      });
      const response = await restOperation.response;
      const surveys = await response.body.json();
      setSurveys(surveys);
      setLoadingSurveys(false);
    };

    getSurveys();
  }, []);

  useEffect(() => {
    const getBusinessProcessSummaries = async () => {
      setLoadingBusinessProcessSummaries(true);

      const accessToken = (
        await fetchAuthSession()
      ).tokens?.accessToken?.toString();

      const restOperation = get({
        apiName: "bordercrossRest",
        path: "/surveys/summaries/" + selectedStackId,
        options: {
          headers: {
            "jwt-token": "Basic " + accessToken,
          },
        },
      });
      const response = await restOperation.response;
      const businessProcessSummaries = await response.body.json();
      setBusinessProcessSummaries(businessProcessSummaries);
      setLoadingBusinessProcessSummaries(false);
    };

    if (selectedStackId) getBusinessProcessSummaries();
  }, [selectedStackId]);

  const loading =
    loadingSurveys || loadingStackList || loadingBusinessProcessSummaries;

  const selectedStack = stackList?.find(
    (stack) => stack?.id === selectedStackId
  );
  const isReadAccess = selectedStack?.readAccess?.includes(cognitoUser.userId);

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 my-10 lg:px-8 bg-slate-50">
      <div className="flex flex-col md:flex-row gap-4 justify-between align-start">
        <div className="flex-shrink-0">
          <div className="flex gap-4 items-center">
            <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Employee Insights
            </h1>
            {isSiteAdmin && (
              <MenuOptions
                stackId={selectedStackId}
                stackName={selectedStack?.name}
              />
            )}
          </div>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Gather valuable insights from staff about your technology
          </p>
        </div>

        {selectedStackId && (
          <div className="max-w-2xl">
            <OrganisationSelector
              defaultValue={selectedStackId}
              cognitoUser={cognitoUser}
              stackList={stackList}
              onChange={(stackId) => {
                setSelectedStackId(stackId);
              }}
              hideLabel={true}
            />
          </div>
        )}
      </div>

      <div className="mt-8">
        {loading ? (
          <SurveysGridSkeleton />
        ) : (
          <SurveysGrid
            surveys={surveys}
            businessProcessSummaries={businessProcessSummaries}
            selectedStack={selectedStack}
            isReadAccess={isReadAccess}
          />
        )}
      </div>
    </div>
  );
};

export default SurveysPage;
