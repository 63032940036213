import React from "react";
import { useLocation,} from "react-router-dom";

const StackTabs = ({ cognitoUser, stackList, getStack, setCurrentProduct }) => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const stackId = urlParams.get("stackId");
  const matchedStack = stackList?.find((stack) => stack.id === stackId);

  return (
    <div>
      <div>
        <label htmlFor="tabs" className="font-bold">
          Select an organisation
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block mt-2 w-full h-12 rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
          defaultValue={matchedStack?.id || stackList[0]?.id}
          onChange={(e) => {
            const selectedStack = stackList.find(
              (stack) => stack.id === e.target.value
            );
            getStack(selectedStack.id);
            setCurrentProduct()
          }}
        >
          {stackList.map((stack) => {
            const stackName = stack?.organisation?.name || stack.name
            let userTypeLabel
            if (stack?.administrator?.includes(cognitoUser.userId)) userTypeLabel = 'Administrator'
            if (stack?.readAccess?.includes(cognitoUser.userId)) userTypeLabel = 'Read access'
            return (
              <option key={stack.id} value={stack.id}>
                {stackName} {userTypeLabel && <span className="text-sm">({userTypeLabel})</span>}
              </option>
            );
          })}
        </select>
      </div>
      {/* <div className="hidden sm:block">
        <h2 className="mb-2 font-bold tracking-tight text-gray-900 text-lg">
          Select a stack
        </h2>
        <nav className="flex flex-wrap gap-4" aria-label="Tabs">
          {stackList.map((stack) => {
              const stackName = stack?.organisation?.name || stack.name;
              let userTypeLabel
              if (stack?.administrator?.includes(cognitoUser.userId)) userTypeLabel = 'Administrator'
              if (stack?.readAccess?.includes(cognitoUser.userId)) userTypeLabel = 'Read access'
            return (
            <button
              key={stack.id}
              className={classNames(
                currentTab.id === stack.id
                  ? "bg-blue-100 text-blue-700"
                  : "text-gray-500 hover:text-gray-700",
                "rounded-md px-3 py-2 text-md font-medium"
              )}
              aria-current={currentTab.id === stack.id ? "page" : undefined}
              onClick={() => {
                setStack(stack);
                setCurrentProduct()
              }}
            >
              {stackName} {userTypeLabel && <span className="text-sm">({userTypeLabel})</span>}
            </button>
          )
            })}
        </nav>
      </div> */}
    </div>
  );
};

export default StackTabs;
