import { titleCaseNHS } from "./ods";

export const getSelectedPracticeIds = (selectedNodeKeys, nodes) => {
  if (!selectedNodeKeys) return []

  const selectedPracticesKeys = Object.keys(selectedNodeKeys).filter((key) =>
    /^\d{1,2}-\d{1,2}-\d{1,2}$/.test(key)
  ); // filter out keys with 3 numbers e.g. 5-0-1 (we're only interested in practices)

  const selectedPracticeIds = selectedPracticesKeys.map(
    (selectedPracticeKey) => {
      const [icbKey, pcnKey, practiceKey] = selectedPracticeKey.split("-");
      const stackId =
        nodes?.[icbKey]?.children?.[pcnKey]?.children?.[practiceKey]?.data
          ?.stackId;
      return stackId;
    }
  );

  return selectedPracticeIds;
};

export const getAreaStructureNodes = (areaStructure) => {
  return areaStructure?.map((icb, icbIndex) => {
    return {
      key: icbIndex,
      label: titleCaseNHS(icb.name),
      data: icb,
      children: icb.pcns?.map((pcn, pcnIndex) => {
        return {
          key: `${icbIndex}-${pcnIndex}`,
          label: titleCaseNHS(pcn.name),
          data: pcn,
          children: pcn.organisations?.map(
            (organisation, organisationIndex) => {
              return {
                key: `${icbIndex}-${pcnIndex}-${organisationIndex}`,
                label: titleCaseNHS(organisation.name),
                data: organisation,
              };
            }
          ),
        };
      }),
    };
  });
};
