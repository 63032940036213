import { NavLink } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const baseStyles = {
  solid:
    "group inline-flex items-center justify-center rounded-md font-semibold",
  outline:
    "group inline-flex items-center justify-center rounded-md font-semibold",
  soft: "group inline-flex items-center justify-center rounded-md font-semibold",
};

const variantStyles = {
  solid: {
    blue: "bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500",
    indigo: "bg-indigo-600 text-white hover:text-slate-100 hover:bg-indigo-500",
    white:
      "bg-white ring-1 ring-inset ring-gray-300 text-slate-900 hover:bg-gray-50",
  },
  outline: {
    white:
      "ring-slate-700 text-white hover:ring-slate-500",
  },
  soft: {
    indigo: "bg-indigo-50 text-indigo-800 hover:bg-indigo-100",
    blue: "bg-blue-50 text-blue-800 hover:bg-blue-100",
    gray: "bg-gray-100 text-gray-600 hover:bg-gray-200",
    red: "bg-red-50 text-red-600 hover:bg-red-100",
  },
};

const sizeStyles ={
    md: "py-2 px-4 text-sm",
    sm: "py-2 px-4 text-xs"
}

export default function Button({ className, ...props }) {
  props.variant ??= "solid";
  props.color ??= "slate";
  props.size ??= "md"

  className = classNames(
    baseStyles[props.variant],
    variantStyles[props.variant][props.color],
    sizeStyles[props.size],
    className
  );

  return typeof props.href === "undefined" && !props.to ? (
    <button className={className} {...props} />
  ) : (
    <NavLink className={className} {...props} />
  );
}
