import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { classNames } from "../../../utilities/general";
import { titleCaseNHS } from "../../../utilities/ods";

const SelectOrgArea = ({ areaStructure, selected, setSelected }) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block font-bold leading-6 text-gray-900">
            Select PCN
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:leading-6">
              <span className="block truncate">
                {titleCaseNHS(selected?.name)}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {areaStructure.map((icb) => {
                  return (
                    <>
                      <IcbOption
                        id={icb.id}
                        name={titleCaseNHS(icb.name)}
                      />
                      {icb.pcns.map((pcn) => {
                        return (
                          <PcnOption
                            id={pcn.id}
                            name={titleCaseNHS(pcn.name)}
                          />
                        );
                      })
                      }
                    </>
                  );
                })}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

const IcbOption = (icb) => (
  <Listbox.Option
    key={icb.id}
    className={({ active }) =>
      classNames(
        active ? "bg-blue-600 text-white" : "text-gray-900",
        "bg-gray-100 relative cursor-default select-none py-2 pl-3 pr-9"
      )
    }
    value={icb}
    disabled
  >
    {({ selected, active }) => (
      <>
        <span
          className={classNames(
            selected ? "font-semibold" : "font-normal",
            "block truncate"
          )}
        >
          {icb.name}
        </span>

        {selected ? (
          <span
            className={classNames(
              active ? "text-white" : "text-blue-600",
              "absolute inset-y-0 right-0 flex items-center pr-4"
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
);

const PcnOption = (pcn) => (
  <Listbox.Option
    key={pcn.id}
    className={({ active }) =>
      classNames(
        active ? "bg-blue-600 text-white" : "text-gray-900",
        "relative cursor-default select-none py-2 pl-8 pr-9"
      )
    }
    value={pcn}
  >
    {({ selected, active }) => (
      <>
        <span
          className={classNames(
            selected ? "font-semibold" : "font-normal",
            "block truncate"
          )}
        >
          {pcn.name}
        </span>

        {selected ? (
          <span
            className={classNames(
              active ? "text-white" : "text-blue-600",
              "absolute inset-y-0 right-0 flex items-center pr-4"
            )}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </span>
        ) : null}
      </>
    )}
  </Listbox.Option>
);

export default SelectOrgArea;
