import { useState } from "react";
import { post } from "aws-amplify/api";
import { useForm } from "react-hook-form";
import { fetchAuthSession } from "aws-amplify/auth";

export default function EditOrgNameModal({
  currentOrganisationName,
  stackId,
  setOpen,
}) {
  const [updatingLoading, setUpdatingLoading] = useState(false);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: currentOrganisationName,
    },
  });

  const onSubmit = async (data) => {
    setUpdatingLoading(true);

    const accessToken = (
      await fetchAuthSession()
    ).tokens?.accessToken?.toString();

    await post({
      apiName: "bordercrossRest",
      path: `/stacks/organisationName/update/${stackId}`,
      options: {
        headers: {
          "jwt-token": "Basic " + accessToken,
        },
        body: {
          name: data.name
        },
      },
    }).response

    setUpdatingLoading(false);
    setOpen(false);
    window.location.reload()
  };

  return (
    <>
      <div className="max-h-[75vh] overflow-scroll">
        <h2 className="text-xl sm:text-4xl font-bold">
          Edit organisation name
        </h2>

        <div className="mt-8">
          <label
            htmlFor="contractStartDate"
            className="block text-md font-medium leading-6 text-gray-900"
          >
            Organisation name
          </label>
          <div className="mt-2">
            <input
              {...register("name")}
              type="text"
              id="name"
              name="name"
              className="block w-full rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
            />
          </div>
        </div>
      </div>

      <div className="mt-5 sm:mt-12 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          disabled={updatingLoading ? true : false}
          type="button"
          className={
            "inline-flex items-center w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 disabled:bg-blue-400 disabled:hover:bg-blue-400 disabled:cursor-not-allowed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 sm:col-start-2"
          }
          onClick={handleSubmit((data) => onSubmit(data))}
        >
          {updatingLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
          Save
        </button>

        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={() => setOpen(false)}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
