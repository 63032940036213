import React, { useState } from "react";
import { useForm } from "react-hook-form";

import Modal from "./Modal";
import SurveyQuestion from "./SurveyQuestion";
import Button from "./Button";
import ProductImage from "./ProductImage";

const ProductSurveyPreview = ({
  modalOpen,
  setModalOpen,
  product,
  questions
}) => {
  const form = useForm();

  return (
    <Modal open={modalOpen} setOpen={setModalOpen}>
      <div>
        <h1 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
          {product.name}
        </h1>
    
        <div>
        <h3 className="mt-6 text-sm">This section is about: </h3>
        <div className="mt-2 flex gap-4 items-center">
          <div className="flex-shrink-0">
            <ProductImage
              height="h-16"
              width="w-16"
            />
          </div>
          <div>
            <h2 className="font-semibold text-lg">{product.name}</h2>
          </div>
        </div>
      </div>
      </div>

      {questions?.map((question) => {
        return <SurveyQuestion question={question} form={form} />;
      })}

      <div className="flex justify-end">
        <Button
          variant="solid"
          color="blue"
          onClick={() => setModalOpen(false)}
        >
          Close preview
        </Button>
      </div>
    </Modal>
  );
};

export default ProductSurveyPreview;
