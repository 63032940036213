import React from "react";
import { Routes, Route } from "react-router-dom";
import "rc-tooltip/assets/bootstrap.css";

import "./App.css";
import ErrorBoundary from "./components/pages/ErrorBoundary";
import ProductSurveyPage from './components/pages/ProductSurveyPage'
import NotFound from "./components/pages/NotFoundPage.js";
import BusinessProcessSurveyPage from "./components/pages/BusinessProcessSurveyPage/index.js";

function App({ signOut, cognitoUser }) {

  return (
    <>
      <div className="bg-white shadow">
        <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:px-8  ">
          <div className="flex h-20 justify-between">
            <div className="flex flex-shrink-0 items-center mr-6">
              <a href="https://bordercross.health">
                <img
                  className="h-14 w-auto"
                  alt="Logo"
                  src="/images/logo-white-cropped.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <ErrorBoundary>
        <Routes>
          <Route path="/ext/employee-insights/product-survey/:surveyUniqueCode" element={<ProductSurveyPage />} />
          <Route path="/ext/employee-insights/business-process-survey/:surveyUniqueCode" element={<BusinessProcessSurveyPage />} />
          <Route path="/ext/*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </>
  );
}

export default App;
