import React from "react";
import {
  Chart,
  Colors,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip as ChartTooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(
  Colors,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  ChartTooltip
);

const MostUsedProductsChart = ({ products, mostUsedProducts }) => {
  const barChartData = {
    labels: mostUsedProducts.map(mostUsedProduct => { 
        const productInformation = products.find(product => mostUsedProduct.id === product.id)
        return productInformation.name
    }),
    datasets: [
      {
        data: mostUsedProducts.map((product) => product.usageCount),
        images: mostUsedProducts.map(mostUsedProduct => { 
            const productInformation = products.find(product => mostUsedProduct.id === product.id)
            return `${process.env.PUBLIC_URL}/images/product-logos/${productInformation.imageUrl}`
        })
      },
    ],
  };

const xScaleImage = {
    id: 'xScaleImage', 
    afterDatasetsDraw: (chart, args, plugins) => {
        const {ctx, data, chartArea: { bottom }, scales: {x} } = chart

        ctx.save()
        // console.log(x, 'x<<<<')
        data.datasets[0].images.forEach((image, index) => {
            const label = new Image()
            label.src = image
            const width = 50
      
            ctx.drawImage(label, 0, x.top, width, width)
        })
    }
}

  const options = {
    scales: {
        x: {
            ticks: {
                // callback: () => {
                //     return ''
                // }
            }
        }
    }
  };

  

  return (
    <>
      <Bar data={barChartData} options={options} plugins={[xScaleImage]}/>
    </>
  );
};

export default MostUsedProductsChart;
