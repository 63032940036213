import { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { listSoftwareStacks } from "../graphql/queries-static";

const client = generateClient();

const useStackList = (cognitoUser) => {
  const [stackList, setStackList] = useState([]);
  const [loadingStackList, setLoadingStackList] = useState(true);

  useEffect(() => {
    const getStackList = async () => {
      setLoadingStackList(true);
      try {
        const softwareStacks = await client.graphql({
          query: listSoftwareStacks,
          variables: {
            limit: 1000,
            filter: {
              or: [
                { owner: { eq: cognitoUser.userId } },
                { administrator: { contains: cognitoUser.userId } },
                { readAccess: { contains: cognitoUser.userId } },
              ],
            },
          },
          authMode: "userPool",
        });

        const sortedStacks = softwareStacks.data.listSoftwareStacks.items?.sort(
          (stackA, stackB) => {
            const getStackName = (stack) => {
              return stack?.organisation?.name || stack.name;
            };
            const stackAName = getStackName(stackA);
            const stackBName = getStackName(stackB);

            if (stackAName > stackBName) return 1;
            if (stackBName > stackAName) return -1;
            return 0;
          }
        );

        setStackList(sortedStacks);
      } catch (error) {
        console.error("Failed to fetch software stacks", error);
      } finally {
        setLoadingStackList(false);
      }
    };

    getStackList();
  }, [cognitoUser.userId]);

  return { stackList, loadingStackList };
};

export default useStackList;
