import { useState, useEffect } from "react";
import { generateClient } from "aws-amplify/api";
import { listSoftwareProducts } from "../graphql/queries-static";

const client = generateClient();
const useProducts = () => {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);

  useEffect(() => {
    const getProducts = async () => {
      setLoadingProducts(true);
      try {
        const softwareProducts = await client.graphql({
          query: listSoftwareProducts,
          variables: {
            limit: 1000,
          },
          authMode: 'userPool'
        });

        const products = softwareProducts?.data?.listSoftwareProducts?.items;

        const sortedProducts = products?.sort((productA, productB) => {
          if (productA.name > productB.name) return 1;
          if (productB.name > productA.name) return -1;
          return 0;
        });

        setProducts(sortedProducts);
      } catch (error) {
        console.error('Failed to fetch software products', error);
      } finally {
        setLoadingProducts(false);
      }
    };

    getProducts();
  }, []);

  return { products, loadingProducts };
};

export default useProducts;
