import React from "react";
import RCTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

const Tooltip = ({ children, overlay = "" }) => {
  return (
    <>
      <RCTooltip
        overlayClassName="rounded-lg"
        placement="bottom"
        trigger={["click", "hover"]}
        overlay={<div className="p-2">{overlay}</div>}
        overlayStyle={{ background: "white", opacity: 1 }}
        overlayInnerStyle={{ border: "none", maxWidth: '600px' }}
        showArrow={false}
      >
        {children}
      </RCTooltip>
    </>
  );
};

export default Tooltip;
