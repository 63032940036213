import React, { useEffect, useState } from "react";
import { generateClient } from "aws-amplify/api";
import { Link } from "react-router-dom";
import { ArrowDownTrayIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import TableToExcel from "@linways/table-to-excel";

import { Filter, FilteredTable, Table } from "./";
import Button from "../../Button";
import LoadBox from "../../LoadBox";
import {
  listSoftwareStacksWithProductsAndProductManagements,
  listProductManagementDetails,
  listProductFilters,
} from "../../../graphql/queries-static";
import { titleCaseNHS } from "../../../utilities/ods";
import { fetchPheData } from "../../../utilities/phe";
import SummaryStats from "./SummaryStats";

const client = generateClient();

const DashboardSummary = ({ cognitoUser }) => {
  const [stacks, setStacks] = useState([]);
  const [productManagementDetails, setProductManagementDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPheData, setLoadingPheData] = useState(true);
  //   const [loadingPheData, setLoadingPheData] = useState();
  //   const [pheDetails, setPheDetails] = useState()
  const [productFilters, setProductFilters] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [perPatientPricing, setPerPatientPricing] = useState(false);
  const [downloadingXLS, setDownloadingXLS] = useState(false);

  const getStacks = async () => {
    setLoadingPheData(true);
    setLoading(true);
    const softwareStacks = await client.graphql({
      query: listSoftwareStacksWithProductsAndProductManagements,
      variables: {
        limit: 1000,
        filter: {
          or: [
            { owner: { eq: cognitoUser.userId } },
            { administrator: { contains: cognitoUser.userId } },
            { readAccess: { contains: cognitoUser.userId } },
          ],
        },
      },
      authMode: "userPool",
    });

    const productManagementDetails = await client.graphql({
      query: listProductManagementDetails,
      variables: {
        limit: 1000,
      },
      authMode: "userPool",
    });

    const sortedStacks = softwareStacks.data.listSoftwareStacks.items?.sort(
      (stackA, stackB) => {
        const getStackName = (stack) => {
          return stack?.organisation?.name || stack.name;
        };
        const stackAName = getStackName(stackA);
        const stackBName = getStackName(stackB);

        if (stackAName > stackBName) return 1;
        if (stackBName > stackAName) return -1;
        return 0;
      }
    );
    setStacks(sortedStacks);
    setProductManagementDetails(
      productManagementDetails.data.listProductManagementDetails.items
    );
    setLoading(false);

    // inefficient to fetch phe details individually
    const stacksWithPheDetails = await Promise.all(
      sortedStacks.map(async (sortedStack) => {
        if (sortedStack?.organisation?.odsId) {
          try {
            const populationResponse = await fetchPheData(
              `https://fingertips.phe.org.uk/api/quinary_population?area_code=${sortedStack.organisation.odsId}&area_type_id=7`
            );

            return {
              ...sortedStack,
              phe: {
                population: populationResponse,
              },
            };
          } catch (error) {
            return sortedStack;
          }
        } else {
          return sortedStack;
        }
      })
    );
    setStacks(stacksWithPheDetails);
    setLoadingPheData(false);
  };

  useEffect(() => {
    const fetchStacks = async () => {
      await getStacks();
    };
    fetchStacks();
  }, []);

  useEffect(() => {
    if (downloadingXLS) {
      TableToExcel.convert(document.getElementById("table"));
      setDownloadingXLS(false);
    }
  }, [downloadingXLS]);

  useEffect(() => {
    const getFilters = async () => {
      const productFilters = await client.graphql({
        query: listProductFilters,
        authMode: 'userPool'
      });

      const sortedProductTags = productFilters.data.listProductTags.items.sort(
        (productTagA, productTagB) => {
          if (productTagA.name > productTagB.name) return 1;
          if (productTagB.name > productTagA.name) return -1;
          return 0;
        }
      );

      const sortedProductCategories =
        productFilters.data.listProductCategories.items.sort(
          (productCategoryA, productCategoryB) => {
            if (productCategoryA.name > productCategoryB.name) return 1;
            if (productCategoryB.name > productCategoryA.name) return -1;
            return 0;
          }
        );

      setProductFilters([
        {
          id: "category",
          name: "Category",
          options: sortedProductCategories,
        },
        {
          id: "tag",
          name: "Tags",
          options: sortedProductTags,
        },
      ]);
    };

    getFilters();
  }, []);

  const listPCNsFromStacks = (stacks) => {
    let pcns = [];
    stacks?.forEach((stack) => {
      const pcnId = stack?.organisation?.pcnId;
      if (pcnId) {
        const pcnAlreadyAdded = pcns.find((pcn) => pcn.id === pcnId);
        if (!pcnAlreadyAdded)
          pcns.push({
            id: pcnId,
            name: titleCaseNHS(stack.organisation.pcnName),
          });
      }
    });
    return pcns;
  };

  const listICBsFromStacks = (stacks) => {
    let icbs = [];
    stacks?.forEach((stack) => {
      const icbId = stack?.organisation?.icbId;
      if (icbId) {
        const icbAlreadyAdded = icbs.find((icb) => icb.id === icbId);
        if (!icbAlreadyAdded)
          icbs.push({
            id: icbId,
            name: titleCaseNHS(stack.organisation.icbName),
          });
      }
    });
    return icbs;
  };

  const listNHSRegionsFromStacks = (stacks) => {
    let nhsRegions = [];
    stacks?.forEach((stack) => {
      const nhsRegionId = stack?.organisation?.nhsRegionId;
      if (nhsRegionId) {
        const nhsRegionAlreadyAdded = nhsRegions.find(
          (nhsRegion) => nhsRegion.id === nhsRegionId
        );
        if (!nhsRegionAlreadyAdded)
          nhsRegions.push({
            id: nhsRegionId,
            name: titleCaseNHS(stack.organisation.nhsRegionName),
          });
      }
    });
    return nhsRegions;
  };

  const icbsFromStacks = listICBsFromStacks(stacks);
  const pcnsFromStacks = listPCNsFromStacks(stacks);
  const filters = [
    ...(productFilters || []),
    ...(icbsFromStacks.length > 0
      ? [
          {
            id: "icb",
            name: "Integrated Care Board (ICB)",
            options: icbsFromStacks,
          },
        ]
      : []),
    ...(pcnsFromStacks.length > 0
      ? [
          {
            id: "pcn",
            name: "Primary Care Network (PCN)",
            options: pcnsFromStacks,
          },
        ]
      : []),
    {
      id: "procured",
      name: "Procured Internally/Externally",
      options: [
        { id: "internally", name: "Internally" },
        { id: "externally", name: "Externally" },
      ],
    },
  ];

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-10">
      <Link
        to="/dashboard"
        className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        <ArrowLeftIcon className="h-4 w-4 mr-1" /> Back
      </Link>
      <div className="py-16 sm:py-24">
        <div className="mb-10">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900">
            Summary
          </h1>
          <p className="mt-4 text-base text-gray-500">
            An aggregated view of all your stacks
          </p>
        </div>
        {loading ? (
          <LoadBox />
        ) : (
          <>
            <SummaryStats
              loadingPheData={loadingPheData}
              stacks={stacks}
              productManagementDetails={productManagementDetails}
            />
            {productFilters && (
              <Filter
                filters={filters}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
              />
            )}
            <div className="flex gap-2 justify-end">
              <Button color="blue" onClick={() => {}}>
                <ArrowDownTrayIcon
                  className="h-5 w-5 mr-1"
                  aria-hidden="true"
                />
                Download as PDF
              </Button>
              <Button
                color="blue"
                onClick={async () => {
                  setDownloadingXLS(true);
                }}
              >
                <ArrowDownTrayIcon
                  className="h-5 w-5 mr-1"
                  aria-hidden="true"
                />
                Download as XLS
              </Button>
            </div>
            {selectedFilters.length === 0 ? (
              <Table
                loadingPheData={loadingPheData}
                downloadingXLS={downloadingXLS}
                perPatientPricing={perPatientPricing}
                setPerPatientPricing={setPerPatientPricing}
                productManagementDetails={productManagementDetails}
                stacks={stacks}
                selectedFilters={selectedFilters}
                // pheDetails={pheDetails}
              />
            ) : (
              <FilteredTable
                loadingPheData={loadingPheData}
                downloadingXLS={downloadingXLS}
                perPatientPricing={perPatientPricing}
                setPerPatientPricing={setPerPatientPricing}
                productManagementDetails={productManagementDetails}
                stacks={stacks}
                filters={filters}
                selectedFilters={selectedFilters}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardSummary;
