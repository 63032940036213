import React from "react";

import ProductImage from "../../ProductImage";
import CategoryInput from "./CategoryInput";
import TagInput from "./TagInput";

const ProductForm = ({
  product,
  productFilters,
  register,
  setValue,
  watch,
}) => {
  const imageUrl = watch("imageUrl");

  return (
    <form className="md:w-3/4" onSubmit={(e) => e.preventDefault}>
      <div className="my-6">
        <label
          htmlFor="name"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Name
        </label>
        <div className="mt-4">
          <input
            {...register("name")}
            type="text"
            id="name"
            className="block w-full rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
          />
        </div>
      </div>

      <div className="my-6">
        <label
          htmlFor="publisher"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Publisher
        </label>
        <div className="mt-4">
          <input
            {...register("publisher")}
            type="text"
            id="publisher"
            className="block w-full rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
          />
        </div>
      </div>

      <div className="my-6">
        <label
          htmlFor="description"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Description
        </label>
        <div className="mt-4">
          <textarea
            rows={4}
            {...register("description")}
            id="description"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
          />
        </div>
      </div>

      <div className="my-6">
        <label
          htmlFor="link"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Link
        </label>
        <div className="mt-4">
          <input
            {...register("link")}
            type="text"
            id="link"
            className="block w-full rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
          />
        </div>
      </div>

      <div className="my-6">
        <label
          htmlFor="imageUrl"
          className="block text-md font-medium leading-6 text-gray-900"
        >
          Image Url
        </label>
        <div className="mt-4">
          <div className="flex gap-2">
            <div className="w-full">
              <input
                {...register("imageUrl")}
                type="text"
                id="imageUrl"
                className="block w-full rounded-md border-0 px-2 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-md sm:leading-6"
              />
            </div>
            <ProductImage
              height="h-12"
              width="w-12"
              softwareProduct={{ name: "Preview", imageUrl }}
            />
          </div>
        </div>
      </div>

      <div className="my-6">
        <CategoryInput
          categories={productFilters[0].options}
          product={product}
          register={register}
          setValue={setValue}
        />
      </div>

      <div className="my-6">
        <TagInput
          product={product}
          tags={productFilters[1].options}
          setValue={setValue}
          watch={watch}
        />
      </div>
    </form>
  );
};

export default ProductForm;
