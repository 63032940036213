import React, { useState, useEffect } from "react";
import { ArrowTrendingUpIcon, PencilIcon } from "@heroicons/react/24/outline";
import TrendChart from "../../TrendChart";
import MomentsModal from "./MomentsModal";
import Modal from "../../Modal";

const TrendSection = ({ stackSurveys, moments, updateMoments, refetchMoments }) => {
 const [momentsModalOpen, setMomentsModalOpen] = useState(false);

  return (
    <div>
      <div>
        <div className="flex gap-2 items-start">
          <h3 className="text-2xl lg:text-3xl font-bold">Trend</h3>
          <ArrowTrendingUpIcon className="h-8 w-8 text-indigo-600 mt-0.5" />
        </div>
        <div className="text-gray-500">Percentage of favourable answers</div>
      </div>
      <div className="p-6 pt-2 bg-white rounded-lg mt-4">
        <div className="mt-4 h-60 max-h-96">
          <TrendChart surveys={stackSurveys} moments={moments} />
        </div>
        <div className="mt-5">
          <button className="text-indigo-700 hover:underline hover:text-indigo-800 text-sm flex items-center gap-1" onClick={() => setMomentsModalOpen(true)}>
            <PencilIcon className="h-4 w-4 mt-0.5" /> Edit events
          </button>
        </div>
      </div>
      <Modal open={momentsModalOpen} setOpen={setMomentsModalOpen}>
        <MomentsModal setOpen={setMomentsModalOpen} moments={moments} updateMoments={updateMoments} refetchMoments={refetchMoments} />
      </Modal>
    </div>
  );
};

export default TrendSection;
