import React, { useState } from "react";
import { CircleStackIcon } from "@heroicons/react/20/solid";

import PcnTable from "./PcnTable/PcnTable.js";

import PcnStatBoxes from "./PcnStatBoxes.js";
import Filter from "./Filter.js";
import {
  getTagOptions,
  getCategoryOptions,
} from "../../../utilities/stacks.js";
import PcnChartSummary from "./PcnChartSummary.js";

const PcnSummary = ({ pcnStacks }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [pcnProductDrawersOpen, setPcnProductDrawersOpen] = useState([]);

  const categories = getCategoryOptions(pcnStacks);
  const tags = getTagOptions(pcnStacks);

  const handleFilterChange = (e) => {
    const newSelectedFilters = [...selectedFilters];
    const changedFilter = e.target.value;
    if (selectedFilters.includes(changedFilter)) {
      newSelectedFilters.splice(newSelectedFilters.indexOf(changedFilter), 1);
    } else {
      newSelectedFilters.push(changedFilter);
    }

    // open all product drawers the first time the filters are changed
    if (!selectedFilters.length && newSelectedFilters.length) {
      openAllPcnProductDrawers();
    }

    setSelectedFilters(newSelectedFilters);
  };

  const openAllPcnProductDrawers = () => {
    const allStackIds = pcnStacks.map((stack) => stack.id);
    setPcnProductDrawersOpen(allStackIds);
  };

  const filters = [
    {
      id: "category",
      name: "Category",
      options: categories,
    },
    {
      id: "tag",
      name: "Tags",
      options: tags,
    },
    {
      id: "procured",
      name: "Procured Internally/Externally",
      options: [
        { id: "internally", name: "Internally" },
        { id: "externally", name: "Externally" },
      ],
    },
  ].filter((filter) => filter.options.length);

  return (
    <>
      <div className="my-8">
        <PcnStatBoxes stacks={pcnStacks} />
      </div>
      <div className="my-8">
        <PcnChartSummary pcnStacks={pcnStacks} />
      </div>
      <Filter
        filters={filters}
        selectedFilters={selectedFilters}
        handleFilterChange={handleFilterChange}
      />
      <div className="my-8">
        <PcnTable
          pcnProductDrawersOpen={pcnProductDrawersOpen}
          setPcnProductDrawersOpen={setPcnProductDrawersOpen}
          filters={filters}
          selectedFilters={selectedFilters}
          stacks={pcnStacks}
        />
      </div>
    </>
  );
};

export default PcnSummary;
