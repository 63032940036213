import React, { useState } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import { classNames } from "../../../utilities/general";

const TagInput = ({ product, tags, setValue, watch }) => {
  const selectedTags = watch('tags')

  return (
    <Listbox as="div" by="id" defaultValue={product?.productTags?.items?.map((productTag) => productTag.productTag)} onChange={(tags) => setValue('tags', tags)} multiple>
      <Listbox.Label className="block sm:text-base font-medium leading-6 text-gray-900">
        Tag
      </Listbox.Label>
      <div className="relative mt-2">
        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-3 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-base sm:leading-6">
          {(!selectedTags || selectedTags?.length === 0) && <span className="text-gray-500">Select a tag</span>}
          {selectedTags?.length === 1 && <span>{selectedTags[0].name}</span>}
          {selectedTags?.length > 1 && <span>Multiple ({selectedTags.length})</span>}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          </span>
        </Listbox.Button>

        {tags?.length > 0 && (
          <Listbox.Options className="absolute z-[10000] mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-base">
            {tags.map((tag) => (
              <Listbox.Option
                key={`${tag.id}-tag-combo-option`}
                value={tag}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-blue-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div>
                      <div className="flex items-center">
                        <span
                          className={classNames(
                            "truncate text-base",
                            selected && "font-semibold"
                          )}
                        >
                          {tag?.name}
                        </span>
                      </div>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-blue-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        )}
      </div>
    </Listbox>
  );
};

export default TagInput;
